import React, { useState, useEffect, useContext } from "react";
import { ethers } from "ethers";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import SAFE from "../../../components/Launchpad/textpar.js";
import InputBlock from "../../../components/Launchpad/InputBlock.js";
import DescBlock from "../../../components/Launchpad/descBlock.js";
import Button from "../../../components/Launchpad/Button.js";
import Check from "../../../components/Launchpad/Checkbox.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import env from "../../../hooks/data/Config.js";
import { useNavigate } from "react-router-dom";
import { Keypair, SYSVAR_CLOCK_PUBKEY } from "@solana/web3.js";
import { Emit } from "../../../components/Alert/Alert.js";

const pda = Keypair.generate();
const metadatapda = pda.publicKey.toString();

const Web3 = require("web3-eth");

const CreateFairLaunch = (props) => {
  const {
    Token,
    Lock,
    PreSale,
    Private,
    FairSale,
    Stage,
    Factory,
    getTokenAddress,
    getToken,
    account,
    PublicKey,
    BN,
    PreSalev2,
    connection,
  } = window.solpadContext;

  const address = account;
  const [_poolfee, setpoofee] = useState(3);
  const [amountTo, setamountTo] = useState(0);
  const [contract, setcontract] = useState(env.priSaleContract);
  const utoken = [
    "So11111111111111111111111111111111111111112", //sol
    "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v", //usdc
    "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB", //usdt
  ];
  const utokendec = [
    9, //sol
    6, //usdc
    6, //usdt
  ];
  const [step, setstep] = useState(0);

  const [currency, setCurrency] = useState(0);
  const [lop, setlop] = useState(0);
  const [Affiliate, setAffiliate] = useState(0);
  const [white, setwhite] = useState(0);
  const [progressv, setProgress] = useState("0%");
  //step1
  const [tokenB, setTokenB] = useState({
    ct: undefined,
    name: "",
    symbol: "",
    dec: 0,
    supply: "",
    allow: 0,
  });
  //step2
  const [presale_rate, setpresale_rate] = useState(1);
  const [softcap, setsoftcap] = useState(1);
  const [hardcap, sethardcap] = useState(2);
  const [minbuy, setminbuy] = useState(1);
  const [maxbuy, setmaxbuy] = useState(2);
  const [percentLiquidity, setpercentLiquidity] = useState(0);
  const [listRate, setlistRate] = useState(0);
  /// const [metadatapda, setmetadatapda] = useState(undefined)
  const [liquidity_lockup, setliquidity_lockup] = useState(0);
  const [startTime, setstartTime] = useState(_toUTC(new Date()));
  const [endTime, setendTime] = useState(_toUTC(new Date()));
  //step3
  const [LogoUrl, setLogoUrl] = useState("");
  const [Website, setWebsite] = useState("");
  const [Facebook, setFacebook] = useState("");
  const [Twitter, setTwitter] = useState("");
  const [Github, setGithub] = useState("");
  const [Telegram, setTelegram] = useState("");
  const [Instagram, setInstagram] = useState("");
  const [Discord, setDiscord] = useState("");
  const [Reddit, setReddit] = useState("");
  const [YoutubeVideo, setYoutubeVideo] = useState("");
  const [Description, setDescription] = useState("");
  const [tokenFee, settokenFee] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    getamount();
  }, [listRate, presale_rate, hardcap, percentLiquidity, tokenB.allow]);

  useEffect(() => {
    setstartTime(_toUTC(new Date()));
    setendTime(_toUTC(new Date()));
    //setUVFC(_toUTC(new Date()));
  }, []);
  if (FairSale == undefined) {
    return <></>;
  }
  const program = new Stage().syncbuild(env.fairSaleContract);

  function nextblock(accountBalancemctTB, d) {
    if (accountBalancemctTB != undefined && accountBalancemctTB != 0) {
      if (accountBalancemctTB.toString().length > d) {
        const decimals = accountBalancemctTB.toString().slice(-d).slice(0, 1);

        const plus = parseInt(decimals) > 0 ? "." + decimals : "";
        return parseFloat(
          parseFloat(ethers.formatUnits(accountBalancemctTB, d))
            .toFixed(0)
            .toString() + plus
        );
      } else {
        let cutin = d - accountBalancemctTB.toString().length + 2;
        return parseFloat(
          ethers.formatUnits(accountBalancemctTB, d)
        ).toFixed(cutin);
      }
    } else {
      return 0.0;
    }
  }

  function nf(e) {
    return ("0" + e).slice(-2);
  }
  function progress() {
    return progressv;
  }
  function toUTC(func, dateA) {
    func(dateA);
  }
  function assert(bool, msg) {
    if (bool) {
      alert(msg);
      throw msg;
    }
  }
  function parseUTC(dateA) {
    return (
      nf(dateA.getUTCMonth() + 1) +
      "-" +
      nf(dateA.getUTCDate()) +
      "-" +
      dateA.getUTCFullYear() +
      " At " +
      nf(dateA.getUTCHours()) +
      ":" +
      nf(dateA.getUTCMinutes()) +
      " UTC"
    );

    // return new Date(Date.now());
  }
  function _toUTC(dateA) {
    const time = new Date(dateA);
    const _time = new Date(
      dateA.getUTCFullYear() +
        "-" +
        nf(dateA.getUTCMonth() + 1) +
        "-" +
        nf(dateA.getUTCDate()) +
        "T" +
        nf(dateA.getUTCHours()) +
        ":" +
        nf(dateA.getUTCMinutes()) +
        ":00"
    );

    return new Date(Date.now());
  }
  function now() {
    const dateA = new Date();
    const time = new Date(
      dateA.getUTCFullYear() +
        "-" +
        nf(dateA.getUTCMonth()) +
        "-" +
        nf(dateA.getUTCDate()) +
        "T" +
        nf(dateA.getUTCHours()) +
        ":" +
        nf(dateA.getUTCMinutes()) +
        ":00"
    );

    return Date.now();
  }
  function alltoBN(d) {
    let a = [];
    for (let index = 0; index < d.length; index++) {
      a.push(new BN(d[index]));
    }
    return a;
  }
  function returnbignumber(value, dec) {
    let a = [""];
    let e = value + "";
    if (e.toString().includes(",")) {
      a = e.toString().split(",");
      e = ethers.parseUnits(a[0] + "." + a[1], dec).toString();
    } else {
      e = ethers.parseUnits(e, dec).toString();
    }
    return e;
  }
  async function create() {
    const factory = new Factory(env.factory, metadatapda);
    const start = parseInt(Date.parse(startTime) / 1000);
    const end = parseInt(Date.parse(endTime) / 1000);

    const { storage, keys, instruction, preAddress } = await program.getPda(
      "createPda",
      { mint: tokenB.ct },
      new BN(Math.floor(Math.random() * 1000)),
      []
    );
    //console.log({ storage, keys, instruction, preAddress })
    const burnWall = "11111111111111111111111111111111";

    const sburn = await getTokenAddress(tokenB.ct, burnWall);
    const sfrom = await getTokenAddress(tokenB.ct, address);
    const sof = await getTokenAddress(tokenB.ct, program.defaultAccounts.of);
    const sto = await getTokenAddress(tokenB.ct, storage);
    const from = await getTokenAddress(utoken[currency], address);
    const to = await getTokenAddress(utoken[currency], storage);
    const extras = await program.testAccountsToken(tokenB.ct, [
      address,
      storage,
      program.defaultAccounts.of,
    ]);
    /* const uextras = await program.testAccountsToken(utoken[currency], [
       address,
       storage,
       program.defaultAccounts.of
     ]);*/
    //const aproveamount = amountTo + (parseFloat(nextblock(tokenB.supply, tokenB.dec)) / 100)
    try {
      await program.create(
        [
          ...extras,
          //...uextras,
          /*  await program.callApprove(
              tokenB.ct,
              new BN(returnbignumber(aproveamount, tokenB.dec))
            )*/
        ],
        [
          instruction,
          await program.send(
            "new",
            {
              // ownerWallet: from,
              //   programWallet: to,
              bank: storage,
              sownerWallet: sfrom,
              sprogramWallet: sto,
              tokenProgramId: await program.getTokenProgram(
                new PublicKey(tokenB.ct)
              ),
              mint: tokenB.ct,
              sof: sof,
            },
            metadatapda,
            Array.from(
              alltoBN([
                returnbignumber(presale_rate, tokenB.dec),
                returnbignumber(softcap, utokendec[currency]),
                returnbignumber(maxbuy, utokendec[currency]),
                returnbignumber(listRate, tokenB.dec),
                percentLiquidity,
                start.toString(),
                end.toString(),
                liquidity_lockup * 1440,

                currency,
                lop,
                Affiliate,

                tokenB.dec,
                tokenFee,
                parseInt(Date.now() / 1000),
              ])
            ),
            Array.from(keys)
          ),
        ],
        await factory.updateMetadata(
          { metadatapda: metadatapda, pda: pda },
          {
            LogoUrl,
            Website,
            Facebook,
            Twitter,
            Github,
            Telegram,
            Discord,
            Instagram,
            Reddit,
            YoutubeVideo,
            Description,
          }
        )
      );

      navigate("/fairlaunch/" + preAddress);
    } catch (error) {
      console.log(error);
    }
  }

  async function getamount() {
    try {
      if (presale_rate > 0) {
        const _amount = (presale_rate * percentLiquidity) / 100;
        setlistRate(_amount.toFixed(tokenB.dec));
        setamountTo((presale_rate + _amount).toFixed(tokenB.dec));
      } else {
        setamountTo(0);
      }
    } catch (error) {
      setamountTo(0);
    }
  }
  async function gettoken(ct) {
    try {
      if (ct?.toString().trim().length < 0) {
      } else {
        const obj = await getToken(ct?.toString().trim());
        obj.ct = ct?.toString().trim();
        obj.allow = 10000000n;
        obj.dec = obj.decimals;
        setTokenB(obj);
      }
    } catch (error) {
      //console.log(error)
    }
  }

  function nextstep() {
    setProgress(((step + 1) * 33).toString() + "%");
    setstep(step + 1);
  }

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} onClick={onClick} className="date-picker">
      <p style={{ margin: "auto" }}>
        {new Date(value).toUTCString().replace("GMT", "UTC")}
      </p>
    </button>
  ));
  let modalcreat = (
    <div>
      {progress() === "0%" && (
        /*-step-1------------------------------------------------------------------------------*/
        <>
          <div className="title-form">
            <div className="title">Verify Token</div>
            <p>Enter the token address and verify</p>
          </div>
          <div className="form">
            <div className="row">
              <div className="col col-8">
                <InputBlock
                  title={"Token Address"}
                  place={"Ex. 3ic31ZwDAvL9vg8b4er22TDLaDyLakEv107CJNTsyXhW"}
                  variavel={tokenB.ct}
                  setvariavel={gettoken}
                  desc={
                    "Creation fee " +
                    3 +
                    " SOL and 5% from " +
                    (currency === 0
                      ? " SOL"
                      : currency === 1
                      ? " USDC"
                      : currency === 2
                      ? " USDT"
                      : " SOL") +
                    " raised and 1% of the supply"
                  }
                />
              </div>
              <div className="col col-4">
                <InputBlock
                  title={"SplToken Fee %*"}
                  place={"0"}
                  variavel={tokenFee}
                  setvariavel={settokenFee}
                  desc={"Enter your CA Transfer Fee %"}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col col-5">
              <SAFE what={"Name"} result={tokenB.name ? tokenB.name : "-"} />
            </div>
            <div className="col col-2">
              <SAFE
                what={"Symbol"}
                result={tokenB.symbol ? tokenB.symbol : "-"}
              />
            </div>
            <div className="col col-2">
              <SAFE what={"Decimals"} result={tokenB.dec ? tokenB.dec : "-"} />
            </div>
            <div className="col col-3">
              <SAFE
                what={"Supply"}
                result={
                  tokenB.supply ? nextblock(tokenB.supply, tokenB.dec) : "-"
                }
              />
            </div>
          </div>
          <div className="form">
            <div className="title-input" style={{ marginLeft: "10px" }}>
              Currency
            </div>
            <div className="row">
              <div className="col col-3">
                <Check
                  title={"SOL"}
                  id={0}
                  variavel={currency}
                  setvariavel={setCurrency}
                />
              </div>
              <div className="col col-3">
                <Check
                  title={"USDC"}
                  id={1}
                  variavel={currency}
                  setvariavel={setCurrency}
                />
              </div>
              <div className="col col-3">
                <Check
                  title={"USDT"}
                  id={2}
                  variavel={currency}
                  setvariavel={setCurrency}
                />
              </div>
              <div className="col col-3">
                <small>
                  Users will pay with{" "}
                  {currency === 0
                    ? " SOL"
                    : currency === 1
                    ? " USDC"
                    : currency === 2
                    ? " USDT"
                    : " SOL"}{" "}
                  for your token
                </small>
              </div>
            </div>
            <hr style={{ margin: "15px 0" }} />
            <div className="title-input" style={{ marginLeft: "10px" }}>
              Affiliate Program
            </div>
            <div className="row">
              <div className="col col-3">
                <Check
                  title={"Disable Affiliate"}
                  id={0}
                  variavel={Affiliate}
                  setvariavel={setAffiliate}
                />
              </div>
              <div className="col col-3">
                <Check
                  title={"Enable Affiliate"}
                  id={Affiliate === 0 ? 1 : Affiliate}
                  variavel={Affiliate}
                  setvariavel={setAffiliate}
                />
              </div>

              {Affiliate !== 0 && (
                <>
                  <div className="col col-3">
                    <div className="title-input" style={{ marginLeft: "10px" }}>
                      {"% to affiliate "}
                    </div>
                    <input
                      onChange={(e) => {
                        e.target.value <= 10
                          ? setAffiliate(e.target.value)
                          : setAffiliate(Affiliate);
                      }}
                      placeholder={"%"}
                      value={Affiliate}
                      className="input"
                    ></input>
                    <small>{"Maximum 10%"}</small>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="group-buttons">
            <Button
              func={() => {
                nextstep();
              }}
              text={"NEXT"}
              next={true}
              className="next"
            />
          </div>
        </>
      )}
      {progress() === "33%" && (
        /*-step-2------------------------------------------------------------------------------*/
        <>
          <div className="title-form">
            <div className="title">DeFi launchpad</div>
            <p>Enter the launchpad information</p>
          </div>
          <div className="form">
            <div className="row">
              <div className="col col-6">
                <InputBlock
                  title={"Total selling amount*"}
                  place={"0"}
                  variavel={presale_rate}
                  setvariavel={setpresale_rate}
                  desc={
                    "1 " +
                    (currency === 0
                      ? " SOL"
                      : currency === 1
                      ? " USDC"
                      : currency === 2
                      ? " USDT"
                      : " SOL") +
                    " contribution will receive this many tokens"
                  }
                  className=""
                />
              </div>
            </div>

            <hr style={{ margin: "15px 0" }} />
            <div className="title-input" style={{ marginLeft: "10px" }}>
              Whitelist
            </div>
            <div className="row">
              <div className="col col-2">
                <Check
                  title={"Disable"}
                  id={0}
                  variavel={white}
                  setvariavel={setwhite}
                />
              </div>
              <div className="col col-2">
                <Check
                  title={"Enable"}
                  id={1}
                  variavel={white}
                  setvariavel={setwhite}
                />
              </div>
              <div className="col col-4">
                <small> You can enable/disable whitelist anytime.</small>
              </div>
            </div>
            <hr style={{ margin: "15px 0" }} />

            <div className="row">
              <div className="col col-6">
                <InputBlock
                  title={
                    "softcap  " +
                    (currency === 0
                      ? " SOL"
                      : currency === 1
                      ? " USDC"
                      : currency === 2
                      ? " USDT"
                      : " SOL") +
                    "*"
                  }
                  place={"0"}
                  variavel={softcap}
                  setvariavel={setsoftcap}
                  desc={"Minimum capitalization"}
                />
              </div>
              <div className="col col-6">
                <InputBlock
                  title={
                    "Maximum buy  " +
                    (currency === 0
                      ? " SOL"
                      : currency === 1
                      ? " USDC"
                      : currency === 2
                      ? " USDT"
                      : " SOL") +
                    "*"
                  }
                  place={"0"}
                  variavel={maxbuy}
                  setvariavel={setmaxbuy}
                  desc={"Maximum buy per wallet"}
                />
              </div>
            </div>

            <div className="row">
              <div className="col col-6">
                <div className="title-input">Start time</div>
                <DatePicker
                  selected={startTime}
                  onChange={(date) => toUTC(setstartTime, date)}
                  touchUi={true}
                  showTimeSelect
                  timeFormat="HH:mm aa"
                  timeIntervals={5}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                  customInput={<CustomInput time={startTime} />}
                />
                <small>Sale start time</small>
              </div>
              <div className="col col-6">
                <div className="title-input">End time</div>
                <DatePicker
                  selected={endTime}
                  onChange={(date) => toUTC(setendTime, date)}
                  touchUi={true}
                  showTimeSelect
                  timeFormat="HH:mm aa"
                  timeIntervals={5}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                  customInput={<CustomInput time={endTime} />}
                />
                <small>Sale End time</small>
              </div>
            </div>

            <div className="row">
              <div className="col col-6">
                <InputBlock
                  title={"Liquidity Percent (%)*"}
                  place={"0"}
                  variavel={percentLiquidity}
                  setvariavel={setpercentLiquidity}
                  desc={
                    "How much from capitalization going to liquidity? (Minimum 30%)"
                  }
                />
              </div>
              <div className="col col-6">
                <InputBlock
                  title={"Liquidity lockup (days)"}
                  place={"0"}
                  variavel={liquidity_lockup}
                  setvariavel={(e) => {
                    setliquidity_lockup(e);
                  }}
                  desc={"How much time liquidity is lock by Solpad lock"}
                />
              </div>
            </div>
          </div>
          <div className="group-buttons">
            <Button
              func={() => {
                setProgress(((step - 1) * 33).toString() + "%");
                setstep(step - 1);
              }}
              text={"BACK"}
              next={false}
              className="default"
            />
            <Button
              func={() => {
                try {
                  assert(presale_rate <= 0, "Presale value invalid");
                  assert(softcap <= 0, "softcap value invalid");

                  assert(
                    parseInt(Date.parse(startTime)) <= parseInt(now()),
                    "start after now"
                  );
                  assert(
                    parseInt(Date.parse(endTime)) <=
                      parseInt(Date.parse(startTime)),
                    "finish time < start time"
                  );

                  nextstep();
                } catch (error) {}
              }}
              text={"NEXT"}
              next={true}
              className="next"
            />
          </div>
        </>
      )}
      {progress() === "66%" && (
        /*-step-4------------------------------------------------------------------------------*/
        <>
          <div className="form">
            <div className="row">
              <div className="col col-4">
                <InputBlock
                  title={"Image upload url*"}
                  place={"https://..."}
                  variavel={LogoUrl}
                  setvariavel={setLogoUrl}
                  desc={""}
                />
              </div>
              <div className="col col-4">
                <InputBlock
                  title={"Website *"}
                  place={"https://..."}
                  variavel={Website}
                  setvariavel={setWebsite}
                  desc={""}
                />
              </div>
              <div className="col col-4">
                <InputBlock
                  title={"Github"}
                  place={"https://..."}
                  variavel={Github}
                  setvariavel={setGithub}
                  desc={""}
                />
              </div>
            </div>

            <div className="row">
              <div className="col col-4">
                <InputBlock
                  title={"Facebook"}
                  place={"https://..."}
                  variavel={Facebook}
                  setvariavel={setFacebook}
                  desc={""}
                />
              </div>
              <div className="col col-4">
                <InputBlock
                  title={"Twitter"}
                  place={"https://..."}
                  variavel={Twitter}
                  setvariavel={setTwitter}
                  desc={""}
                />
              </div>
              <div className="col col-4">
                <InputBlock
                  title={"Instagram"}
                  place={"https://..."}
                  variavel={Instagram}
                  setvariavel={setInstagram}
                  desc={""}
                />
              </div>
            </div>

            <div className="row">
              <div className="col col-4">
                <InputBlock
                  title={"Telegram"}
                  place={"https://..."}
                  variavel={Telegram}
                  setvariavel={setTelegram}
                  desc={""}
                />
              </div>
              <div className="col col-4">
                <InputBlock
                  title={"Discord"}
                  place={"https://..."}
                  variavel={Discord}
                  setvariavel={setDiscord}
                  desc={""}
                />
              </div>
              <div className="col col-4">
                <InputBlock
                  title={"Reddit"}
                  place={"https://..."}
                  variavel={Reddit}
                  setvariavel={setReddit}
                  desc={""}
                />
              </div>
            </div>

            <div className="row">
              <div className="col col-6">
                <InputBlock
                  title={"Youtube Video url or banner url"}
                  place={"https://..."}
                  variavel={YoutubeVideo}
                  setvariavel={setYoutubeVideo}
                  desc={""}
                />
              </div>
            </div>
            <div className="row">
              <div className="col col-12">
                <DescBlock
                  title={"Description"}
                  place={"Description..."}
                  variavel={Description}
                  setvariavel={setDescription}
                  desc={""}
                />
              </div>
            </div>
          </div>

          <div className="group-buttons">
            <Button
              func={() => {
                setProgress(((step - 1) * 33).toString() + "%");
                setstep(step - 1);
              }}
              text={"BACK"}
              next={false}
              className="default"
            />
            <Button
              func={async () => {
                nextstep();
              }}
              text={"NEXT"}
              next={true}
              className="next"
            />
          </div>
        </>
      )}
      {progress() === "99%" && (
        /*-step-3------------------------------------------------------------------------------*/
        <>
          <div className="form">
            <div className="row">
              <div className="col col-6">
                <SAFE what={"Total token"} result={amountTo ? amountTo : "-"} />
              </div>
              <div className="col col-6">
                <SAFE
                  what={"Factory Address"}
                  result={contract ? contract : "-"}
                />
              </div>
              <div className="col col-6">
                <SAFE
                  what={"Token name"}
                  result={tokenB.name ? tokenB.name : "-"}
                />
              </div>
              <div className="col col-6">
                <SAFE
                  what={"Token symbol"}
                  result={tokenB.symbol ? tokenB.symbol : "-"}
                />
              </div>
              <div className="col col-6">
                <SAFE
                  what={"Token decimals"}
                  result={tokenB.dec ? tokenB.dec : "-"}
                />
              </div>
              <div className="col col-6">
                <SAFE
                  what={"PreSale Rate"}
                  result={presale_rate ? presale_rate : "-"}
                />
              </div>
              <div className="col col-6">
                <SAFE what={"Sale method"} result={"Fair launch"} />
              </div>
              <div className="col col-6">
                <SAFE
                  what={"Liquidity Percent (%)"}
                  result={percentLiquidity ? percentLiquidity + "%" : "-"}
                />
              </div>
              <div className="col col-6">
                <SAFE
                  what={"Affiliate"}
                  result={Affiliate ? Affiliate + "%" : "-"}
                />
              </div>
              <div className="col col-6">
                <SAFE what={"softcap"} result={softcap ? softcap : "-"} />
              </div>
              <div className="col col-6">
                <SAFE what={"Maximum buy"} result={maxbuy ? maxbuy : "-"} />
              </div>
              <div className="col col-6">
                <SAFE
                  what={"Start time"}
                  result={startTime ? parseUTC(startTime) : "-"}
                />
              </div>
              <div className="col col-6">
                <SAFE
                  what={"End time"}
                  result={endTime ? parseUTC(endTime) : "-"}
                />
              </div>
              <div className="col col-6">
                <SAFE what={"Website"} result={Website ? Website : "-"} />
              </div>
            </div>

            <hr style={{ margin: "15px 0" }} />
            <div className="row">
              <div className="col col-12">
                <div className="warning-message">
                  <WarningAmberOutlinedIcon
                    sx={{
                      fill: "#babd41",
                      width: "1.1rem",
                      height: "1.1rem",
                    }}
                  />
                  {
                    "To authenticate and edit your metadata, copy and save this on a safe place"
                  }
                </div>

                <div className="secret-key">
                  {JSON.stringify(pda.secretKey)}
                </div>

                <Button
                  isButtonLarge={false}
                  func={() => {
                    navigator.clipboard.writeText(
                      JSON.stringify(pda.secretKey)
                    );
                    Emit(
                      "Copied",
                      "Now save in a safe place, this is your authentication to edit your sale metadata!!",
                      "load"
                    );
                  }}
                  text={"COPY"}
                  next={false}
                  className="next"
                />
              </div>
            </div>
          </div>
          <div className="group-buttons">
            <Button
              func={() => {
                setProgress(((step - 1) * 33).toString() + "%");
                setstep(step - 1);
              }}
              text={"BACK"}
              next={false}
              className="default"
            />
            <Button
              func={() => {
                create();
              }}
              text={"PUBLISH"}
              next={true}
              className="next"
            />
          </div>
        </>
      )}
    </div>
  );

  function stepCurrent() {
    return step;
  }

  return (
    <>
      <div className="text-title">Create Fair Launch</div>
      <div className="group-create">
        <div className="time-line">
          <div className="group-step">
            <div
              className={
                stepCurrent() === 0 || stepCurrent() > 0
                  ? "step-actived"
                  : "step"
              }
            >
              <div className="step-number">1</div>
              <div className="step-title">
                Verify Token <br /> <p>Enter the token address and verify</p>
              </div>
            </div>
            <div
              className={
                stepCurrent() === 1 || stepCurrent() > 1
                  ? "step-actived"
                  : "step"
              }
            >
              <div className="step-number">2</div>
              <div className="step-title">
                DeFi Launchpad <br /> <p>Enter the launchpad information</p>
              </div>
            </div>

            <div
              className={
                stepCurrent() === 2 || stepCurrent() > 2
                  ? "step-actived"
                  : "step"
              }
            >
              <div className="step-number">3</div>
              <div className="step-title">
                Add Additional Info <br />{" "}
                <p>Short description about your project and project links</p>
              </div>
            </div>
            <div
              className={
                stepCurrent() === 3 || stepCurrent() > 3
                  ? "step-actived"
                  : "step"
              }
            >
              <div className="step-number">4</div>
              <div className="step-title">
                Finish <br />{" "}
                <p>Review your information and submit your presale</p>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">{modalcreat}</div>
      </div>
    </>
  );
};
export default CreateFairLaunch;
