import React, { useState, useEffect, useContext } from "react";
import { ethers } from "ethers";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import Numberinput from "../../../components/Launchpad/numberInput.js";
import SAFE from "../../../components/Launchpad/textpar.js";
import InputBlock from "../../../components/Launchpad/InputBlock.js";
import DescBlock from "../../../components/Launchpad/descBlock.js";
import Button from "../../../components/Launchpad/Button.js";
import Check from "../../../components/Launchpad/Checkbox.js";
import Checklist from "../../../components/Launchpad/ListCheck.js";
import StepBar from "../../../components/Launchpad/Stepbar.js";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import enUS from "date-fns/locale/en-US";
import "react-datepicker/dist/react-datepicker.css";

import AppContext from "../../../framework/helpers/AppContext.js";
import env from "../../../hooks/data/Config.js";
import { useNavigate } from "react-router-dom";
import { Keypair } from "@solana/web3.js";
import { Emit } from "../../../components/Alert/Alert.js";

const pda = Keypair.generate();
const metadatapda = pda.publicKey.toString();
const Web3 = require("web3-eth");

const CreatePrivateSale = (props) => {
  const {
    Token,
    Lock,
    Private,
    Factory,
    Stage,
    getTokenAddress,
    getToken,
    account,
    PublicKey,
    BN,
    connection,
  } = window.solpadContext;

  const address = account;
  const [_poolfee, setpoofee] = useState(3);
  const [amountTo, setamountTo] = useState(0);
  const [contract, setcontract] = useState(env.priSaleContract);
  const utoken = [
    "So11111111111111111111111111111111111111112", //sol
    "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v", //usdc
    "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB", //usdt
  ];
  const utokendec = [
    9, //sol
    6, //usdc
    6, //usdt
  ];
  const [step, setstep] = useState(0);

  const [currency, setCurrency] = useState(0);
  const [lop, setlop] = useState(0);
  const [Affiliate, setAffiliate] = useState(0);
  const [white, setwhite] = useState(0);
  const [progressv, setProgress] = useState("0%");
  //step1
  const [tokenB, setTokenB] = useState({
    ct: undefined,
    name: "",
    symbol: "",
    dec: 0,
    supply: "",
    allow: 0,
  });
  //step2
  const [presale_rate, setpresale_rate] = useState(1);
  const [softcap, setsoftcap] = useState(1);
  const [hardcap, sethardcap] = useState(2);
  const [minbuy, setminbuy] = useState(1);
  const [maxbuy, setmaxbuy] = useState(2);
  const [refundType, setrefundType] = useState(0);
  const [percentLiquidity, setpercentLiquidity] = useState(0);
  const [listRate, setlistRate] = useState(0);
  //const [metadatapda, setmetadatapda] = useState(undefined)
  const [tokenFee, settokenFee] = useState(0);

  const [uvesting, setuvesting] = useState(0);
  const [ovesting, setovesting] = useState(0);
  const [VI, setVI] = useState(10);
  const [VC, setVC] = useState(10);
  const [VPC, setVPC] = useState(10);
  const [UVI, setUVI] = useState(10);
  const [UVC, setUVC] = useState(10);
  const [UVPC, setUVPC] = useState(10);
  const [UVFC, setUVFC] = useState(_toUTC(new Date()));
  const [startTime, setstartTime] = useState(_toUTC(new Date()));
  const [endTime, setendTime] = useState(_toUTC(new Date()));
  //step3
  const [LogoUrl, setLogoUrl] = useState("");
  const [Website, setWebsite] = useState("");
  const [Facebook, setFacebook] = useState("");
  const [Twitter, setTwitter] = useState("");
  const [Github, setGithub] = useState("");
  const [Telegram, setTelegram] = useState("");
  const [Instagram, setInstagram] = useState("");
  const [Discord, setDiscord] = useState("");
  const [Reddit, setReddit] = useState("");
  const [YoutubeVideo, setYoutubeVideo] = useState("");
  const [Description, setDescription] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    getamount();
  }, [listRate, presale_rate, hardcap, percentLiquidity, tokenB.allow]);

  useEffect(() => {
    setstartTime(_toUTC(new Date()));
    setendTime(_toUTC(new Date()));
    setUVFC(_toUTC(new Date()));
  }, []);
  if (Private == undefined) {
    return <></>;
  }
  const program = new Stage().syncbuild(env.priSaleContract);

  function nextblock(accountBalancemctTB, d) {
    if (accountBalancemctTB != undefined && accountBalancemctTB != 0) {
      if (accountBalancemctTB.toString().length > d) {
        const decimals = accountBalancemctTB.toString().slice(-d).slice(0, 1);

        const plus = parseInt(decimals) > 0 ? "." + decimals : "";
        return parseFloat(
          parseFloat(ethers.formatUnits(accountBalancemctTB, d))
            .toFixed(0)
            .toString() + plus
        );
      } else {
        let cutin = d - accountBalancemctTB.toString().length + 2;
        return parseFloat(
          ethers.formatUnits(accountBalancemctTB, d)
        ).toFixed(cutin);
      }
    } else {
      return 0.0;
    }
  }

  function nf(e) {
    return ("0" + e).slice(-2);
  }
  function progress() {
    return progressv;
  }
  function toUTC(func, dateA) {
    func(dateA);
  }
  function assert(bool, msg) {
    if (bool) {
      alert(msg);
      throw msg;
    }
  }
  function _toUTC(dateA) {
    const time = new Date(dateA);
    const _time = new Date(
      dateA.getUTCFullYear() +
        "-" +
        nf(dateA.getUTCMonth() + 1) +
        "-" +
        nf(dateA.getUTCDate()) +
        "T" +
        nf(dateA.getUTCHours()) +
        ":" +
        nf(dateA.getUTCMinutes()) +
        ":00"
    );

    return new Date(Date.now());
  }
  function now() {
    const dateA = new Date();
    const time = new Date(
      dateA.getUTCFullYear() +
        "-" +
        nf(dateA.getUTCMonth()) +
        "-" +
        nf(dateA.getUTCDate()) +
        "T" +
        nf(dateA.getUTCHours()) +
        ":" +
        nf(dateA.getUTCMinutes()) +
        ":00"
    );

    return Date.now();
  }
  function alltoBN(d) {
    let a = [];
    for (let index = 0; index < d.length; index++) {
      a.push(new BN(d[index]));
    }
    return a;
  }
  function returnbignumber(value, dec) {
    let a = [""];
    let e = value + "";
    if (e.toString().includes(",")) {
      a = e.toString().split(",");
      e = ethers.parseUnits(a[0] + "." + a[1], dec).toString();
    } else {
      e = ethers.parseUnits(e, dec).toString();
    }
    return e;
  }
  async function create() {
    const factory = new Factory(env.factory, metadatapda);
    const start = parseInt(Date.parse(startTime) / 1000);
    const end = parseInt(Date.parse(endTime) / 1000);

    const { storage, keys, instruction, preAddress } = await program.getPda(
      "createPda",
      { mint: tokenB.ct },
      new BN(Math.floor(Math.random() * 1000)),
      []
    );

    const burnWall = "11111111111111111111111111111111";

    const sburn = await getTokenAddress(tokenB.ct, burnWall);
    const sfrom = await getTokenAddress(tokenB.ct, address);
    const sof = await getTokenAddress(tokenB.ct, program.defaultAccounts.of);
    const sto = await getTokenAddress(tokenB.ct, storage);
    const from = await getTokenAddress(utoken[currency], address);
    const to = await getTokenAddress(utoken[currency], storage);
    const extras = await program.testAccountsToken(tokenB.ct, [
      address,
      storage,
      program.defaultAccounts.of,
    ]);
    const uextras = await program.testAccountsToken(utoken[currency], [
      address,
      storage,
      program.defaultAccounts.of,
    ]);
    const aproveamount =
      amountTo + parseFloat(nextblock(tokenB.supply, tokenB.dec)) / 100;
    try {
      const a = await program.create(
        [
          ...extras,
          ...uextras,
          /*  await program.callApprove(
              tokenB.ct,
              new BN(returnbignumber(aproveamount, tokenB.dec))
            )*/
        ],
        [
          instruction,
          await program.send(
            "new",
            {
              // ownerWallet: from,
              //   programWallet: to,
              sownerWallet: sfrom,
              sprogramWallet: sto,
              bank: storage,
              mint: tokenB.ct,
              tokenProgramId: await program.getTokenProgram(
                new PublicKey(tokenB.ct)
              ),
              sof: sof,
              //     payer:address
            },
            metadatapda,
            Array.from(
              alltoBN([
                returnbignumber(presale_rate, tokenB.dec),
                returnbignumber(softcap, utokendec[currency]),
                returnbignumber(hardcap, utokendec[currency]),
                returnbignumber(minbuy, utokendec[currency]),
                returnbignumber(maxbuy, utokendec[currency]),
                refundType.toString(),
                start.toString(),
                end.toString(),
                VI,
                VC,
                VPC,
                parseInt(Date.parse(UVFC) / 1000).toString(),
                UVI,
                UVC,
                UVPC,
                currency,
                Affiliate,
                ovesting,
                uvesting,
                tokenB.dec,
                tokenFee,
                parseInt(Date.now() / 1000),
              ])
            ),
            Array.from(keys)
          ),
        ],
        await factory.updateMetadata(
          { metadatapda: metadatapda, pda: pda },
          {
            LogoUrl,
            Website,
            Facebook,
            Twitter,
            Github,
            Telegram,
            Discord,
            Instagram,
            Reddit,
            YoutubeVideo,
            Description,
          }
        )
      );

      //navigate("/privatesales/" + a);
    } catch (error) {
      console.log(error);
    }
  }

  async function getamount() {
    if (presale_rate > 0 && hardcap > 0) {
      const _amount = presale_rate * hardcap;

      setamountTo(_amount);
    } else {
      setamountTo(0);
    }
  }
  async function gettoken(ct) {
    try {
      if (ct?.toString().trim().length < 0) {
      } else {
        const obj = await getToken(ct?.toString().trim());
        obj.ct = ct?.toString().trim();
        obj.allow = 10000000n;
        obj.dec = obj.decimals;
        setTokenB(obj);
      }
    } catch (error) {
      //console.log(error)
    }
  }

  function nextstep() {
    setProgress(((step + 1) * 20).toString() + "%");
    setstep(step + 1);
  }
  function parseUTC(dateA) {
    return (
      nf(dateA.getUTCMonth() + 1) +
      "-" +
      nf(dateA.getUTCDate()) +
      "-" +
      dateA.getUTCFullYear() +
      " At " +
      nf(dateA.getUTCHours()) +
      ":" +
      nf(dateA.getUTCMinutes()) +
      " UTC"
    );

    // return new Date(Date.now());
  }
  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} onClick={onClick} className="date-picker">
      <p style={{ margin: "auto" }}>
        {new Date(value).toUTCString().replace("GMT", "UTC")}
      </p>
    </button>
  ));
  let modalcreat = (
    <div>
      {progress() === "0%" && (
        /*-step-1------------------------------------------------------------------------------*/
        <>
          <div className="form">
            <div className="row">
              <div className="col col-6">
                <InputBlock
                  title={"Token Address"}
                  place={"Ex. 3ic31ZwDAvL9vg8b4er22TDLaDyLakEv107CJNTsyXhW"}
                  variavel={tokenB.ct}
                  setvariavel={gettoken}
                  desc={
                    "Creation fee " +
                    3 +
                    " SOL and 5% from " +
                    (currency === 0
                      ? " SOL"
                      : currency === 1
                      ? " USDC"
                      : currency === 2
                      ? " USDT"
                      : " SOL") +
                    " raised and 1% of the supply"
                  }
                />
              </div>
              <div className="col col-6">
                <InputBlock
                  title={"SplToken Fee %*"}
                  place={"0"}
                  variavel={tokenFee}
                  setvariavel={settokenFee}
                  desc={"Enter your CA Transfer Fee %"}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col col-5">
              <SAFE what={"Name"} result={tokenB.name ? tokenB.name : "-"} />
            </div>
            <div className="col col-2">
              <SAFE
                what={"Symbol"}
                result={tokenB.symbol ? tokenB.symbol : "-"}
              />
            </div>
            <div className="col col-2">
              <SAFE what={"Decimals"} result={tokenB.dec} />
            </div>
            <div className="col col-3">
              <SAFE
                what={"Supply"}
                result={nextblock(tokenB.supply, tokenB.dec)}
              />
            </div>
          </div>
          <div className="form">
            <div className="title-input" style={{ marginLeft: "10px" }}>
              Currency
            </div>
            <div className="row">
              <div className="col col-3">
                <Check
                  title={"SOL"}
                  id={0}
                  variavel={currency}
                  setvariavel={setCurrency}
                />
              </div>
              <div className="col col-3">
                <Check
                  title={"USDC"}
                  id={1}
                  variavel={currency}
                  setvariavel={setCurrency}
                />
              </div>
              <div className="col col-3">
                <Check
                  title={"USDT"}
                  id={2}
                  variavel={currency}
                  setvariavel={setCurrency}
                />
              </div>
              <div className="col col-3">
                <small>
                  Users will pay with{" "}
                  {currency === 0
                    ? " SOL"
                    : currency === 1
                    ? " USDC"
                    : currency === 2
                    ? " USDT"
                    : " SOL"}{" "}
                  for your token
                </small>
              </div>
            </div>
            <hr style={{ margin: "15px 0" }} />
            <div className="title-input" style={{ marginLeft: "10px" }}>
              Affiliate Program
            </div>
            <div className="row">
              <div className="col col-3">
                <Check
                  title={"Disable Affiliate"}
                  id={0}
                  variavel={Affiliate}
                  setvariavel={setAffiliate}
                />
              </div>
              <div className="col col-3">
                <Check
                  title={"Enable Affiliate"}
                  id={Affiliate === 0 ? 1 : Affiliate}
                  variavel={Affiliate}
                  setvariavel={setAffiliate}
                />
              </div>

              {Affiliate !== 0 && (
                <>
                  <div className="col col-3">
                    <div className="title-input" style={{ marginLeft: "10px" }}>
                      {"% to affiliate "}
                    </div>
                    <input
                      onChange={(e) => {
                        e.target.value <= 10
                          ? setAffiliate(e.target.value)
                          : setAffiliate(Affiliate);
                      }}
                      placeholder={"%"}
                      value={Affiliate}
                      className="input"
                    ></input>
                    <small>{"Maximum 10%"}</small>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="group-buttons">
            <Button
              func={() => {
                nextstep();
              }}
              text={"NEXT"}
              next={true}
              className="next"
            />
          </div>
        </>
      )}
      {progress() === "20%" && (
        /*-step-2------------------------------------------------------------------------------*/
        <>
          <div className="form">
            <div className="row">
              <div className="col col-4">
                <InputBlock
                  title={"Private rate"}
                  place={"0"}
                  variavel={presale_rate}
                  setvariavel={setpresale_rate}
                  desc={
                    "1 " +
                    (currency === 0
                      ? " SOL"
                      : currency === 1
                      ? " USDC"
                      : currency === 2
                      ? " USDT"
                      : " SOL") +
                    " contribution will receive this many tokens"
                  }
                  className=""
                />
              </div>
            </div>
            <hr style={{ margin: "15px 0" }} />
            <div className="title-input" style={{ marginLeft: "10px" }}>
              Whitelist
            </div>
            <div className="row">
              <div className="col col-2">
                <Check
                  title={"Disable"}
                  id={0}
                  variavel={white}
                  setvariavel={setwhite}
                />
              </div>
              <div className="col col-2">
                <Check
                  title={"Enable"}
                  id={1}
                  variavel={white}
                  setvariavel={setwhite}
                />
              </div>
              <div className="col col-4">
                <small>You can enable/disable whitelist anytime.</small>
              </div>
            </div>
            <hr style={{ margin: "15px 0" }} />
            <div className="row">
              <div className="col col-3">
                <InputBlock
                  title={
                    "softcap  " +
                    (currency === 0
                      ? " SOL"
                      : currency === 1
                      ? " USDC"
                      : currency === 2
                      ? " USDT"
                      : " SOL") +
                    "*"
                  }
                  place={"0"}
                  variavel={softcap}
                  setvariavel={setsoftcap}
                  desc={"Minimum capitalization"}
                />
              </div>
              <div className="col col-3">
                <InputBlock
                  title={
                    "hardcap  " +
                    (currency === 0
                      ? " SOL"
                      : currency === 1
                      ? " USDC"
                      : currency === 2
                      ? " USDT"
                      : " SOL") +
                    "*"
                  }
                  place={"0"}
                  variavel={hardcap}
                  setvariavel={sethardcap}
                  desc={"Maximum capitalization"}
                />
              </div>
              <div className="col col-3">
                <InputBlock
                  title={
                    "Minimum buy" +
                    (currency === 0
                      ? " SOL"
                      : currency === 1
                      ? " USDC"
                      : currency === 2
                      ? " USDT"
                      : " SOL") +
                    "*"
                  }
                  place={"0"}
                  variavel={minbuy}
                  setvariavel={setminbuy}
                  desc={"Minimum buy per wallet"}
                />
              </div>
              <div className="col col-3">
                <InputBlock
                  title={
                    "Maximum buy  " +
                    (currency === 0
                      ? " SOL"
                      : currency === 1
                      ? " USDC"
                      : currency === 2
                      ? " USDT"
                      : " SOL") +
                    "*"
                  }
                  place={"0"}
                  variavel={maxbuy}
                  setvariavel={setmaxbuy}
                  desc={"Maximum buy per wallet"}
                />
              </div>
            </div>
            <div className="row">
              <div className="col col-3">
                <Checklist
                  title={"Refund Type"}
                  untitle={"Refund"}
                  ops={["Refund", "Burn"]}
                  setvariavel={(e) => {
                    setrefundType(e === "Refund" ? 0 : 1);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col col-6">
                <div className="title-input">Start time</div>
                <DatePicker
                  selected={startTime}
                  onChange={(date) => toUTC(setstartTime, date)}
                  touchUi={true}
                  showTimeSelect
                  timeFormat="HH:mm aa"
                  timeIntervals={5}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                  customInput={<CustomInput time={startTime} />}
                />
                <small>Sale start time</small>
              </div>
              <div className="col col-6">
                <div className="title-input">End time</div>
                <DatePicker
                  selected={endTime}
                  onChange={(date) => toUTC(setendTime, date)}
                  touchUi={true}
                  showTimeSelect
                  timeFormat="HH:mm aa"
                  timeIntervals={5}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                  customInput={<CustomInput time={endTime} />}
                />
                <small>Sale End time</small>
              </div>
            </div>
          </div>
          <div className="group-buttons">
            <Button
              func={() => {
                setProgress(((step - 1) * 20).toString() + "%");
                setstep(step - 1);
              }}
              text={"BACK"}
              next={false}
              className="default"
            />
            <Button
              func={() => {
                try {
                  assert(presale_rate <= 0, "Presale value invalid");
                  assert(softcap <= 0, "softcap value invalid");
                  assert(minbuy <= 0, "Minimum buy value invalid");
                  assert(
                    parseFloat(softcap) < parseFloat(hardcap) / 4,
                    "Soft cap had to be 25% from hardcap"
                  );
                  assert(
                    parseFloat(maxbuy) <= parseFloat(minbuy),
                    "Minimum buy > or equal Maximum buy"
                  );
                  assert(
                    parseInt(Date.parse(startTime)) <= parseInt(now()),
                    "start after now"
                  );
                  assert(
                    parseInt(Date.parse(endTime)) <=
                      parseInt(Date.parse(startTime)),
                    "finish time < start time"
                  );

                  nextstep();
                } catch (error) {}
              }}
              text={"NEXT"}
              next={true}
              className="next"
            />
          </div>
        </>
      )}
      {progress() === "40%" && (
        /*-step-3------------------------------------------------------------------------------*/
        <>
          <div className="title-section-form">Vesting Owner</div>
          <div className="form">
            <div className="title-input" style={{ marginLeft: "10px" }}>
              Enable?
            </div>
            <div className="row">
              <div className="col col-2">
                <Check
                  title={"Disable"}
                  id={0}
                  variavel={ovesting}
                  setvariavel={setovesting}
                />
              </div>
              <div className="col col-2">
                <Check
                  title={"Enable"}
                  id={1}
                  variavel={ovesting}
                  setvariavel={setovesting}
                />
              </div>
              <div className="col col-4">
                <small>You can enable/disable.</small>
              </div>
            </div>
            <hr style={{ margin: "15px 0" }} />
            <div className="row">
              <div className="col col-4">
                <InputBlock
                  title={"Vesting Initial (%)"}
                  place={"< 95%"}
                  variavel={VI}
                  setvariavel={setVI}
                  desc={
                    "How much from capitalization come to you when finalize?"
                  }
                />
              </div>
              <div className="col col-4">
                <InputBlock
                  title={"Vesting Circle (minutes)"}
                  place={"> 5 minutes"}
                  variavel={VC}
                  setvariavel={setVC}
                  desc={"How much time for get next circle?"}
                />
              </div>
              <div className="col col-4">
                <InputBlock
                  title={"Vesting Per Circle (%)"}
                  place={"MAX 100% - vesting inicial"}
                  variavel={VPC}
                  setvariavel={setVPC}
                  desc={"How much from capitalization come to you per circle"}
                />
              </div>
            </div>
          </div>
          <div className="title-section-form">Vesting User</div>
          <div className="form">
            <div className="title-input" style={{ marginLeft: "10px" }}>
              Enable?
            </div>
            <div className="row">
              <div className="col col-2">
                <Check
                  title={"Disable"}
                  id={0}
                  variavel={uvesting}
                  setvariavel={setuvesting}
                />
              </div>
              <div className="col col-2">
                <Check
                  title={"Enable"}
                  id={1}
                  variavel={uvesting}
                  setvariavel={setuvesting}
                />
              </div>
              <div className="col col-4">
                <small>You can enable/disable.</small>
              </div>
            </div>
            <hr style={{ margin: "15px 0" }} />
            <div className="row">
              <div className="col col-6">
                <div className="title-input">User claim started</div>
                <DatePicker
                  selected={UVFC}
                  onChange={(date) => toUTC(setUVFC, date)}
                  touchUi={true}
                  showTimeSelect
                  timeFormat="HH:mm aa"
                  timeIntervals={5}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                  customInput={<CustomInput time={UVFC} />}
                />
                <small>Sale End time</small>
              </div>
              <div className="col col-6">
                <InputBlock
                  title={"User Vesting Initial (%)"}
                  place={"< 95%"}
                  variavel={UVI}
                  setvariavel={setUVI}
                  desc={
                    "How much from capitalization come to user on first claim?"
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col col-6">
                <InputBlock
                  title={"User Vesting Circle (minutes)"}
                  place={"> 5 minutes"}
                  variavel={UVC}
                  setvariavel={setUVC}
                  desc={"How much time for get next circle?"}
                />
              </div>
              <div className="col col-6">
                <InputBlock
                  title={"User Vesting Per Circle (%)"}
                  place={"MAX 100% - vesting inicial"}
                  variavel={UVPC}
                  setvariavel={setUVPC}
                  desc={"How much from capitalization come to user per circle"}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              height: "min-content",
              width: "100%",
              display: "grid",
              gridTemplateColumns: "auto",
              gap: "2px",
              margin: "auto",
            }}
          >
            <p
              style={{ margin: "auto", textAlign: "center", fontSize: "14px" }}
            >
              This launch request: <br />
              {amountTo} {tokenB.symbol}
            </p>
          </div>
          <div
            style={{
              height: "min-content",
              width: "100%",
              display: "grid",
              gridTemplateColumns: "auto",
              gap: "2px",
              margin: "auto",
            }}
          >
            <p
              style={{
                margin: "auto",
                textAlign: "center",
                color: "#999",
                fontSize: "14px",
              }}
            >
              * Please check all data before continue *
            </p>
          </div>
          <div className="group-buttons">
            <Button
              func={() => {
                setProgress(((step - 1) * 20).toString() + "%");
                setstep(step - 1);
              }}
              text={"BACK"}
              next={false}
              className="default"
            />
            <Button
              func={() => {
                try {
                  if (ovesting != 0) {
                    assert(
                      !(parseFloat(VI) + parseFloat(VPC) <= 100),
                      "Owner vesting inicial + vesting per circle needs to be less than 100%"
                    );
                    assert(
                      !(parseFloat(VI) <= 95),
                      "Owner vesting inicial needs to be less than 95%"
                    );
                    assert(
                      !(parseFloat(VPC) <= 99),
                      "Vesting per circle needs to be less than 99%"
                    );
                    assert(
                      !(parseFloat(VI) >= 1),
                      "Owner vesting inicial needs to be greater than 1%"
                    );
                    assert(
                      !(parseFloat(VPC) >= 1),
                      "Vesting per circle needs to be greater than 1%"
                    );
                  }

                  if (uvesting != 0) {
                    assert(
                      !(parseFloat(UVI) + parseFloat(UVPC) <= 100),
                      "User vesting inicial + User vesting per circle needs to be less than 100%"
                    );
                    assert(
                      !(parseFloat(UVI) <= 95),
                      "User vesting inicial needs to be less than 95%"
                    );
                    assert(
                      !(parseFloat(UVPC) <= 99),
                      "User vesting per circle needs to be less than 99%"
                    );
                    assert(
                      !(parseFloat(UVI) >= 1),
                      "User vesting inicial needs to be greater than 1%"
                    );
                    assert(
                      !(parseFloat(UVPC) >= 1),
                      "User vesting per circle needs to be greater than 1%"
                    );
                    assert(
                      parseInt(Date.parse(UVFC) / 1000) <=
                        parseInt(Date.parse(endTime) / 1000),
                      "Initial user claim time < finish time "
                    );
                  }

                  nextstep();
                } catch (error) {}
              }}
              text={"NEXT"}
              next={true}
              className="next"
            />
          </div>
        </>
      )}
      {progress() === "60%" && (
        /*-step-4------------------------------------------------------------------------------*/
        <>
          <div className="form">
            <div className="row">
              <div className="col col-4">
                <InputBlock
                  title={"Image Upload Url *"}
                  place={"https://..."}
                  variavel={LogoUrl}
                  setvariavel={setLogoUrl}
                  desc={""}
                />
              </div>
              <div className="col col-4">
                <InputBlock
                  title={"Website *"}
                  place={"https://..."}
                  variavel={Website}
                  setvariavel={setWebsite}
                  desc={""}
                />
              </div>
              <div className="col col-4">
                <InputBlock
                  title={"Github"}
                  place={"https://..."}
                  variavel={Github}
                  setvariavel={setGithub}
                  desc={""}
                />
              </div>
            </div>

            <div className="row">
              <div className="col col-4">
                <InputBlock
                  title={"Facebook"}
                  place={"https://..."}
                  variavel={Facebook}
                  setvariavel={setFacebook}
                  desc={""}
                />
              </div>
              <div className="col col-4">
                <InputBlock
                  title={"Twitter"}
                  place={"https://..."}
                  variavel={Twitter}
                  setvariavel={setTwitter}
                  desc={""}
                />
              </div>
              <div className="col col-4">
                <InputBlock
                  title={"Instagram"}
                  place={"https://..."}
                  variavel={Instagram}
                  setvariavel={setInstagram}
                  desc={""}
                />
              </div>
            </div>

            <div className="row">
              <div className="col col-4">
                <InputBlock
                  title={"Telegram"}
                  place={"https://..."}
                  variavel={Telegram}
                  setvariavel={setTelegram}
                  desc={""}
                />
              </div>
              <div className="col col-4">
                <InputBlock
                  title={"Discord"}
                  place={"https://..."}
                  variavel={Discord}
                  setvariavel={setDiscord}
                  desc={""}
                />
              </div>
              <div className="col col-4">
                <InputBlock
                  title={"Reddit"}
                  place={"https://..."}
                  variavel={Reddit}
                  setvariavel={setReddit}
                  desc={""}
                />
              </div>
            </div>

            <div className="row">
              <div className="col col-4">
                <InputBlock
                  title={"YoutubeVideo Video url or banner url"}
                  place={"https://..."}
                  variavel={YoutubeVideo}
                  setvariavel={setYoutubeVideo}
                  desc={""}
                />
              </div>
            </div>

            <div className="row">
              <div className="col col-12">
                <DescBlock
                  title={"Description"}
                  place={"Description..."}
                  variavel={Description}
                  setvariavel={setDescription}
                  desc={""}
                />
              </div>
            </div>
          </div>

          <div className="group-buttons">
            <Button
              func={() => {
                setProgress(((step - 1) * 20).toString() + "%");
                setstep(step - 1);
              }}
              text={"BACK"}
              next={false}
              className="default"
            />
            <Button
              func={async () => {
                nextstep();
              }}
              text={"NEXT"}
              next={true}
              className="next"
            />
          </div>
        </>
      )}
      {progress() === "80%" && (
        /*-step-3------------------------------------------------------------------------------*/
        <>
          <div className="form">
            <div className="row">
              <div className="col col-6">
                <SAFE what={"Total token"} result={amountTo} />
              </div>
              <div className="col col-6">
                <SAFE what={"Factory Address"} result={contract} />
              </div>
            </div>
            <div className="row">
              <div className="col col-6">
                <SAFE what={"Token name"} result={tokenB.name} />
              </div>
              <div className="col col-6">
                <SAFE what={"Token symbol"} result={tokenB.symbol} />
              </div>
            </div>
            <div className="row">
              <div className="col col-6">
                <SAFE what={"Token decimals"} result={tokenB.dec} />
              </div>
              <div className="col col-6">
                <SAFE what={"Private rate"} result={presale_rate} />
              </div>
            </div>
            <div className="row">
              <div className="col col-6">
                <SAFE what={"Sale method"} result={"Private-Sale"} />
              </div>
              <div className="col col-6">
                <SAFE what={"Affiliate"} result={Affiliate + "%"} />
              </div>
            </div>
            <div className="row">
              <div className="col col-6">
                <SAFE what={"softcap"} result={softcap} />
              </div>
              <div className="col col-6">
                <SAFE what={"hardcap"} result={hardcap} />
              </div>
            </div>
            <div className="row">
              <div className="col col-6">
                <SAFE
                  what={"Unsold tokens"}
                  result={refundType === 1 ? "Burn" : "Refund"}
                />
              </div>
              <div className="col col-6">
                <SAFE what={"Minimum buy"} result={minbuy} />
              </div>
            </div>
            <div className="row">
              <div className="col col-6">
                <SAFE what={"Maximum buy"} result={maxbuy} />
              </div>
              <div className="col col-6">
                <SAFE what={"Start time"} result={parseUTC(startTime)} />
              </div>
            </div>
            <div className="row">
              <div className="col col-6">
                <SAFE what={"End time"} result={parseUTC(endTime)} />
              </div>
              <div className="col col-6">
                <SAFE what={"Website"} result={Website} />
              </div>
            </div>
            <hr style={{ margin: "15px 0" }} />
            <div className="row">
              <div className="col col-12">
                <div className="warning-message">
                  <WarningAmberOutlinedIcon
                    sx={{
                      fill: "#babd41",
                      width: "1.1rem",
                      height: "1.1rem",
                    }}
                  />
                  {
                    "To authenticate and edit your metadata, copy and save this on a safe place"
                  }
                </div>

                <div className="secret-key">
                  {JSON.stringify(pda.secretKey)}
                </div>

                <Button
                  isButtonLarge={false}
                  func={() => {
                    navigator.clipboard.writeText(
                      JSON.stringify(pda.secretKey)
                    );

                    Emit(
                      "Copied",
                      "Now save in a safe place, this is your authentication to edit your sale metadata!!",
                      "load"
                    );
                  }}
                  text={"COPY"}
                  next={false}
                  className="next"
                />
              </div>
            </div>
          </div>
          <div className="group-buttons">
            <Button
              func={() => {
                setProgress(((step - 1) * 20).toString() + "%");
                setstep(step - 1);
              }}
              text={"BACK"}
              next={false}
              className="default"
            />
            <Button
              func={() => {
                create();
              }}
              text={"PUBLISH"}
              next={true}
              className="next"
            />
          </div>
        </>
      )}
    </div>
  );

  function stepCurrent() {
    return step;
  }

  return (
    <>
      <div className="container-launch">
        <div className="content-launch">
          <div className="text-title">Create Private Sale</div>
          <div className="group-create">
            <div className="time-line">
              <div className="group-step">
                <div
                  className={
                    stepCurrent() === 0 || stepCurrent() > 0
                      ? "step-actived"
                      : "step"
                  }
                >
                  <div className="step-number">1</div>
                  <div className="step-title">
                    Verify Token <br />{" "}
                    <p>Enter the token address and verify</p>
                  </div>
                </div>
                <div
                  className={
                    stepCurrent() === 1 || stepCurrent() > 1
                      ? "step-actived"
                      : "step"
                  }
                >
                  <div className="step-number">2</div>
                  <div className="step-title">
                    DeFi Launchpad <br /> <p>Enter the launchpad information</p>
                  </div>
                </div>
                <div
                  className={
                    stepCurrent() === 2 || stepCurrent() > 2
                      ? "step-actived"
                      : "step"
                  }
                >
                  <div className="step-number">3</div>
                  <div className="step-title">
                    Vesting <br />{" "}
                    <p>Enter the vesting owner or vesting user</p>
                  </div>
                </div>
                <div
                  className={
                    stepCurrent() === 3 || stepCurrent() > 3
                      ? "step-actived"
                      : "step"
                  }
                >
                  <div className="step-number">4</div>
                  <div className="step-title">
                    Add Additional Info <br />{" "}
                    <p>
                      Short description about your project and project links
                    </p>
                  </div>
                </div>
                <div
                  className={
                    stepCurrent() === 4 || stepCurrent() > 4
                      ? "step-actived"
                      : "step"
                  }
                >
                  <div className="step-number">5</div>
                  <div className="step-title">
                    Finish <br />{" "}
                    <p>Review your information and submit your presale</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group">{modalcreat}</div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CreatePrivateSale;
