import simples from "./simples/simples"

const tokens =
    [
        {
            data: simples,
            params: (...args) => {
                return [
                    ['string', 'string', 'uint8', 'uint256'], // Tipos dos parâmetros do construtor
                    [...args] // Valores dos parâmetros
                ]
            }
        }
    ]
export default tokens 