import { BN } from "@coral-xyz/anchor";
import Button from "../../../../../components/Launchpad/Button";
import InputBlock from "../../../../../components/Launchpad/InputBlock";

import Checkbox from "../../../../../components/Launchpad/Checkbox";
import SessionDrop from "../../SessionDrop";
import { useState } from "react";
import DescBlock from "../../../../../components/Launchpad/descBlock";
import { Keypair, PublicKey, SystemProgram } from "@solana/web3.js";
import SAFE from "../../../../../components/Launchpad/textpar";
import { Contract, ptx } from "../../../../../programs/programs";
import { programNetwork } from "../../../../../programs/utils";

function WhitelistHub({ object, nSendTX, setwhite, program }) {


    const [tier, setTier] = useState(0)
    const [enable, setenable] = useState(object == undefined ? 0 : parseInt(object.enableWL))
    const [tierTimeOut, setTierTimeout] = useState(0)

    const [whitelist, setwhitelist] = useState(
        document.getElementById("whitelistINPUT")?.value?.split(",")
    );

    return (
        <>
            <div className="form">
                { /*  <div className="title-input" style={{ marginLeft: "10px" }}>
                    Whitelist
    </div>*/}
                <SAFE
                    what={"Whitelist"}
                    result={
                        object.enableWL != undefined
                            ? parseInt(object.enableWL) == 0
                                ? "Status: Public"
                                : "Status: Enable"
                            : "-"
                    }
                />

                {parseInt(object.enableWL) == 0
                    && <SessionDrop
                        pos={0}
                        selected={enable}
                        setSelected={setenable}
                        title={"Enable"}
                        content={

                            <>
                                <div className="row">
                                    <div className="col">
                                        <Checkbox
                                            title={"No Tier"}
                                            id={0}
                                            variavel={tier}
                                            setvariavel={setTier}
                                        />
                                    </div>
                                    <div className="col">
                                        <Checkbox
                                            title={"With Tier"}
                                            id={1}
                                            variavel={tier}
                                            setvariavel={setTier}
                                        />
                                    </div>

                                </div>
                                <div className="row">

                                    <div className="col col-12">
                                        {tier == 1 &&
                                            <InputBlock
                                                title={"Tier 1 timeout (minutes)"}
                                                place={"0"}
                                                id={"wl-timeout"}
                                                //    variavel={percentHC}
                                                setvariavel={(e) => {
                                                    // set(e);
                                                }}
                                                desc={""}
                                            />
                                        }
                                    </div>
                                    {programNetwork(object.program).name == "solana" && <div className="col col-12">
                                        <InputBlock
                                            title={"Number of wallets in Whitelist on total (No tier and with tier) *"}
                                            place={"0"}
                                            id={"wl-length"}
                                            //    variavel={percentHC}
                                            setvariavel={(e) => {
                                                // set(e);
                                            }}
                                            desc={"The more wallets the higher the solana storage fee. There may be errors if the number of wallets entered is greater than that specified here!"}
                                        />
                                    </div>}
                                </div>
                                <Button
                                    func={async () => {
                                        try {
                                            if (
                                                parseInt(object.enableWL) == 0
                                            ) {
                                                const pn = programNetwork(object.program).name
                                                switch (pn) {
                                                    case "solana":
                                                        const { provider, publicKey } = window.solpadContext
                                                        let wlA = await program.try(
                                                            async () => {
                                                                const wladdress = await program.methods.whitelistAddress.view({})
                                                                if (wladdress == undefined) {
                                                                    return undefined
                                                                }
                                                                if (wladdress.toString() == "11111111111111111111111111111111") {
                                                                    return undefined
                                                                }
                                                                return wladdress.toString()
                                                            }
                                                        )


                                                        const canCreate = wlA == undefined

                                                        const acc = Keypair.generate();
                                                        const minbites = 10240
                                                        const bites = parseInt(document.getElementById("wl-length")?.value) * 256
                                                        const bitesFinal = bites > minbites ? bites : minbites
                                                        const createWA = canCreate ? [
                                                            SystemProgram.createAccount({
                                                                fromPubkey: publicKey,
                                                                newAccountPubkey: acc.publicKey,
                                                                lamports: 7050 * bitesFinal,
                                                                space: bitesFinal,
                                                                programId: new PublicKey("ZM4wAVoto9qSJ6ZFZmc7Bqo43KQyDkFJhWDMBLL5X4L"),
                                                            }),
                                                            await (new Contract(
                                                                acc.publicKey,
                                                                new PublicKey("ZM4wAVoto9qSJ6ZFZmc7Bqo43KQyDkFJhWDMBLL5X4L"),
                                                                require("../../../../../abis/whitelist/whitelist.json")
                                                            )).methods.new.instruction([], [], {pool:new PublicKey(object.bank)})

                                                        ] : []
                                                        await program.methods.setIoWl.send([...createWA], canCreate ? [acc] : [],
                                                            {
                                                                wlAddress: canCreate ? acc.publicKey : wlA,

                                                            },
                                                            new BN(1),
                                                            tier == 0
                                                                ? new BN(0)
                                                                : new BN(document.getElementById("wl-timeout")?.value * 60),
                                                            acc.publicKey
                                                        )
                                                        break;
                                                    case "tron":
                                                        await nSendTX("setIoWl", 0, 0, 1, tier == 0
                                                            ? 0
                                                            : document.getElementById("wl-timeout")?.value * 60)
                                                        break;
                                                    default:
                                                        await nSendTX("setIoWl", 0, 0, 1, tier == 0
                                                        ? 0
                                                        : document.getElementById("wl-timeout")?.value * 60)
                                                    break;
                                                }


                                            }
                                        } catch (error) {
                                            console.log(error)
                                        }

                                    }}
                                    text={"Enable Whitelist"}
                                    className="simple-button"

                                />
                            </>
                        }
                    />}

                {parseInt(object.enableWL) > 0
                    && <SessionDrop
                        pos={1}
                        selected={enable}
                        setSelected={setenable}
                        title={"Setup"}
                        content={
                            enable == 1 &&
                            <>
                                <div className="row">
                                    <div className="col">
                                        <Checkbox
                                            title={"No Tier"}
                                            id={0}
                                            variavel={tier}
                                            setvariavel={setTier}
                                        />
                                    </div>
                                    <div className="col">
                                        <Checkbox
                                            title={"Tier 1"}
                                            id={1}
                                            variavel={tier}
                                            setvariavel={setTier}
                                        />
                                    </div>
                                    <div className="col">
                                        <Checkbox
                                            title={"Tier 2"}
                                            id={2}
                                            variavel={tier}
                                            setvariavel={setTier}
                                        />
                                    </div>
                                </div>

                                <hr style={{ margin: "10px 0" }} />
                                <div className="row">
                                    <div className="col col-12">
                                        <DescBlock
                                            title={""}
                                            id={"whitelistINPUT"}
                                            place={"ex: wallet, wallet,..."}
                                            max={999999999}
                                            variavel={whitelist}
                                            setvariavel={(e) => {
                                                // console.log(e, e.split(","))
                                                setwhitelist(e.split(","));
                                            }}
                                            desc={""}
                                        />
                                    </div>


                                </div>

                            </>
                        }
                    />}

                {/* <div className="row">
                    {/* <div className="col col-6">
                  <Checkbox
                    title={"Disable"}
                    id={0}
                    variavel={parseInt(object.enableWL)}
                    setvariavel={(e) => {
                      if (parseInt(object.enableWL) == 1) {
                        nSendTX("setIoWl", 0, 0, new BN(e));
                      }
                      setwhite(e);
                    }}
                  />
                  </div>}
                    <div className="col col-6">
                        <Checkbox
                            title={"Enable"}
                            id={1}
                            variavel={parseInt(object.enableWL)}
                            setvariavel={(e) => {
                                if (parseInt(object.enableWL) == 0) {
                                    nSendTX("setIoWl", 0, 0, new BN(e));
                                }
                                setwhite(e);
                            }}
                        />
                    </div>
                </div>*/}

                {parseInt(object.enableWL) > 0
                    && <>
                        <hr style={{ margin: "10px 0" }} />
                        <Button
                            func={async () => {
                                try {
                                    switch (programNetwork(object.program).name) {
                                        case "solana":
                                            if (whitelist.length > 80) {
                                            } else {
                                                const j = [];
                                                for (let index = 0; index < whitelist.length; index++) {
                                                    try {
                                                        j.push(new PublicKey(whitelist[index].trim()));
                                                    } catch (error) { }
                                                }
                                                await program.writeWhitelist(tier, j);
                                            }
                                            break;
                                        case "tron":
                                            const j = [];
                                            for (let index = 0; index < whitelist.length; index++) {
                                                try {
                                                    j.push(whitelist[index].trim());
                                                } catch (error) { }
                                            }
                                            //  console.log(j)
                                            await nSendTX(
                                                (tier == 2)
                                                    ? "addToWhiteListTierTwo"
                                                    : "addToWhiteList", 0, 0, j)

                                            break;
                                        default:
                                            const e = [];
                                            for (let index = 0; index < whitelist.length; index++) {
                                                try {
                                                    e.push(whitelist[index].trim());
                                                } catch (error) { }
                                            }
                                            //  console.log(j)
                                            await nSendTX(
                                                (tier == 2)
                                                    ? "addToWhiteListTierTwo"
                                                    : "addToWhiteList", 0, 0, e)

                                            break;
                                    }

                                } catch (error) {

                                }

                            }}
                            text={`Add to Whitelist ${tier != 0 ? " Tier " + tier : ""}`}
                            className="simple-button"
                        />
                        <small>
                            your entry have{" "}
                            {whitelist?.length != undefined ? whitelist?.length : 0}{" "}
                            wallets
                        </small>{" "}
                        {programNetwork(object.program).name == "solana" ? <small>80 wallets per entry at a time</small> : <small>200 wallets per entry at a time</small>}
                    </>}
                {parseInt(object.enableWL) > 0
                    &&
                    <Button
                        func={async () => {
                            try {
                                const pn = programNetwork(object.program).name
                                switch (pn) {
                                    case "solana":
                                        if (parseInt(object.enableWL) == 1) {
                                            let wlA = await program.try(
                                                async () => {
                                                    const wladdress = await program.methods.whitelistAddress.view({})
                                                    if (wladdress == undefined) {
                                                        return undefined
                                                    }
                                                    if (wladdress.toString() == "11111111111111111111111111111111") {
                                                        return undefined
                                                    }
                                                    return wladdress.toString()
                                                }
                                            )
                                            await program.methods.setIoWl.send([], [],
                                                {
                                                    wlAddress: new PublicKey(wlA),

                                                },
                                                new BN(0),
                                                new BN(0),
                                                new PublicKey(wlA)
                                            )
                                            //  nSendTX("setIoWl", 0, 0, new BN(0),new BN(0), );
                                        }
                                        break;
                                    case "tron":
                                        await nSendTX("setIoWl", 0, 0, 0, 0)
                                        break;
                                    default:
                                        await nSendTX("setIoWl", 0, 0, 0, 0)
                                }
                                setwhite(0);
                            } catch (error) {

                            }
                        }

                        }
                        text={"Disable Whitelist"}
                        className="simple-button"
                        style={{ background: "red" }}
                    />
                }
            </div>
        </>
    );
}
export default WhitelistHub