import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.scss";
import Dropdown from "./Dropdown";
import assets from "../../assets";

function Navbar({
  WButton,
  selected,
  openSettins,
  rpcs,
  select,
  setPriority,
  setCustom,
}) {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [number, setNumber] = useState(null);

  const [activedColor, setActivedColor] = useState(false);

  useEffect(function () {
    function positionScroll() {
      if (window.scrollY > 40) {
        setActivedColor(true);
      } else {
        setActivedColor(false);
      }
    }

    window.addEventListener("scroll", positionScroll);
  }, []);

  const pri = JSON.parse(localStorage.getItem("spad-settings"));
  const handleClick = () => setClick(!click);

  const closeMobileMenu = () => {
    if (window.innerWidth < 960) {
      setClick(true);
    } else {
      setClick(false);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  const onMouseEnter = (number) => {
    setNumber(number);

    if (number) {
      if (window.innerWidth < 960) {
        setDropdown(true);
      } else {
        setDropdown(true);
      }
    }
  };

  return (
    <>
      <nav className={activedColor ? "navbar menuFixo" : "navbar"}>
        <div className="group-navbar">
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <>
            <ul className={click ? "nav-menu active-nav-menu" : "nav-menu"}>
              <li className="nav-item">
                <Link to="/" className="nav-links" onClick={handleClick}>
                  Home
                </Link>
              </li>
              <li
                className="nav-item"
                onMouseEnter={() => onMouseEnter(1)}
                onMouseLeave={onMouseLeave}
              >
                <Link className="nav-links" onClick={closeMobileMenu}>
                  Launchpads <i class="fa fa-chevron-down" />
                </Link>
                {dropdown && number === 1 && <Dropdown i={1} />}
              </li>
              <li
                className="nav-item"
                onMouseEnter={() => onMouseEnter(2)}
                onMouseLeave={onMouseLeave}
              >
                <Link className="nav-links" onClick={closeMobileMenu}>
                  Private Sale <i class="fa fa-chevron-down" />
                </Link>
                {dropdown && number === 2 && <Dropdown i={2} />}
              </li>
              <li className="nav-item">
                <Link
                  to="/revenue "
                  className="nav-links"
                  onClick={handleClick}
                >
                  Revenue
                </Link>
              </li>
              <li
                className="nav-item"
                onMouseEnter={() => onMouseEnter(3)}
                onMouseLeave={onMouseLeave}
              >
                <Link className="nav-links" onClick={closeMobileMenu}>
                  Token Builder <i class="fa fa-chevron-down" />
                </Link>
                {dropdown && number === 3 && <Dropdown i={3} />}
              </li>
              <li
                className="nav-item"
                onMouseEnter={() => onMouseEnter(4)}
                onMouseLeave={onMouseLeave}
              >
                <Link className="nav-links" onClick={closeMobileMenu}>
                  Locker <i class="fa fa-chevron-down" />
                </Link>
                {dropdown && number === 4 && <Dropdown i={4} />}
              </li>
            </ul>
            <div className="wallet-button">
              <WButton />
            </div>
          </>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
