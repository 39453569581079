import React from "react";

function InputBlock(props) {
  return (
    <div className="group-input">
      <div className="title-input">{props.title}</div>
      {props.variavel === undefined ? (
        <input
          id={props.id === undefined ? "" : props.id}
          type={props.type === undefined ? "text" : props.type}
          onChange={(e) => {
            props.setvariavel(e.target.value);
          }}
          placeholder={props.place}
          disabled={props.disabled != undefined ? props.disabled : false}
        ></input>
      ) : (
        <input
          id={props.id === undefined ? "" : props.id}
          type={props.type === undefined ? "text" : props.type}
          onChange={(e) => {
            props.setvariavel(e.target.value);
          }}
          disabled={props.disabled != undefined ? props.disabled : false}
          placeholder={props.place}
          value={props.variavel}
        ></input>
      )}

      <small>{props.desc}</small>
    </div>
  );
}

export default InputBlock;
