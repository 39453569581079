// export const MenuItems = [
//   {
//     title: "Create Presale",
//     path: "/create/presale",
//     cName: "dropdown-link",
//     index: 1,
//   },
//   {
//     title: "Create Fair Launch",
//     path: "/create/fairlaunch",
//     cName: "dropdown-link",
//     index: 1,
//   },
//   {
//     title: "Launchpad List",
//     path: "/launchpads",
//     cName: "dropdown-link",
//     index: 1,
//   },
//   {
//     title: "Create Private Sale",
//     path: "/create/privatesales",
//     cName: "dropdown-link",
//     index: 2,
//   },
//   {
//     title: "Private Sale List",
//     path: "/privatesales",
//     cName: "dropdown-link",
//     index: 2,
//   },
//   {
//     title: "Create Token",
//     path: "/create/token",
//     cName: "dropdown-link",
//     index: 3,
//   },
//   {
//     title: "Edit Token",
//     path: "/edit/token",
//     cName: "dropdown-link",
//     index: 3,
//   },
//   {
//     title: "Burn Token",
//     path: "/burn/token",
//     cName: "dropdown-link",
//     index: 3,
//   },
//   {
//     title: "Create Token Lock",
//     path: "/create/lock/token",
//     cName: "dropdown-link",
//     index: 4,
//   },
//   {
//     title: "Create Liquidity Lock",
//     path: "/create/lock/liquidity",
//     cName: "dropdown-link",
//     index: 4,
//   },
//   {
//     title: "My Tokens Lock",
//     path: "/lock/token/user/list",
//     cName: "dropdown-link",
//     index: 4,
//   },
//   {
//     title: "My Liquidity Lock",
//     path: "/lock/liquidity/user/list",
//     cName: "dropdown-link",
//     index: 4,
//   },
//   {
//     title: "Token Lock List",
//     path: "/lock/token/list",
//     cName: "dropdown-link",
//     index: 4,
//   },


//   {
//     title: "Liquidity Locks List",
//     path: "/lock/liquidity/list",
//     cName: "dropdown-link",
//     index: 4,
//   },
// ];
export const MenuItems = [
  {
    title: "Create Presale",
    path: "/create/presale",
    cName: "dropdown-link",
    index: 1,
  },
  {
    title: "Create Fair Launch",
    path: "/create/fairlaunch",
    cName: "dropdown-link",
    index: 1,
  },
  {
    title: "Launchpad List",
    path: "/launchpads",
    cName: "dropdown-link",
    index: 1,
  },
  {
    title: "Create Private Sale",
    path: "/create/privatesales",
    cName: "dropdown-link",
    index: 2,
  },
  {
    title: "Private Sale List",
    path: "/privatesales",
    cName: "dropdown-link",
    index: 2,
  },
  {
    title: "Create Token",
    path: "/create/token",
    cName: "dropdown-link",
    index: 3,
  },
  {
    title: "Edit Token",
    path: "/edit/token",
    cName: "dropdown-link",
    index: 3,
  },
  {
    title: "Burn Token",
    path: "/burn/token",
    cName: "dropdown-link",
    index: 3,
  },
  {
    title: "Create lock",
    path: "",
    cName: "dropdown-link",
    index: 4,
    sub: [
      {
        title: "Token",
        path: "/create/lock/token",
        cName: "dropdown-link-sub",
        index: 4,
      },
      {
        title: "Liquidity",
        path: "/create/lock/liquidity",
        cName: "dropdown-link-sub",
        index: 4,
      },
    ]
  },
  {
    title: "My Locks",
    path: "",
    cName: "dropdown-link",
    index: 4,
    sub: [
      {
        title: "Tokens",
        path: "/lock/token/user/list",
        cName: "dropdown-link-sub",
        index: 4,
      },
      {
        title: "Liquidity",
        path: "/lock/liquidity/user/list",
        cName: "dropdown-link-sub",
        index: 4,
      },
    ]
  },
  {
    title: "Lock list",
    path: "",
    cName: "dropdown-link",
    index: 4,
    sub: [
      {
        title: "Token",
        path: "/lock/token/list",
        cName: "dropdown-link-sub",
        index: 4,
      },


      {
        title: "Liquidity",
        path: "/lock/liquidity/list",
        cName: "dropdown-link-sub",
        index: 4,
      },
    ]
  },





];
