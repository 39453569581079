import React from "react";
import assets from "../../../../assets/index";
import "../../style.scss";
import { format } from "../../../../programs/utils";

const SpadPrice = ({ price , supply, totalStaked, apr}) => {
  return (
    <>
      <div className="col col-3">
        <div className="form group-spad">
          <img src={`${assets.images.spadPrice}`} alt="" />
          <div className="group-spad__item">
            <div className="group-spad__item--title">$SPAD Price</div>
            <div className="group-spad__item--value">${price}</div>
          </div>
          <div className="group-spad__percente"></div>
        </div>
      </div>
      <div className="col col-3">
        <div className="form group-spad">
          <img src={`${assets.images.spadSupply}`} alt="" />
          <div className="group-spad__item">
            <div className="group-spad__item--title">$SPAD Supply</div>
            <div className="group-spad__item--value">{supply}</div>
          </div>
          <div className="group-spad__percente">${format(parseFloat(supply) * parseFloat(price))}</div>
        </div>
      </div>
      <div className="col col-3">
        <div className="form group-spad">
          <img src={`${assets.images.spadStaked}`} alt="" />
          <div className="group-spad__item">
            <div className="group-spad__item--title">$SPAD Staked</div>
            <div className="group-spad__item--value">{totalStaked}</div>
          </div>
          <div className="group-spad__percente">${format(parseFloat(totalStaked) * parseFloat(price))}</div>
        </div>
      </div>
      <div className="col col-3">
        <div className="form group-spad">
          <img src={`${assets.images.averageApy}`} alt="" />
          <div className="group-spad__item">
            <div className="group-spad__item--title">Average APY</div>
            <div className="group-spad__item--value">{format(apr)}%</div>
          </div>
          <div className="group-spad__percente">EARN SOL</div>
        </div>
      </div>
    </>
  );
};
export default SpadPrice;
