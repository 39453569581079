import React from "react";

export default ({ pos, selected, setSelected, title, status }) => {
    return (
        <div onClick={() => { setSelected(pos) }} style={{ height: "59px", background: "#fff", width: "auto", display: "flex", gap: "5px", fontSize: "10px", justifyContent: "space-between", padding: "0px 10px" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "5px", }}>
                <p className="Heavy" style={{ height: "38px", width: "38px", fontSize: "16px", color: "#fff", background: status == "success" ? "#3ebd61" : status == "error" ? "#ff0000" : "#0098EA", borderRadius: "90px", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {status == "load" && <>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                        </svg>
                    </>}
                    {status == "success" && <>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                            <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                            <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                        </svg>
                    </>}
                    {status == "error" && <>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                        </svg>
                    </>}
                </p>
                <p className="Poppins" style={{ fontSize: "15px", color: "#000", textAlign: "left" }}>{title}</p>
            </div>
            <svg style={{ fontSize: "24px", color: "#000", margin: "auto", marginRight: "0px", transform: `rotate(${selected == pos ? 180 : 0}deg)` }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
            </svg>
        </div>
    )
}