import React from "react";
import { nextblock, programNetwork, quote } from "../../../../programs/utils";
import TokenNomics from "../../../../components/Launchpad/TokenNomics";

const TokenModal = ({
    program,
    object,
    SAFE,
    tksforlp,
    WarningAmberOutlinedIcon
}) => {
    if (object == undefined) {
        return (
            <div class="animated-background">
                <div class="background-masker btn-divide-left"></div>
            </div>
        )
    }
    function getTime(lockup) {
        return parseInt(lockup) + " Days"
    }

    return (
        <>
            <div className="form">
                <SAFE what={"Category"} result={"-"} />
                <hr style={{ margin: "5px 0" }} />
                <SAFE
                    what={"Token name"}
                    result={object.tokenname ? object.tokenname : "-"}
                />
                <hr style={{ margin: "5px 0" }} />
                <SAFE
                    what={"Token symbol"}
                    result={object.tokesymbol ? object.tokesymbol : "-"}
                />
                <hr style={{ margin: "5px 0" }} />
                <SAFE what={"Token decimals"} result={object.tokendec} />
                <hr style={{ margin: "5px 0" }} />
                <SAFE
                    what={"Token Address"}
                    result={
                        <p

                        >
                            <span style={{color:"white",cursor:"pointer"}} onClick={() => {

                                window.open(programNetwork(object.program).explorer + program.convertAddress(object.contract));
                            }}>   {program.convertAddress(object.contract)} </span>
                            <svg xmlns="http://www.w3.org/2000/svg" onClick={() => {
                                navigator.clipboard.writeText(
                                    program.convertAddress(object.contract)
                                );
                            }} width="14" height="14" style={{ cursor: "pointer", margin: "auto 5px" }} fill="currentColor" class="bi bi-copy" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z" />
                            </svg>
                            <p />
                            <div className="warning-message">
                                <WarningAmberOutlinedIcon
                                    sx={{
                                        fill: "#babd41",
                                        width: "0.8rem",
                                        height: "0.8rem",
                                    }}
                                />
                                {"Do not send " + quote(programNetwork(object.program).name, object.utoken) + "or tokens to the Token address!"}
                            </div>

                        </p>
                    }
                />
                <hr style={{ margin: "5px 0" }} />
                <SAFE
                    what={"Token Total Supply"}
                    result={
                        nextblock(object.tokents, object.tokendec) +
                        " " +
                        object.tokesymbol
                    }
                />
                <hr style={{ margin: "5px 0" }} />
                <SAFE
                    what={"Liquidity Lockup Time"}
                    result={
                        object.liquidity_lockup != undefined
                            ? getTime(object.liquidity_lockup)
                            : "-"
                    }
                />
            </div>

            {true && (
                //------------------------------------------------------------------
                <>
                    <div className="title-section-form">Token Metrics</div>
                    {object.contract != undefined && (
                        <TokenNomics
                            obj={object}
                            lpamount={tksforlp()}
                            Contract={object.contract}
                        />
                    )}
                </>
            )}
            <p style={{ textAlign: "center" }}>
                {object.listOP != undefined
                    ? object.listOP == 0
                        ? "This is a manual listing therefore it is the full responsibility of the project and its team to input this LP. SolPad is not responsible for adding liquidity to projects that selected manual liquidity listing"
                        : ""
                    : ""}
            </p>
        </>
    );
}
export default TokenModal;