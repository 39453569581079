import React from "react";
import assets from "../../../../assets/index";
import "../../style.scss";
import { format } from "../../../../programs/utils";

const MyWallet = ({
  balance,
  stkA,
  stkR,
  price,
  priceSOLUSD,
  TOKENA,
  TOKENB,
}) => {
  return (
    <>
      <div className="group-wallet__title-card">My Wallet</div>
      {/*<div className="form group-wallet">*/}
      <div className="form row">
        <div className="group-wallet__wallet col col-4">
          <img src={`${assets.images.wallet}`} alt="" width={40} />
          <div className="group-wallet__wallet--items center">
            <div className="group-wallet__wallet--items__title">
              ${TOKENA} Balance
            </div>
            <div className="group-wallet__wallet--items__value">{balance}</div>
            <div className="group-wallet__wallet--items--total">
              ${format(parseFloat(balance) * parseFloat(price))} USD
            </div>
          </div>
        </div>
        <div className="group-wallet__wallet col col-4">
          <img src={`${assets.images.wallet}`} alt="" width={40} />
          <div className="group-wallet__wallet--items center">
            <div className="group-wallet__wallet--items__title">
              Total ${TOKENA} Staked
            </div>
            <div className="group-wallet__wallet--items__value">{stkA}</div>
            <div className="group-wallet__wallet--items--total">
              ${format(parseFloat(stkA) * parseFloat(price))} USD
            </div>
          </div>
        </div>
        <div className="group-wallet__wallet col col-4">
          <img src={`${assets.images.wallet}`} alt="" width={40} />
          <div className="group-wallet__wallet--items">
            <div className="group-wallet__wallet--items__title">
              Total ${TOKENB} to Claim
            </div>
            <div className="group-wallet__wallet--items__value">{stkR}</div>
            <div className="group-wallet__wallet--items--total">
              ${format(parseFloat(stkR) * parseFloat(priceSOLUSD))} USD
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MyWallet;
