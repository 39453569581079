import React, { useState } from "react";
import Button from "./Button";
import SAFE from "../../components/Launchpad/textpar";
import { nextblock, programNetwork, quote } from "../../programs/utils";

const AffiliateHUB = (props) => {
  const [_ct, setCt] = useState("");

  const { account } = window.solpadContext;
  // const account = "CVcH1sY3SZGKWAgKqCHbBXaFvGHFJToDamcEwtwkkbMc"
  const quoteToken = quote(
    programNetwork(props.obj.program).name,
    props.obj.utoken
  );

  function ref(e) {
    try {
      //let a = {};
      let addrs = props.obj.refs.address.split(",");
      let amts = props.obj.refs.amounts.split(",");

      for (let index = 0; index < addrs.length; index++) {
        //console.log(props.obj.refs.amounts[index])
        // console.log(addrs[index])
        const affAddress = props.sale.convertAddress(addrs[index]);
        if (e == affAddress) {
          return parseFloat(nextblock(amts[index], props.obj.quoteDecimals));
        }
      }
      return 0;
    } catch (error) {
      //  console.log(error)
      //   let a = {}
      //a[account?.toString()] = 0
      return 0;
    }
  }
  function afs() {
    try {
      let a = [];
      let addrs = props.obj.refs.address.split(",");
      let amts = props.obj.refs.amounts.split(",");
      for (let index = 0; index < addrs.length; index++) {
        a.push({
          who: addrs[index].toString(),
          amount: nextblock(amts[index].toString(), props.obj.quoteDecimals),
        });
      }
      //console.log(a)
      return a;
    } catch (error) {
      // console.log(error)
      return [];
    }
  }

  /// console.log(data)
  function getQuery(query) {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const foo = params.get(query);
    return foo;
  }
  //console.log(parseFloat(ref(props.sale.convertAddress(account?.toString()))))
  return (
    <>
      {props.Affiliated > 0 ? (
        <>
          <div className="title-section-form">Affiliate program</div>

          <div className="form">
            <div
              className="group-aff"
              onClick={() => {
                navigator.clipboard.writeText(
                  getQuery("refFrom") == null
                    ? window.location.href + "?refFrom=" + account
                    : window.location.href.toString().split("?refFrom=")[0] +
                        "?refFrom=" +
                        account
                );
              }}
            >
              <p>
                {getQuery("refFrom") == null
                  ? window.location.href + "?refFrom=" + account
                  : window.location.href}
              </p>
              <svg
                viewBox="0 0 40 40"
                fill="#ececec"
                style={{
                  height: "60px",
                  width: "60px",
                  margin: "auto",
                  cursor: "pointer",
                }}
                xmlns="http://www.w3.org/2000/svg"
                className="sc-231a1e38-0 gUXTeE"
              >
                <path d="M15 1H4C2.9 1 2 1.9 2 3V16C2 16.55 2.45 17 3 17C3.55 17 4 16.55 4 16V4C4 3.45 4.45 3 5 3H15C15.55 3 16 2.55 16 2C16 1.45 15.55 1 15 1ZM19 5H8C6.9 5 6 5.9 6 7V21C6 22.1 6.9 23 8 23H19C20.1 23 21 22.1 21 21V7C21 5.9 20.1 5 19 5ZM18 21H9C8.45 21 8 20.55 8 20V8C8 7.45 8.45 7 9 7H18C18.55 7 19 7.45 19 8V20C19 20.55 18.55 21 18 21Z" />
              </svg>
            </div>

            {props.claimable && (
              <SAFE
                what={props.claimable ? "Claimable" : "Received"}
                result={
                  ref(props.sale.convertAddress(account?.toString())) +
                  quoteToken
                }
              />
            )}
          </div>

          {parseFloat(ref(props.sale.convertAddress(account?.toString()))) >
            0 &&
            props.status(props.obj) == "Claim Allowed" && (
              <div style={{ margin: "20px 0" }}>
                <Button
                  func={() => {
                    props
                      .nSendTX("afiliadeClaim", "0", 0)
                      .then((a) => {})
                      .catch((e) => {});
                  }}
                  text={"Claim"}
                  next={props.status(props.obj) == "Claim Allowed"}
                  className={"simple-button"}
                />
              </div>
            )}

          {afs().length > 0 && (
            <>
              <div className="title-section-form">Affiliate Ranking</div>
              <div className="form">
                <div className="group-affiliate">
                  {afs().length > 0 &&
                    afs()
                      .sort((a, b) => b.amount - a.amount)
                      .map(
                        (e, i) =>
                          parseFloat(e.amount) > 0 && (
                            <div className="item-affiliate">
                              <div className="position">{i + 1}</div>
                              <div className="value">
                                {e.who?.toString().slice(0, 4) +
                                  "..." +
                                  e.who?.toString().slice(-4)}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  onClick={() => {
                                    navigator.clipboard.writeText(e.who);
                                  }}
                                  width="14"
                                  height="14"
                                  style={{ margin: "auto 5px" }}
                                  fill="currentColor"
                                  class="bi bi-copy"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                  />
                                </svg>
                              </div>
                              <div className="amount">
                                {e.amount + quoteToken}
                              </div>
                            </div>
                          )
                      )}
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export default AffiliateHUB;
