import React, { useState, useEffect } from "react";

import Content from "../../../pages/content.js";
import Api from "../../../programs/api.js";

import { getAccount, prepareWriteContract, waitForTransaction, writeContract, getNetwork } from 'wagmi/actions'
import { useAccount } from 'wagmi';
import { ConnectButton } from '@rainbow-me/rainbowkit';

import { Connection, PublicKey } from "@solana/web3.js";
const EvmConnection = ({
    selected,
    rpcs,
    select,
    setPriority,
    setCustom,
    custom,
}) => {

    const { address, connector, isConnected

    } = useAccount();
    const connected = isConnected
    const signTransaction = () => { }
    const wallet = address


    const [seconds, setSeconds] = useState(0);
    const [price, setprice] = useState(0);
    const [priceSolApi, setPriceSolApi] = useState(0);
    const [priceTronApi, setPriceTronApi] = useState(0);

    useEffect(() => {
        loadprice();
        //getmarket()
    }, []);

    const loadprice = async () => {
        try {
            const tick = await Api.blockchain.status.get("/solana")
            const tickB = await Api.blockchain.status.get("/bsc")
            // console.log(parseFloat(tickB.price))
            setPriceSolApi(parseFloat(tick.price));
            setPriceTronApi(parseFloat(tickB.price))

            const connection = new Connection(Api.blockchain.rpc)
            const associatedPoolKeys = {
                base: new PublicKey("5shqQQeG1Y4mujvhXMrgjYZaLaFnFLLtfpFrCESEzrr6"),
                quote: new PublicKey("7XKmdTcALL4KVNM7jKpMZ8JkYr5RRdtk9RSv9cSSmrHm"),
            };
            const balanceA = await connection.getTokenAccountBalance(
                associatedPoolKeys.base
            );
            const balanceB = await connection.getTokenAccountBalance(
                associatedPoolKeys.quote
            );
            const priceSol =
                parseFloat(balanceB.value.uiAmount) /
                parseFloat(balanceA.value.uiAmount);


            const price = parseFloat(tick.price) * parseFloat(priceSol);


            setprice(price.toFixed(3));
        } catch (error) { }
    };


    const data = {
        price,
        publicKey: address,
        sendTransaction: () => { },
        signTransaction,
        provider: undefined,
        connection: undefined,
        connected,
        wallet,
        account: connected && address
    };

    window.solpadContext =
        window.solpadContext != undefined
            ? {
                ...window.solpadContext,
                ...data,
            }
            : data;
    useEffect(() => {
        const data = {
            price,
            publicKey: address,
            sendTransaction: () => { },
            signTransaction,
            provider: undefined,
            connection: undefined,
            connected,
            wallet,
            account: connected && address
        };

        window.solpadContext =
            window.solpadContext != undefined
                ? {
                    ...window.solpadContext,
                    ...data,
                }
                : data;
    }, [wallet]);

    useEffect(() => {
        if (connected) {
            const data = {
                price,
                publicKey: address,
                sendTransaction: () => { },
                signTransaction,
                provider: undefined,
                connection: undefined,
                connected,
                wallet,
                account: connected && address
            };

            window.solpadContext =
                window.solpadContext != undefined
                    ? {
                        ...window.solpadContext,
                        ...data,
                    }
                    : data;
        } else {
            let interval = null;
            interval = setInterval(() => {
                setSeconds((seconds) => seconds + 1);
            }, 1000);
            const data = {
                price,
                publicKey: address,
                sendTransaction: () => { },
                signTransaction,
                provider: undefined,
                connection: undefined,
                connected,
                wallet,
                account: connected && address
            };

            window.solpadContext =
                window.solpadContext != undefined
                    ? {
                        ...window.solpadContext,
                        ...data,
                    }
                    : data;

            return () => clearInterval(interval);
        }
    }, [seconds]);
    //   console.log(connected, wallet)
    const WButton = () => {
        return (
            <ConnectButton.Custom>
                {({
                    account,
                    chain,
                    openAccountModal,
                    openChainModal,
                    openConnectModal,
                    authenticationStatus,
                    mounted,
                }) => {
                    const ready = mounted && authenticationStatus !== 'loading';
                    const connected =
                        ready &&
                        account &&
                        chain &&
                        (!authenticationStatus ||
                            authenticationStatus === 'authenticated');

                    return (
                        <div
                            {...(!ready && {
                                'aria-hidden': true,
                                'style': {
                                    opacity: 0,
                                    pointerEvents: 'none',
                                    userSelect: 'none',
                                },
                            })}
                        >
                            {(() => {
                                if (!connected) {
                                    return (
                                        <div id="wallet" className="Wallet" onClick={openConnectModal}>

                                            <p>Select Wallet</p>
                                        </div>
                                    );
                                }

                                if (chain.unsupported) {
                                    return (
                                        <div id="wallet" className="Wallet" onClick={openChainModal}>
                                     
                                            <p>Wrong network</p>
                                        </div>
                                    );
                                }

                                return (
                                    <div id="wallet" className="Wallet" onClick={openAccountModal}>
                                        <div onClick={openChainModal} >
                                            <img
                                                alt={chain.name ?? 'Chain icon'}
                                                src={chain.iconUrl}
                                                style={{ borderRadius: "5px ", width: "12px", height: "12px", margin: "auto" }}></img>
                                        </div>
                                        <p>
                                            {account.displayName}<p />
                                            {/* {account.displayBalance
                              ? ` (${account.displayBalance})`
                              : ''} */}
                                        </p>
                                    </div>
                                );
                            })()}
                        </div>
                    );
                }}
            </ConnectButton.Custom>
        );
    };
    return (
        <>
            <Content
                WalletButton={<WButton />}
                WalletButtonOut={<WButton />}
                selected={selected}
                rpcs={rpcs}
                select={select}
                price={price}
                setPriority={setPriority}
                setCustom={setCustom}
                priceSol={{
                    solana: priceSolApi,
                    tron: priceTronApi,
                    bsc: priceTronApi,
                }}
            />
        </>
    );
};
export default EvmConnection