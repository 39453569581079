import React from "react";

const Tll = ({ element }) => {
    return (
        <>
            {element}
        </>

    );
}
export default Tll;