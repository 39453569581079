import React from "react";
import { endedccheck, programNetwork, removeSpacesAndUnderscores } from "../../../../programs/utils";

const DetailsModal = ({

    object,
    address,
    navigate,
    assets,
    Iconwebsite,
    Iconfacebook,
    Icondiscord,
    Icongithub,
    Icontelegram,
    Iconinstagram,
    Iconreddit,
    Icontwitter
}) => {
    function setStylesStatus(status) {
        let st = "";
        switch (status) {
            case "Sale live":
                st = "salelive";
                break;
            case "Canceled":
                st = "canceled";
                break;
            case "coming":
                st = "coming";
                break;
            case "Ended":
                st = "ended";
                break;
            case "Finalized":
                st = "finalized";
                break;
            case "Claim Allowed":
                st = "claimAllowed";
                break;
            default:
                break;
        }

        return st;
    }

    function getyoutubeVideo(e) {
        if (e == undefined) {
            return "";
        }
        if (e.toString().includes("youtu.be")) {
            return e.toString().split("/")[e.toString().split("/").length - 1];
        }
        if (e.toString().includes("watch?v")) {
            return e.toString().split("&")[0].toString().split("watch?v=")[
                e.toString().split("&")[0].toString().split("watch?v=").length - 1
            ];
        }
        return "img:" + e;
    }
    const banner = <div className="header-token-banner">
        {getyoutubeVideo(object?.youtubeVideo) != "" && (
            <div className="img-banner">
                {getyoutubeVideo(object?.youtubeVideo)
                    .toString()
                    .startsWith("img:") ? (
                    <img
                        style={{ borderRadius: "4px" }}
                        src={
                            getyoutubeVideo(object?.youtubeVideo)
                                .toString()
                                .split("img:")[1]
                        }
                        width="100%"
                        alt=""
                    />
                ) : (
                    <iframe
                        style={{ borderRadius: "4px" }}
                        width="100%"
                        height="315"
                        src={
                            "https://www.youtube.com/embed/" +
                            getyoutubeVideo(object?.youtubeVideo)
                        }
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    ></iframe>
                )}
            </div>
        )}

        <div className="header-logo-token">
            <img src={object["logoURL"]} loading="lazy" alt="" />

            <div className="header-token-logo">
                <img src={`${programNetwork(object.program).icon}`} alt="" />
            </div>
        </div>
    </div>
    const status = <div className="group-status">
        <div
            className={"group-status__status " + setStylesStatus(endedccheck(object))}
        >
            {endedccheck(object)}
        </div>

        {parseInt(object.affiliated) > 0 && (
            <div className="group-status__affiliate">
                Affiliate {object.affiliated.toString()} %
            </div>
        )}
    </div>
    const name = <div className="group-name-icons">
        <div className="group-name-launch">
            {object.tokenname}
            {object.owner == address && (
                <div
                    onClick={() => {
                        const path = window.location.pathname.toString().split("/")
                        navigate(
                            "/"+path[1] + "/edit/" +
                            path[2]
                            + "/" + object.cts

                        );
                    }}
                    className="edit-icon"
                    title={"Edit " + object.tokenname + " information"}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                            height: "15px",
                            width: "15px",
                            margin: "auto",
                        }}
                        fill="currentColor"
                        class="bi bi-pencil-fill"
                        viewBox="0 0 16 16"
                    >
                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                    </svg>
                </div>
            )}
        </div>
    </div>
    const socials = <div className="group-social">
        {(object["website"] == undefined ? "" : object["website"]).toString()
            .length > 0 && (
                <a
                    onClick={() => window.open(object["website"])}
                    rel="nofollow noreferrer"
                    target="_blank"
                >
                    {Iconwebsite}
                </a>
            )}

        {(object["facebook"] == undefined
            ? ""
            : object["facebook"]
        ).toString().length > 0 && (
                <a
                    onClick={() => window.open(object["facebook"])}
                    rel="nofollow noreferrer"
                    target="_blank"
                >
                    {Iconfacebook}
                </a>
            )}

        {(object["twitter"] == undefined ? "" : object["twitter"]).toString()
            .length > 0 && (
                <a
                    onClick={() => window.open(object["twitter"])}
                    rel="nofollow noreferrer"
                    target="_blank"
                >
                    {Icontwitter}
                </a>
            )}

        {(object["github"] == undefined ? "" : object["github"]).toString()
            .length > 0 && (
                <a
                    onClick={() => window.open(object["github"])}
                    rel="nofollow noreferrer"
                    target="_blank"
                >
                    {Icongithub}
                </a>
            )}

        {(object["telegram"] == undefined
            ? ""
            : object["telegram"]
        ).toString().length > 0 && (
                <a
                    onClick={() => window.open(object["telegram"])}
                    rel="nofollow noreferrer"
                    target="_blank"
                >
                    {Icontelegram}
                </a>
            )}

        {(object["instagram"] == undefined
            ? ""
            : object["instagram"]
        ).toString().length > 0 && (
                <a
                    onClick={() => window.open(object["instagram"])}
                    rel="nofollow noreferrer"
                    target="_blank"
                >
                    {Iconinstagram}
                </a>
            )}

        {(object["discord"] == undefined ? "" : object["discord"]).toString()
            .length > 0 && (
                <a
                    onClick={() => window.open(object["discord"])}
                    rel="nofollow noreferrer"
                    target="_blank"
                >
                    {Icondiscord}
                </a>
            )}

        {(object["reddit"] == undefined ? "" : object["reddit"]).toString()
            .length > 0 && (
                <a
                    onClick={() => window.open(object["reddit"])}
                    rel="nofollow noreferrer"
                    target="_blank"
                >
                    {Iconreddit}
                </a>
            )}
    </div>
    const badges = object.badges != undefined && (
        <div className="group-tags">
            {object.badges[0] != "" && (
                <div
                    onClick={() => {
                        window.open(object.badges[0]);
                    }}
                    className="tags audit"
                >
                    AUDIT
                </div>
            )}
            {object.badges[1] != "" && (
                <div
                    onClick={() => {
                        window.open(object.badges[1]);
                    }}
                    className="tags kyc"
                >
                    KYC
                </div>
            )}
            {object.badges[2] != "" && (
                <div
                    onClick={() => {
                        window.open(object.badges[2]);
                    }}
                    className="tags doxx"
                >
                    DOXXED
                </div>
            )}
            {object.badges[3] != "" && (
                <div
                    onClick={() => {
                        window.open(object.badges[3]);
                    }}
                    className="tags alpha"
                >
                    ALPHA
                </div>
            )}
            {object.badges[4] != "" && (
                <div
                    onClick={() => {
                        window.open(object.badges[4]);
                    }}
                    className="tags doxx"
                >
                    VETTED
                </div>
            )}
            {object.badges[5] != "" && (
                <div
                    onClick={() => {
                        window.open(object.badges[5]);
                    }}
                    className="tags safu"
                >
                    SAFU
                </div>
            )}
        </div>
    )
    return (
        <>
            <div className="group-header-token">
                {banner}
                {status}
            </div>

            <div className="group-information">
                {name}
            </div>

            <div className="group-tag-social">
                {socials}
                {badges}
            </div>

        </>
    );
}
export default DetailsModal;