import React from "react";
import InputBlock from "../../../../components/Launchpad/InputBlock";
import Button from "../../../../components/Launchpad/Button";

const WhitelistModal = ({
    isOnWhitelist,
    whitelistviewlenght,
    whitelistview,
    whiteindex,
    setwhiteindex
}) => {

    return (
        <>
            <div className="form">
                <div className="row">
                    <div className="col col-12">
                        <InputBlock
                            title={"Search on whitelist"}
                            place={"Address"}
                            id={"search-whitelist"}
                            // variavel={percentHC}
                            setvariavel={(e) => {
                                isOnWhitelist(e);
                            }}
                            desc={""}
                        />
                    </div>
                </div>
            </div>
            <div className="text-whitelist">
                This Sale Contains{" "}
                {whitelistviewlenght != undefined && whitelistviewlenght != null
                    ? whitelistviewlenght
                    : 0}{" "}
                wallets in whitelist
            </div>

            {whitelistview.length > 0 && <hr style={{ margin: "15px 0" }} />}
            {whitelistview.length > 0 &&
                whitelistview.map((e, i) => (
                    <div className="item-whitelist">{e.address}  { (e.tier > 0 ? "// Tier " + e.tier : "No tier")}</div>
                ))}
            {whitelistview.length > 0 && (
                <div className="paginator">
                    <Button
                        func={() => {
                            if (whiteindex >= 1) {
                                setwhiteindex(whiteindex - 1);
                            }
                        }}
                        text={"<"}
                        className="btn-previous"
                        disabled={whitelistview === 0}
                    />
                    <div className="index-paginator">{whiteindex}</div>
                    <Button
                        func={() => {
                            setwhiteindex(whiteindex + 1);
                        }}
                        text={">"}
                        className="btn-next"
                        disabled={whitelistview.length === 0}
                    />
                </div>
            )}
        </>

    );
}
export default WhitelistModal;