import React, { useEffect, useState } from "react";
import CreateDropDown from "./components/createDropDown";
import Button from "../Launchpad/Button";


// export function Alert() {
// 	const logoUrl = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJIAAAAtCAYAAABF5zIuAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQzSURBVHgB7ZqLcdswDEDhXgfQBlUmaDpB1Q2SCaIN4k5gZ4K4E0iZIOkEViawN5A2sDsBSljgiVXEnyW7dzbeHc+x8CFFQiAFB0AQBEEQBEEQBEEQBEEQJgIRF6rtVNuodgeRKJtEtTW2PINwfaiFn+NHHiLsEw5AkwyE60Iteo3DeIPJEkTEGoTrQS14jm7mHvtXh20GwnWA3bnGxcJiW3jsChAuH7XQKYaz6NkWgXYJCJdNRDD8E0yRdksQLoLZ0EXOFLVqsRljq9pthP5etZvZbLaHSHiMj6pROSKFdqx7HsOL8lkG+CA7emnI2QeE+uD+X0lf6d1D3LhfPWqNak/Kb+PxRQ9vxl/vQ+YR3S86dN+/fP0Gg/5D9pQsIRJst92d4YP+bnp+C4+PhwAf9LKQOMZA1BABhh8ZamwDPdTPMrD/EBYwBWh/5T8FtIBRmQ+7l4ASe5ON7UOgAyS32JuLsLL4qFn+7PFxbCDV/He/3WJXMlk5/BSss4mZR+O++/1mPBd67pYwBnZ4buaRY9TcWuQUCBRkmUVesH3h6CN19YMTBJJD5y5Ap9Zjwy6YcvD3f8Ah1+sf/YD3HRV4fmIXo2a7AtsbTyPtNalH74315gOyUwaSXsyNRZ6zvOLv+tcHb6FX37hHZ81qOQTyaeBazGF5Kg6pNUL/iT9z1Wjyar04PAlzW5AY1/cBh8qKP7/CCeCA6bdciXSmfLGY6jNMaXzSQTvDaQq97/yZwrFgWBHyFCwjx0nBRwFTqbYd8Eep+c5iR3gzCXZPfjHGj8XOB21XyYB9NtQvtucbYu3p/4BHZxm7Jp8HrtFTkMH52cYoczZZcTvAE08ZVZcFKADeeqb6FZkWNPG8Muvs/Aemh/r9bZFVjtKD/o0z6QV4yp9ZwH35+ALdGI/HiMhzsYkcH03W3CFPtGOLvGZxDu5+tN6ozDaFXc/Wx9Lhwzovhk7NahmMBc8XTIMp3DGuxLB9sOjot56d595o+0stOs+sU1vk/yOQCral+tbg+cq4L1v964BFlhh9RI1v5hJiG9nTFKeGoe3sR2waxra+8shfK+gOh8R36LZmqtLOLT7Wht4b+6Bx0ME6h66qT+OrBuxTaKv/e/i4fWre+1uUYdco2Q1EwIub2sbEOjse+0+lsxqQ6yAqe6IU2q1c/0LwbbIKN3dc4mmIykQD43JlzB0GHBSxO6AOUaGlTsW2IdvMlIf0PMTOmJe1Re6D7juFSGYhSspxCd0hbwqOykR9sDtcp8blhvyH+mYfGXQH64btt4F9u2j6T7Vht/f10bPT2aJxZQpzXJZMmllM9+x73AHbB06XmUZlIuECmCCYJIiEFjy+YClBJHSg/R/6XVQSRMIHIoOpBEGwERhMJQiCD08wlSAIoWBbYKsliITRcDBtJYgEQRAEQRAEQRAEQRAEQXDxF8y0hkLmWRjPAAAAAElFTkSuQmCC"
// 	const styleDialog = {
// 		height: "min-content",
// 		width: "280px",
// 		borderRadius: "10px",
// 		background: "#fff",
// 		border: "none",
// 		position: "absolute",
// 		margin: "auto",
// 		padding: "0px 0px 20px 0px",

// 		boxShadow: `5px 3px 3px #00000020`,
// 	}
// 	const Info = ({ }) => {
// 		return (
// 			<form
// 				method="dialog"
// 				className="modal-box groupCreated"
// 				style={{ display: "grid", gap: "10px" }}
// 			>
// 				<div style={{
// 					display: "grid",
// 					padding: "10px 15px", gridTemplateColumns: "auto 50px"
// 				}}>
// 					<p
// 						style={{ fontWeight: "bold", fontSize: "16px", color: "#3d44b6", margin: "auto 0px" }}
// 					>

// 					</p>
// 					<button
// 						style={{ fontWeight: "bold", fontSize: "12px", color: "#3d44b6", width: "50px", minWidth: "50px", border: "none" }}
// 					>
// 						✕
// 					</button>
// 				</div>


// 				<div style={{ display: "grid", padding: "10px 15px", gridTemplateColumns: "auto 50px" }}>
// 					<p
// 						style={{ fontWeight: "bold", fontSize: "12px", color: "#3d44b6" }}
// 					>

// 					</p>
// 				</div>

// 			</form>
// 		);
// 	}
// 	return (
// 		<div>
// 			<dialog id="success_modal_alert" style={styleDialog}>
// 				<Info />
// 			</dialog>
// 			<dialog id="error_modal_alert" style={styleDialog}>
// 				<Info />
// 			</dialog>
// 			<dialog id="loading_modal_alert" style={styleDialog}>
// 				<Info />
// 			</dialog>
// 		</div>
// 	);
// }
// export function Emit(Title, Desc, Type) {
// 	try {
// 		let _AlertData = {}
// 		_AlertData.Title = Title
// 		_AlertData.Desc = Desc
// 		_AlertData.Type = Type


// 		window.success_modal_alert.close()
// 		window.error_modal_alert.close()
// 		window.loading_modal_alert.close()
// 		if (_AlertData.Type == 'success') {
// 			window.success_modal_alert.showModal()
// 			window.error_modal_alert.close()
// 			window.loading_modal_alert.close()

// 			document.getElementById("success_modal_alert").getElementsByTagName("p")[0].innerHTML = _AlertData.Title
// 			document.getElementById("success_modal_alert").getElementsByTagName("p")[1].innerHTML = _AlertData.Desc
// 		} else {
// 			if (_AlertData.Type == 'error') {
// 				window.success_modal_alert.close()
// 				window.error_modal_alert.showModal()
// 				window.loading_modal_alert.close()

// 				document.getElementById("error_modal_alert").getElementsByTagName("p")[0].innerHTML = _AlertData.Title
// 				document.getElementById("error_modal_alert").getElementsByTagName("p")[1].innerHTML = _AlertData.Desc
// 			} else {
// 				window.success_modal_alert.close()
// 				window.error_modal_alert.close()
// 				window.loading_modal_alert.showModal()

// 				document.getElementById("loading_modal_alert").getElementsByTagName("p")[0].innerHTML = _AlertData.Title
// 				document.getElementById("loading_modal_alert").getElementsByTagName("p")[1].innerHTML = _AlertData.Desc
// 			}
// 		}

// 	} catch (error) {

// 	}



// }
class Emission {
	constructor(index) {
		this.index = index
	}
	update(
		Title,
		Desc,
		type
	) {
		window.alert.update(
			this.index,
			Title,
			Desc,
			type
		)
	}
}
export function Alert() {
	const logoUrl = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJIAAAAtCAYAAABF5zIuAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQzSURBVHgB7ZqLcdswDEDhXgfQBlUmaDpB1Q2SCaIN4k5gZ4K4E0iZIOkEViawN5A2sDsBSljgiVXEnyW7dzbeHc+x8CFFQiAFB0AQBEEQBEEQBEEQBEEQJgIRF6rtVNuodgeRKJtEtTW2PINwfaiFn+NHHiLsEw5AkwyE60Iteo3DeIPJEkTEGoTrQS14jm7mHvtXh20GwnWA3bnGxcJiW3jsChAuH7XQKYaz6NkWgXYJCJdNRDD8E0yRdksQLoLZ0EXOFLVqsRljq9pthP5etZvZbLaHSHiMj6pROSKFdqx7HsOL8lkG+CA7emnI2QeE+uD+X0lf6d1D3LhfPWqNak/Kb+PxRQ9vxl/vQ+YR3S86dN+/fP0Gg/5D9pQsIRJst92d4YP+bnp+C4+PhwAf9LKQOMZA1BABhh8ZamwDPdTPMrD/EBYwBWh/5T8FtIBRmQ+7l4ASe5ON7UOgAyS32JuLsLL4qFn+7PFxbCDV/He/3WJXMlk5/BSss4mZR+O++/1mPBd67pYwBnZ4buaRY9TcWuQUCBRkmUVesH3h6CN19YMTBJJD5y5Ap9Zjwy6YcvD3f8Ah1+sf/YD3HRV4fmIXo2a7AtsbTyPtNalH74315gOyUwaSXsyNRZ6zvOLv+tcHb6FX37hHZ81qOQTyaeBazGF5Kg6pNUL/iT9z1Wjyar04PAlzW5AY1/cBh8qKP7/CCeCA6bdciXSmfLGY6jNMaXzSQTvDaQq97/yZwrFgWBHyFCwjx0nBRwFTqbYd8Eep+c5iR3gzCXZPfjHGj8XOB21XyYB9NtQvtucbYu3p/4BHZxm7Jp8HrtFTkMH52cYoczZZcTvAE08ZVZcFKADeeqb6FZkWNPG8Muvs/Aemh/r9bZFVjtKD/o0z6QV4yp9ZwH35+ALdGI/HiMhzsYkcH03W3CFPtGOLvGZxDu5+tN6ozDaFXc/Wx9Lhwzovhk7NahmMBc8XTIMp3DGuxLB9sOjot56d595o+0stOs+sU1vk/yOQCral+tbg+cq4L1v964BFlhh9RI1v5hJiG9nTFKeGoe3sR2waxra+8shfK+gOh8R36LZmqtLOLT7Wht4b+6Bx0ME6h66qT+OrBuxTaKv/e/i4fWre+1uUYdco2Q1EwIub2sbEOjse+0+lsxqQ6yAqe6IU2q1c/0LwbbIKN3dc4mmIykQD43JlzB0GHBSxO6AOUaGlTsW2IdvMlIf0PMTOmJe1Re6D7juFSGYhSspxCd0hbwqOykR9sDtcp8blhvyH+mYfGXQH64btt4F9u2j6T7Vht/f10bPT2aJxZQpzXJZMmllM9+x73AHbB06XmUZlIuECmCCYJIiEFjy+YClBJHSg/R/6XVQSRMIHIoOpBEGwERhMJQiCD08wlSAIoWBbYKsliITRcDBtJYgEQRAEQRAEQRAEQRAEQXDxF8y0hkLmWRjPAAAAAElFTkSuQmCC"
	const styleDialog = {
		height: "min-content",
		width: "280px",
		borderRadius: "10px",
		background: "transparent",
		border: "none",
		position: "fixed",
		//margin: "auto",
		padding: "0px 0px 20px 0px",
		right: "10px",
		bottom: "10px",
		display:"none"
		///boxShadow: `5px 3px 3px #00000020`,
	}
	const [seconds, setSeconds] = useState(0);
	const [selected, setSelected] = useState(0);
	useEffect(() => {

	}, [seconds]);

	const [pendings, setpendings] = useState([])

	window.alert = {

		emit: (
			Title,
			Desc,
			type,
			promise = new Promise(() => {

			}),
			callbacksuccess = () => { },
			callbackerror = () => { }
		) => {

			// if (window.modal_alert != undefined) {
			// 	if (window.modal_alert.open) {

			// 	} else {
			// 		window.modal_alert.showModal()
			// 	}
			// }
			document.getElementById("modal_alert").style.display = "grid"

			let temp = pendings
			temp.push(
				{
					Title,
					Desc,
					Type: type,
				}
			)
			setpendings(temp)
			setSeconds((seconds) => seconds + 1);
			const index = temp.length - 1
			return new Emission(index)
		},
		update: (
			index,
			Title,
			Desc,
			Type
		) => {
			try {
				console.log(index,
					Title,
					Desc,
					Type)
				let temp = pendings
				temp[index] = {
					Title,
					Desc,
					Type
				}
				setpendings(temp)
				setSeconds((seconds) => seconds + 1);
			} catch (error) {

			}

		}
	}

	return (
		<div>
			<div id="modal_alert" style={styleDialog}>
				{/* //<Info /> */}
				{
					pendings.map((e, i) =>

						<div
							id={"modal_alert_tx_" + i}
							style={{
								display: "grid",
								border: "1px solid #ECECEC", borderRadius: "10px",
								background: "#fff"
							}}>
							<CreateDropDown
								pos={i}
								selected={selected}
								setSelected={setSelected}
								title={e.Title}
								status={e.Type}

							/>

							{/* <button
								onClick={() => {
									document.getElementById("modal_alert_tx_" + i).style.display = "none"

								}}
								style={{ fontWeight: "bold", fontSize: "12px", color: "#3d44b6", width: "50px", minWidth: "50px", border: "none" }}
							>
								✕
							</button> */}
							<p
								style={{ fontSize: "10px", color: "#3d44b6", margin: "auto" }}
							>
								{selected == i ? e.Desc : ""}
							</p>

						</div>
					)
				}
				<Button
					func={() => {
						document.getElementById("modal_alert").style.display = "none"
						setpendings([])
					}}
					text={"close"}
					className="simple-button"
				/>
			</div>
		</div>
	);
}

export function Emit(
	Title,
	Desc,
	type,
	promise = new Promise(() => {

	}),
	callbacksuccess = () => { },
	callbackerror = () => { }
) {
	//Alert.name("asd")
	return window.alert.emit(Title, Desc, type)
}

export default {
	Alert,
	Emit
};