import React, { useContext, useEffect, useState } from "react";
import InputBlock from "../../components/Launchpad/InputBlock.js";
import Button from "../../components/Launchpad/Button.js";
import DescBlock from "../../components/Launchpad/descBlock.js";
import Checkbox from "../../components/Launchpad/Checkbox.js";
import AppContext from "../../framework/helpers/AppContext.js";

const EditTokenComponent = (props) => {
  const {
    setnet,
    netid
  } = useContext(AppContext)
  useEffect(() => {
    if (netid == "tron") {
      setnet("solana")
      window.location.reload()
    }
  }, [])
  const [step, setstep] = useState(0);

  //---step 0
  const [mint, setMint] = useState(undefined);
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [fee, setFee] = useState(0);

  //---step 1
  const [logoURL, setlogoURL] = useState("");
  const [Website, setWebsite] = useState("");
  const [Facebook, setFacebook] = useState("");
  const [Twitter, setTwitter] = useState("");
  const [Github, setGithub] = useState("");
  const [Telegram, setTelegram] = useState("");
  const [Instagram, setInstagram] = useState("");
  const [Discord, setDiscord] = useState("");
  const [Reddit, setReddit] = useState("");
  const [Youtube, setYoutube] = useState("");
  const [Description, setDescription] = useState("");
  const [metadataMutable, setMetadataMutable] = useState(0);

  function stepCurrent() {
    return step;
  }

  async function editSpl(feeBasisPoints, maxFee) {
    const { SplBuilder } = require("../../programs/programs.js");
    const builder = new SplBuilder();

    builder.asyncedit(
      mint,
      {
        name: name,
        symbol: symbol,
        description: Description,
        image: logoURL, //add public URL to image you'd like to use
        website: Website, //add public URL to image you'd like to use
        twitter: Twitter,
        telegram: Telegram,
        discord: Discord,
        feeBasisPoints: fee,
      },
      metadataMutable
    );
  }
  return (
    <>
      <div className="text-title">Solpad Metadata Tool</div>
      <div className="text-sub-title">
        Edit your token Metadata on the Solana chain
      </div>
      {stepCurrent() === 0 && (
        /*-step-1------------------------------------------------------------------------------*/
        <>
          <div className="form">
            <div className="row">
              <div className="col col-12">
                <InputBlock
                  title={"Token Address"}
                  place={"Type the token address"}
                  variavel={mint}
                  setvariavel={(ct) => {
                    try {
                      if (ct?.toString().trim().length < 0) {
                      } else {
                        setMint(ct?.toString().trim());
                      }
                    } catch (error) {
                      //console.log(error)
                    }
                  }}
                  desc={""}
                />
              </div>
            </div>
            <div className="row">
              <div className="col col-6">
                <InputBlock
                  title={"Name"}
                  place={"Name"}
                  variavel={name}
                  setvariavel={setName}
                  desc={""}
                />
              </div>
              <div className="col col-6">
                <InputBlock
                  title={"Symbol"}
                  place={"Symbol"}
                  variavel={symbol}
                  setvariavel={setSymbol}
                  desc={""}
                />
              </div>
            </div>
            <hr style={{ margin: "15px 0" }} />
            <div className="title-input" style={{ marginLeft: "10px" }}>
              Metadata Mutable
            </div>
            <div className="row">
              <div className="col col-2">
                <Checkbox
                  title={"Disable"}
                  id={0}
                  variavel={metadataMutable}
                  setvariavel={setMetadataMutable}
                />
              </div>
              <div className="col col-2">
                <Checkbox
                  title={"Enable"}
                  id={1}
                  variavel={metadataMutable}
                  setvariavel={setMetadataMutable}
                />
              </div>
            </div>
            <hr style={{ margin: "15px 0" }} />
            <div className="row">
              <div className="col col-6">
                <InputBlock
                  title={"Image Upload Url *"}
                  place={"https://..."}
                  variavel={logoURL}
                  setvariavel={setlogoURL}
                  desc={""}
                />
              </div>
              <div className="col col-6">
                <InputBlock
                  title={"Website"}
                  place={"https://..."}
                  variavel={Website}
                  setvariavel={setWebsite}
                  desc={""}
                />
              </div>
            </div>
            <div className="row">
              <div className="col col-4">
                <InputBlock
                  title={"Twitter"}
                  place={"https://..."}
                  variavel={Twitter}
                  setvariavel={setTwitter}
                  desc={""}
                />
              </div>
              <div className="col col-4">
                <InputBlock
                  title={"Telegram *"}
                  place={"https://..."}
                  variavel={Telegram}
                  setvariavel={setTelegram}
                  desc={""}
                />
              </div>
              <div className="col col-4">
                <InputBlock
                  title={"Discord"}
                  place={"https://..."}
                  variavel={Discord}
                  setvariavel={setDiscord}
                  desc={""}
                />
              </div>
            </div>
            <div className="row">
              <div className="col col-12">
                <DescBlock
                  title={"Description"}
                  place={""}
                  variavel={Description}
                  setvariavel={setDescription}
                  desc={""}
                />
              </div>
            </div>
          </div>
          <div className="group-buttons">
            <Button
              func={() => {
                editSpl(0, 0);
              }}
              text={"Update"}
              next={true}
              className="next"
            />
          </div>
        </>
      )}
    </>
  );
};

export default EditTokenComponent;
