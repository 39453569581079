import React, { useState, useEffect, useContext } from "react";
import InputBlock from "../../../components/Launchpad/InputBlock";
import DescBlock from "../../../components/Launchpad/descBlock";
import Button from "../../../components/Launchpad/Button";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import env from "../../../hooks/data/Config";
import { useNavigate } from "react-router-dom";
import { Keypair } from "@solana/web3.js";
import LoadingComponent from "../../../components/loading/loading";
import Api from "../../../programs/api";
import { loadSaleMetaData } from "../../../programs/utils";
import { Contract, Factory, TronContract } from "../../../programs/programs";
import SaleAbi from "../../../abis/PreSale/SalePreSale.json"
import { Emit } from "../../../components/Alert/Alert";
const SalesEdit = ({ type, contract }) => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(true);
  const [logoURL, setlogoURL] = useState("");
  const [Website, setWebsite] = useState("");
  const [Facebook, setFacebook] = useState("");
  const [Twitter, setTwitter] = useState("");
  const [Github, setGithub] = useState("");
  const [Telegram, setTelegram] = useState("");
  const [Instagram, setInstagram] = useState("");
  const [Discord, setDiscord] = useState("");
  const [Reddit, setReddit] = useState("");
  const [Youtube, setYoutube] = useState("");
  const [Description, setDescription] = useState("");
  const [v3, setv3] = useState(undefined);
  //step4
  const {

    account
  } = window.solpadContext;
  const contractSALE = window.location.pathname.toString().split("/")[
    window.location.pathname.toString().split("/").length - 1
  ];

  const chain = window.location.pathname.toString().split("/")[1]
  const address = account;
  const [program, setprogram] = useState(
    chain == "solana"
      ? new Contract(contractSALE, contract)
      : chain == "tron"
        ? new TronContract(contractSALE, contractSALE, env.tron.preSaleAbi)
        : new Contract(contractSALE, contract)


  );
  useEffect(() => {
    const { account } = window.solpadContext;
    program.fetchData(
      {
        addr:
          account && account != undefined
            ? account
            : "11111111111111111111111111111111",
        priv: contractSALE,
      },
      (meta) => {
        console.log(meta)
        setlogoURL(meta.logoURL);
        setWebsite(meta.website);
        setFacebook(meta.facebook);
        setTwitter(meta.twitter);
        setGithub(meta.github);
        setTelegram(meta.telegram);
        setDiscord(meta.discord);
        setInstagram(meta.instagram);
        setReddit(meta.reddit);
        setYoutube(meta.youtubeVideo);
        setDescription(meta.description);
        setloading(false)
      },
      (e) => { }
    );
  }, []);

  async function Save() {
    try {
      switch (chain) {
        case "solana":
        //  const program = new Contract(contractSALE, contract);
          const metadataPDA = await program.methods.meta.view({})//.call("meta", {});
          const metadata = new Factory(env.factory, metadataPDA);

          const f = await metadata.updateMetadata(
            {
              metadatapda: metadataPDA,
              pda: Keypair.fromSecretKey(new Uint8Array(parse(JSON.parse(v3)))),
            },
            {
              LogoUrl: logoURL,
              Website,
              Facebook,
              Twitter,
              Github,
              Telegram,
              Discord,
              Instagram,
              Reddit,
              YoutubeVideo: Youtube,
              Description,
            }
          );
          const result = await Api.sale.metadata.post(f)
          navigate("/" + chain + "/presale/" + contractSALE)
          break;
        case "tron":
          await program.methods.editPAD.send(0,[
            logoURL,
            Website,
            Facebook,
            Twitter,
            Github,
            Telegram,
            Discord,
            Instagram,
            Reddit,
            Youtube,
            Description,
          ])
          navigate("/" + chain + "/presale/" + contractSALE)
          break;
        default:
          break;
      }


    } catch (error) {
      try {
        Emit("Error on Edit", error.message, "error")
      } catch (e) {
        Emit("Error on Edit", error, "error")
      }
//console.log(error)
    }
  }

  function parse(data) {
    const calls = [];

    const keys = Object.keys(data);

    for (let index = 0; index < keys.length; index++) {
      calls.push(data[keys[index]]);
    }
    return calls;
  }

  async function getprofile(ct) {

  }

  function isEmptyObject(obj) {
    if (!obj || !obj.tokenname) return true;
    return false;
  }

  return (
    <>
      <div
        className="btn-back"
        onClick={() => navigate("/" + window.location.pathname.toString().split("/")[1] + "/presale/" + contractSALE)}
      >
        <ArrowBackOutlinedIcon /> Back
      </div>
      <hr style={{ margin: "20px 0" }} />
      {loading && <LoadingComponent />}
      {!loading && (
        <>
          <div className="text-title">Edit Presale</div>
          <div className="text-sub-title">Edit your presale information</div>
          <div className="form">
            <div className="row">
              <div className="col col-6">
                <InputBlock
                  title={"Image Upload Url *"}
                  place={"https://..."}
                  variavel={logoURL}
                  setvariavel={setlogoURL}
                  desc={""}
                />
              </div>
              <div className="col col-6">
                <InputBlock
                  title={"Website *"}
                  place={"https://..."}
                  variavel={Website}
                  setvariavel={setWebsite}
                  desc={""}
                />
              </div>
              <div className="col col-6">
                <InputBlock
                  title={"Facebook"}
                  place={"https://..."}
                  variavel={Facebook}
                  setvariavel={setFacebook}
                  desc={""}
                />
              </div>
              <div className="col col-6">
                <InputBlock
                  title={"Twitter"}
                  place={"https://..."}
                  variavel={Twitter}
                  setvariavel={setTwitter}
                  desc={""}
                />
              </div>
              <div className="col col-6">
                <InputBlock
                  title={"Github"}
                  place={"https://..."}
                  variavel={Github}
                  setvariavel={setGithub}
                  desc={""}
                />
              </div>
              <div className="col col-6">
                <InputBlock
                  title={"Telegram"}
                  place={"https://..."}
                  variavel={Telegram}
                  setvariavel={setTelegram}
                  desc={""}
                />
              </div>
              <div className="col col-6">
                <InputBlock
                  title={"Instagram"}
                  place={"https://..."}
                  variavel={Instagram}
                  setvariavel={setInstagram}
                  desc={""}
                />
              </div>
              <div className="col col-6">
                <InputBlock
                  title={"Discord"}
                  place={"https://..."}
                  variavel={Discord}
                  setvariavel={setDiscord}
                  desc={""}
                />
              </div>
              <div className="col col-6">
                <InputBlock
                  title={"Reddit"}
                  place={"https://..."}
                  variavel={Reddit}
                  setvariavel={setReddit}
                  desc={""}
                />
              </div>
              <div className="col col-6">
                <InputBlock
                  title={"Youtube Video or Banner URL"}
                  place={"https://..."}
                  variavel={Youtube}
                  setvariavel={setYoutube}
                  desc={""}
                />
              </div>
              <div className="col col-12">
                <DescBlock
                  title={"Description"}
                  place={""}
                  variavel={Description}
                  setvariavel={setDescription}
                  desc={""}
                />
              </div>{" "}
              <div className="col col-12">
                <InputBlock
                  title={"Do you have authenticate key? Past here"}
                  place={""}
                  variavel={v3}
                  setvariavel={setv3}
                  desc={""}
                />
              </div>
            </div>
          </div>
          <div className="group-buttons">
            <Button
              func={() => {
                Save();
              }}
              text={"Save"}
              next={true}
              className="next"
            />
          </div>
        </>
      )}
    </>
  );
};
export default SalesEdit;
