import React, { useState, useEffect } from "react";
import SAFE from "../../../components/Launchpad/textpar";
import Buttons from "../../../components/Launchpad/Button";
import { ethers } from "ethers";
import { useNavigate } from "react-router-dom";
import { nextblock } from "../../../programs/utils";
const DetailTokenLock = (props) => {
  const navigate = useNavigate();
  const [symbola, setsymbolA] = useState("");
  const [_ct, setCt] = useState("");
  const [deca, setdecA] = useState(9);
  const [owner, setowner] = useState("");
  const [time, settime] = useState(0);
  const [amount, setamount] = useState(0);
  const [data, setdata] = useState({
    address: null,
    mintAuthority: null,
    supply: "0",
    decimals: "0",
    isInitialized: false,
    freezeAuthority: null,
    tlvData: "",
    name: "",
    symbol: "",
    icon: "",
    amount: "0",
    endTime: "0",
    _type: "0",
    id: "0",
    owner: null,
    spltoken: null,
  });
  useEffect(() => {
    aa();
  }, []);

  const [seconds, setSeconds] = useState(0);

  /* useEffect(() => {
     let interval = null;
     interval = setInterval(() => {
       setSeconds((seconds) => seconds + 1);
       aa();
     }, 10000);
 
     return () => clearInterval(interval);
   }, [seconds]);
 */
  const aa = async () => {
    try {
      const a = await props.getlock(props.posi);
      const aba = await props.getToken(a.spltoken, props.netid);

      setdecA(aba.decimals);
      setsymbolA(aba.symbol);
      setCt(aba.address);
      setamount(a.amount);
      settime(a.endtime);
      setowner(a.owner);
      setdata(a);
    } catch (error) {
      // console.log(error)
    }
  };

  function getTimeRemaining(endTime) {
    const total = Date.parse(endTime) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));
    if (total < 0) {
      return "EXPIRED";
    } else {
      return days + "D " + hours + "H " + minutes + "M " + seconds + "S";
    }
  }

  return (
    <>
      <div className="form">
        <div className="row">
          <div className="col col-6">
            <SAFE what={"Lock Name:"} result={data.name ? data.name : "-"} />
          </div>
          <div className="col col-6">
            <SAFE
              what={"Owner"}
              result={owner ? owner.slice(0, 4) + "..." + owner.slice(-4) : "-"}
            />
          </div>
        </div>

        <div className="row">
          <div className="col col-6">
            <SAFE
              what={"Locked Amount (" + symbola?.toUpperCase() + ")"}
              result={amount ? nextblock(amount, deca) : "-"}
            />
          </div>
          <div className="col col-6">
            <SAFE
              what={"Finish in"}
              result={
                time ? getTimeRemaining(new Date(parseInt(time) * 1000)) : "-"
              }
            />
          </div>
        </div>

        {data.enabled != undefined && parseInt(data.enabled) != 0 && (
          <>
            <hr style={{ margin: "20px 0" }} />
            <div className="row">
              <div className="col col-4">
                <SAFE
                  what={"Vesting Starts In:"}
                  result={
                    data.U_VestingInitialIN
                      ? new Date(
                          parseInt(data.U_VestingInitialIN) * 1000
                        ).toString()
                      : "-"
                  }
                />
              </div>
              <div className="col col-4">
                <SAFE
                  what={"Start Percent"}
                  result={
                    data.U_VestingInitial ? data.U_VestingInitial + " %" : "-"
                  }
                />
              </div>
              <div className="col col-4">
                <SAFE
                  what={"Withdraw circle (minutes)"}
                  result={
                    data.U_VestingCircle
                      ? data.U_VestingCircle?.toString()
                      : "-"
                  }
                />
              </div>
              <div className="col col-4">
                <SAFE
                  what={"Withdraw Percent per Circle"}
                  result={
                    data.U_VestingPerCircle
                      ? data.U_VestingPerCircle + " %"
                      : "-"
                  }
                />
              </div>
            </div>
          </>
        )}
      </div>

      {props.account == owner && (
        <div className="group-buttons">
          <Buttons
            func={() => {
              navigate(props.uri + "/update/?=" + props.posi);
            }}
            text={"UpdateLock"}
            className="next"
          />
          <Buttons
            func={() => {
              navigate(props.uri + "/transfer/?=" + props.posi);
            }}
            text={"Transfer Lock"}
            className="next"
          />
          <Buttons
            func={() => {
              props.renounceLock();
            }}
            text={"Renounce Lock"}
            className="next"
          />

          <Buttons
            func={() => {
              props.unlock(props.posi);
            }}
            text={"Unlock"}
            className="next"
          />
        </div>
      )}
    </>
  );
};
export default DetailTokenLock;
