export default {
    Name: "Solpad",
    logoUrl: "https://solpad.io/wp-content/uploads/2023/12/Logo-Solpad-1024x807.png",
    Logo: <img
        src={"https://solpad.io/wp-content/uploads/2023/12/Logo-Solpad-1024x807.png"}
        style={{
            height: "50px",
            width: "50px",
            margin: "auto",
        }}
    />,
    backgroundColor: "#333333",
    buttonsColor: "#161a1e",
    modalsColor: "#161a1e",
    outbuttonsColor: "#fff",
    buttonsBorders: "1px solid #ffffff20",
    inputsColor: "#ffffff00",
    inputsBorders: "1px solid #ffffff20",
    Borders: "#ffffff20",
    outFont: "#fff",
    innerFont: "#E0E0E0",
    innerBFont: "#222",
    redirectTo: '/create/contract',
    Routers:
        [
            '/',
 
            
            '/private-sales',
            
     
            '/private-sale',
     
            '/create/private-sale',
    
      
            '/private-sale/edit',
           
          
            '/lock',
            '/lock/data',
            '/lock/create',
            '/lock/update',
            '/lock/transfer',
     
        ],
    menudata: [
        {
            permit: true,//Trade
            subpermit: [
                {
                    permit: true
                },
                {
                    permit: true
                },
                {
                    permit: true
                },
            ]
        },
        {
            permit: true,//Trade
            subpermit: [
                {
                    permit: true
                },
                {
                    permit: true
                },
                {
                    permit: true
                },
            ]
        },
        {
            permit: true,//Trade
            subpermit: [
                {
                    permit: true
                },
                {
                    permit: true
                },
                {
                    permit: true
                },
            ]
        },
            {
            permit: true,//lock
            subpermit: [
                {
                    permit: true
                },
                {
                    permit: true
                },

            ]
        },
        {
            permit: true,//docs
            subpermit: [
                {
                    permit: true
                },
                {
                    permit: true
                },
                {
                    permit: true
                },
            ]
        },
    ],
    rpc: "https://bsc-dataseed.binance.org",
    tokenContract: "",
    info: {
        title: "The Future Of Decentralized Funding",
        desc: "SolPad is a premier launchpad/incubator for innovative projects on the Solana blockchain. Join our community of early investors and discover the top projects launching on the Solana network.",
        buyon: "https://www.dextools.io/app/en/solana/pair-explorer/FWznLu333fEdgqtmEprw6hrxG1EnXpZmi1Asnxw3mZVZ",
        name: "SolPad",
        symbol: "SolPad",
        telegram: "https://t.me/SolPadPortal",
        twitter: "https://twitter.com/SolPadGlobal",
    },
    

}