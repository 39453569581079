import React, { useEffect, useState } from "react";
import "./styles.scss";
import Api from "../../programs/api";
import TrendsModal from "./trendingModal";
import { programNetwork } from "../../programs/utils";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import ThemeComponent from "../themes/themes";

const TrendingComponent = ({ price }) => {
  const [trends, settrendlist] = useState([]);
  const link =
    "https://www.dextools.io/app/en/solana/pair-explorer/FWznLu333fEdgqtmEprw6hrxG1EnXpZmi1Asnxw3mZVZ?t=1707275541739";

  useEffect(() => {
    load();
  }, []);

  async function load() {
    try {
      const list = await Api.launch.trend // .use("https://solpad-analytics-api.azurewebsites.net/api/")
        .get();

      settrendlist(list.items);
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div className="trending">
      <div className="trending__container">
        <div
          className="value-sol"
          onClick={() => window.open(link.toString(), "_blank")}
        >
          <div className="name">$SPAD</div>
          <div className="value">${price != undefined ? price : 0}</div>
        </div>
        <ThemeComponent />
        <div className="g-trending">
          <div className="trending__container--title">
            Trending
            <LocalFireDepartmentIcon />
          </div>
          <div className="group-itens-trending">
            <div className="marquee">
              <div className="itens-trending">
                {trends.map((e, i) => (
                  <>
                    <TrendsModal
                      uri={programNetwork(e.program).name}
                      order={e.order}
                      cts={e.cts}
                      tokenName={e.tokenName}
                      icon={e.icon}
                      url={e.url}
                    />
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrendingComponent;
