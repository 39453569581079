import { BN } from "@coral-xyz/anchor";
import Button from "../../../../../components/Launchpad/Button";
import InputBlock from "../../../../../components/Launchpad/InputBlock";

function OwnerFacHub({setpercentHC, percentHC, nSendTX, load, Badges}) {
    return (
        <>
            <div className="title-section-form">Owner Factory</div>

            <div className="form">
                <div className="row">
                    <div className="col col-12">
                        <InputBlock
                            title={"Percent HC"}
                            place={"5 is default"}
                            variavel={percentHC}
                            setvariavel={(e) => {
                                setpercentHC(e);
                            }}
                            desc={""}
                        />
                    </div>
                    <div className="col col-12">
                        <Button
                            func={() => {
                                nSendTX("setBadges", "0", 0, percentHC, Badges)
                                    .then((a) => {
                                        load();
                                    })
                                    .catch((e) => {
                                        //  //console.log(e);
                                    });
                            }}
                            text={"Update PercentHC"}
                            className="simple-button-default"
                        />
                    </div>
                </div>
                <div className="col col-12">
                    <InputBlock
                        title={"Amount"}
                        place={"0"}
                        id={"amount-rescue"}
                        //   variavel={percentHC}
                        setvariavel={(e) => {
                            //  setpercentHC(e);
                        }}
                        desc={""}
                    />
                </div>
                <div className="col col-12">
                    <Button
                        func={() => {
                            nSendTX(
                                "rescue",
                                "0",
                                0,
                                new BN(document.getElementById("amount-rescue").value)
                            )
                                .then((a) => {
                                    load();
                                })
                                .catch((e) => {
                                    //  //console.log(e);
                                });
                        }}
                        text={"Rescue Stuck values"}
                        className="simple-button-default"
                    />
                </div>
            </div>
        </>
    );
}
export default OwnerFacHub