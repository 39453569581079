import React, { useState, useEffect } from "react";
import env from "../../hooks/data/Config.js";
import FilterSale from "../../components/Launchpad/FilterSale.js";
import FilterSales from "../../components/Launchpad/FilterSales.js";
import HourglassDisabledOutlinedIcon from "@mui/icons-material/HourglassDisabledOutlined";
import LoadingComponent from "../../components/loading/loading.js";
import { ethers } from "ethers";
import CardLaunch from "../../components/card-launch/card-launch.js";
import { endedccheck, endedccheckc, nextblock, programNetwork } from "../../programs/utils.js";
import api from "../../programs/api.js";

const SalesList = ({ type, price }) => {
    const { account } = window.solpadContext;

    const [seconds, setSeconds] = useState(0);

    const [address, setaddress] = useState(account);
    const [list, setList] = useState([]);
    const [filter, setFilter] = useState("createAt");
    const [n, setN] = useState(0);
    const [listof, setlistof] = useState(0);

    const [searchValue, setSearchValue] = useState("");
    const [statusFilter, setstatusFilter] = useState("No filter");
    const [networkFilter, setNetworkFilter] = useState("All");
    const [poolTypeFilter, setPoolTypeFilter] = useState("No filter");
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        loadlist(0);
    }, []);


    useEffect(() => {
        let interval = null;
        interval = setInterval(async () => {
            setSeconds((seconds) => seconds + 1);
            const { account } = window.solpadContext;
            if (address) {
            } else {
                setaddress(account);
            }
            window.onscroll = async function () {
                var d = document.documentElement;
                var offset = parseInt(d.scrollTop + window.innerHeight) + 1;
                var height = d.offsetHeight;
                //   console.log({height, offset});
                if (offset >= height) {
                    await loadlist();
                    setList((list) => list);
                    //alert("FAfdf")

                    // loadmore(); // call function here
                }
            };
        }, 1000);
        return () => clearInterval(interval);
    }, [seconds]);
    function infavs(addr) {
        const lc = localStorage.getItem("solpad-local-fav") != undefined
            ? JSON.parse(localStorage.getItem("solpad-local-fav"))
            : {};
        return lc[addr] != undefined
    }
    function innots(addr) {
        const lc = localStorage.getItem("solpad-local-not") != undefined
            ? JSON.parse(localStorage.getItem("solpad-local-not"))
            : {};
        return lc[addr] != undefined
    }
    async function loadlist(listindex, chain = networkFilter) {
        try {
            const res = type == 0
                ? await api.launch.list.get(
                    "?PageSize=" + (listindex != undefined ? 10 : list.length + 10)
                    + (chain == "All" ? "" : ("&&Blockchain=" + chain))
                )
                : await api.private.list.get(
                    "?PageSize=" + (listindex != undefined ? 10 : list.length + 10)
                    + (chain == "All" ? "" : ("&&Blockchain=" + chain))
                )
            setList(res);
            setSeconds((seconds) => seconds + 1);
            setLoading(false);
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <>
            <div className="text-title">{type == 0 ? "Launchpad" : "Private"} List</div>
            <div className="text-sub-title">
                We bring new technologies and functions to our community, and created a
                variety of investment packages.
            </div>

            <div className="form">
                <FilterSale
                    opts={{
                        setSearchValue,
                    }}
                    filter={{
                        title: "",
                        showSearcFilter: true,
                        showComboFilter: false,
                    }}
                />

                <div className="group-filters" style={{ marginTop: "10px" }}>
                    <FilterSales setlistof={setlistof} />
                    <FilterSale
                        opts={{
                            setstatusFilter,
                            setFilter,
                            setNetworkFilter: (e) => {
                                setNetworkFilter(e)
                                setList([])
                                setSeconds((seconds) => seconds + 1);
                                setLoading(true);
                                loadlist(0, e)
                    
                            },
                            setPoolTypeFilter,
                        }}
                        filter={{
                            title: "",
                            showSearcFilter: false,
                            showComboFilter: true,
                        }}
                    />
                </div>
            </div>
            {loading && <LoadingComponent />}
            {!loading && (
                <>
                    {list && list.length == 0 && (
                        <div className="no-data">
                            <HourglassDisabledOutlinedIcon />
                            <p>No data found</p>
                        </div>
                    )}
                    {list && list.length > 0 && (
                        <div className="group-cards row">
                            {list
                                .sort((a, b) =>
                                    (
                                        filter === "No filter"
                                            ? true
                                            : filter === "Hard Cap"
                                                ? a.hardcap > b.hardcap
                                                : filter === "Soft Cap"
                                                    ? a.softcap > b.softcap
                                                    : filter === "LP percent"
                                                        ? a.percentLiquidity > b.percentLiquidity
                                                        : filter === "Start Time"
                                                            ? a.startTime > b.startTime
                                                            : filter === "End Time"
                                                                ? a.endTime > b.endTime
                                                                : filter === "createAt"
                                                                    ? a.createAt > b.createAt
                                                                    : true
                                    )
                                        ? 1
                                        : -1
                                )
                                .map((e, index) => (
                                    <CardLaunch
                                        obj={e}
                                        price={price[programNetwork(e.program).name]}
                                        inlist={
                                            listof == 0
                                                ? true
                                                : listof == 1
                                                    ? e.invested?.toString() != "0"
                                                    : listof == 2
                                                        ? infavs(e.cts)
                                                        : listof == 3
                                                            ? innots(e.cts)
                                                            : e.owner == address
                                        }
                                        status={endedccheck(e)}
                                        dec={e.quoteDecimals}
                                        nameSearch={searchValue}
                                        filter={statusFilter}
                                        isOnFilter={
                                            statusFilter === "No filter"
                                                ? true
                                                : statusFilter === endedccheckc(e)
                                        }
                                    />
                                ))
                                .reverse()}
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default SalesList;
