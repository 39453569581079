
import fairabi from '../../abis/fairSale/fairSale.json'
import lockabi from '../../abis/lock/lock.json'

import privabi from '../../abis/Private/privateAbi.json'
import preabi from '../../abis/PreSale/PreSale.json'
import preabiv2 from '../../abis/PreSale/PreSalev2.json'
import preabiv3 from '../../abis/PreSale/PreSalev3.json'
import factoryabi from '../../abis/factory.json'


const data = {
    router: "10ED43C718714eb63d5aA57B78B54704E256024E",
    providerURL: "https://bsc-dataseed.binance.org",
    factory: "ASu6Fai1VM8Dj5EqmUNV11pjazMv3mXGp8TvPFXtKKwb",
    bank: "AAVGwE2iKzbWmehmLyeA7PxKRDwucYWJ3PeBzzxNpuXY",
    pbank: "5QqhCH2BtbY6QfiB5G2SFZW8figkFp6LTo441RL3oZsA",
    lbank: "9Mamd7UCbbkWv2qkaj2NdZK618cZAgHxNBcfjDe3aPwz",//definir
    fbank: "9Mamd7UCbbkWv2qkaj2NdZK618cZAgHxNBcfjDe3aPwz",//definir
    ownerFAC: "Hjvcw2tNx5aZdJCZ5qBDgkcGZMA6cvsBWBhrvDAMY2FT",
    preSaleContract: "AvdDZ3qG5b4JcVViY6MZUDhbmqCMepNfoTEyUkqaaSY1",
    preSaleContractv2: "5ic31ZwHCvL9vg8b4er22TDLaDyLakEv167CJNTsyXhU",
    fairSaleContract: "5x1ZUJiA2YwEEVavMAu6cafxH4LsxP3ceKG6Ccg98WWQ",
    priSaleContract: "8ySRF1aUq5CDVxGhrh62s3uW5v9kZxw4xeK3uaB284KB",
    lockContract: "4adD2DpsCqsVdDCBHo9DxHqmqqLtsuVZv5fUE3y72hYf",
    lockStorage: "3ZCCcDNTLKfZBFQ1AeXjtc5euT7DXq1fumeFMS6WWeSM", // lockStorage: "3ZCCcDNTLKfZBFQ1AeXjtc5euT7DXq1fumeFMS6WWeSM"   // lockStorage: "2P4Pw5DpcUe2DVGn9Qe5gX8XkgKfE52eLdmX7MUTzaFA"
    tron: {
        lock: "TCzhibZkHRHyzkwW5jm64ooRANkXLN79zN",//"TTPHQXwDUzbjoQZaPNVhq5UDfthEiZPBRL",
        lockabi: require('../../abis/lock/tronlock.json'),

        preSaleContract: "TEhsrzyxy12gYkBZThoPKNSknGwbkto8kX",//"TBVge3LErN3C3Nk8ja1SFJpGQd8uf7QNDo",//"TQcu6wpbEN27gkVsrxET4H8gGhcLRhFh2f",//"TRecaqEuzBsmwW74rK1LJfMu9earrCyQoa",//"THZDAMxmJSuTePNmkMzYK5hQoUxqC9jGLc",
        preSaleFactoryAbi: require('../../abis/PreSale/TronPresale.json'),
        preSaleAbi: require('../../abis/PreSale/SalePreSale.json')
    },
    "evm-56": {
        lock: "0x8076ECa45fb32187aC5c9dB1E88760C9D4b934EF",//"TTPHQXwDUzbjoQZaPNVhq5UDfthEiZPBRL",
        lockabi: require('../../abis/lock/bsclock.json'),
        
        preSaleContract: "0x29dc484c6375D800A6E9F84043C43a6FE1a3BF55",//"TBVge3LErN3C3Nk8ja1SFJpGQd8uf7QNDo",//"TQcu6wpbEN27gkVsrxET4H8gGhcLRhFh2f",//"TRecaqEuzBsmwW74rK1LJfMu9earrCyQoa",//"THZDAMxmJSuTePNmkMzYK5hQoUxqC9jGLc",
        preSaleFactoryAbi: require('../../abis/PreSale/bsc-presale.json'),
        preSaleAbi: require('../../abis/PreSale/bsc-presale-sale.json')
    },
    "evm-97": {
        lock: "0x31a616150318c21e52ac65c2697cA916b7239683",//"TTPHQXwDUzbjoQZaPNVhq5UDfthEiZPBRL",
        lockabi: require('../../abis/lock/bsclock.json'),

        preSaleContract: "0x4717B709301Aef8FCaFAa494c1370DF01D75E5D3",//"TBVge3LErN3C3Nk8ja1SFJpGQd8uf7QNDo",//"TQcu6wpbEN27gkVsrxET4H8gGhcLRhFh2f",//"TRecaqEuzBsmwW74rK1LJfMu9earrCyQoa",//"THZDAMxmJSuTePNmkMzYK5hQoUxqC9jGLc",
        preSaleFactoryAbi: require('../../abis/PreSale/bsc-presale.json'),
        preSaleAbi: require('../../abis/PreSale/bsc-presale-sale.json')
    },
    token: {
        abi: require("../../abis/token.json")
    },
    abis: {

    },
    endpoints: {

    }
}
data.abis[data.factory] = factoryabi;
data.abis[data.preSaleContract] = preabi;
data.abis[data.preSaleContractv2] = preabiv3;
data.abis[data.fairSaleContract] = fairabi;
data.abis[data.priSaleContract] = privabi;
data.abis[data.lockContract] = lockabi;

data.endpoints[data.factory] = "";
data.endpoints[data.preSaleContract] = "presale";
data.endpoints[data.preSaleContractv2] = "presale";
data.endpoints[data.fairSaleContract] = "fairsale";
data.endpoints[data.priSaleContract] = "private";
data.endpoints[data.lockContract] = "";

export default data;