import React from 'react';

const Numberinput = (props) => {
    return (
        <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto", gap: "2px", margin: "auto", marginTop: "5px" }}>
            <p style={{ margin: "auto", marginLeft: "0%", textAlign: "left", color: "#ddd", fontSize: "10px" }}>{props.title}</p>
            <div style={{ fontSize: "15px", outline: "none", color: "#fff", height: "35px", width: "100%", display: "grid", gridTemplateColumns: "30px auto 30px", margin: "auto", border: "1px solid #ffffff20", background: "#00000050", borderRadius: "2px", }}>
                <p onClick={(e) => {
                    if (parseInt(props.variavel) - 1 < props.min) {
                        props.setvariavel(parseInt(props.min))
                    } else {
                        props.setvariavel(parseInt(props.variavel) - 1)
                    }
                }} style={{ cursor: "pointer", margin: "auto", textAlign: "center", color: "#fff", fontSize: "14px", width: "30px" }}>-</p>
                <input onChange={(e) => {
                    if (parseInt(e.target.value) < props.min) {
                        props.setvariavel(parseInt(props.min))
                    } else {
                        if (parseInt(e.target.value) > props.max) {
                            props.setvariavel(parseInt(props.max))
                        } else {
                            props.setvariavel(e.target.value)
                        }
                    }
                }} placeholder="0" value={props.variavel} style={{ fontSize: "15px", outline: "none", color: "#fff", textAlign: "center", height: "35px", width: "100%", display: "grid", gridTemplateColumns: "auto", margin: "auto", border: "1px solid #ffffff20", background: "#00000050", borderRadius: "2px" }}></input>
                <p onClick={(e) => {
                    if (parseInt(props.variavel) + 1 > props.max) {
                        props.setvariavel(parseInt(props.max))
                    } else {
                        props.setvariavel(parseInt(props.variavel) + 1)
                    }
                }} style={{ cursor: "pointer", margin: "auto", textAlign: "center", color: "#fff", fontSize: "14px", width: "30px" }}>+</p>
            </div>
        </div>
    );
}

export default Numberinput;