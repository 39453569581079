import React, { useState, useEffect } from "react";
import {

    useWallet,
    useAnchorWallet,
} from "@solana/wallet-adapter-react";

import {

    WalletDisconnectButton,
    WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import {
    PublicKey,
    Connection,

} from "@solana/web3.js";

import { Provider, BN } from "@project-serum/anchor";
import Content from "../../../pages/content.js";
import Api from "../../../programs/api.js";
const SolConnection = ({
    selected,
    rpcs,
    select,
    setPriority,
    setCustom,
    custom,
}) => {
    const connection = new Connection(
        custom != undefined ? custom : rpcs[selected].url
    );
    const { publicKey, sendTransaction, signTransaction, connected } =
        useWallet();
    const wallet = useAnchorWallet();

    const [seconds, setSeconds] = useState(0);
    const [price, setprice] = useState(0);
    const [priceSolApi, setPriceSolApi] = useState(0);
    const [priceTronApi, setPriceTronApi] = useState(0);
    const provider = new Provider(
        connection,
        wallet, // Use a carteira fornecida pelo @solana/wallet-adapter-react
        {
            preflightCommitment: "recent",
            commitment: "recent",
        }
    );
    useEffect(() => {
        loadprice();
        //getmarket()
    }, []);

    const loadprice = async () => {
        try {
            const tick = await Api.blockchain.status.get("/solana")
            const tickB = await Api.blockchain.status.get("/tron")
            // console.log(parseFloat(tickB.price))
            setPriceSolApi(parseFloat(tick.price));
            setPriceTronApi(parseFloat(tickB.price))
            
            const associatedPoolKeys = {
                base: new PublicKey("5shqQQeG1Y4mujvhXMrgjYZaLaFnFLLtfpFrCESEzrr6"),
                quote: new PublicKey("7XKmdTcALL4KVNM7jKpMZ8JkYr5RRdtk9RSv9cSSmrHm"),
            };
            const balanceA = await connection.getTokenAccountBalance(
                associatedPoolKeys.base
            );
            const balanceB = await connection.getTokenAccountBalance(
                associatedPoolKeys.quote
            );
            const priceSol =
                parseFloat(balanceB.value.uiAmount) /
                parseFloat(balanceA.value.uiAmount);

       
            const price = parseFloat(tick.price) * parseFloat(priceSol);

            setprice(price.toFixed(3));
        } catch (error) { }
    };



    const data = {
        price,
        publicKey,
        sendTransaction,
        signTransaction,
        provider,
        connection,
        connected,
        wallet,
        account: publicKey != undefined && publicKey.toBase58()
    };

    window.solpadContext =
        window.solpadContext != undefined
            ? {
                ...window.solpadContext,
                ...data,
            }
            : data;
    useEffect(() => {
        const data = {
            price,
            publicKey,
            sendTransaction,
            signTransaction,
            provider,
            connection,
            connected,
            wallet,
            account: publicKey != undefined && publicKey.toBase58()
        };

        window.solpadContext =
            window.solpadContext != undefined
                ? {
                    ...window.solpadContext,
                    ...data,
                }
                : data;
    }, [wallet, publicKey]);

    useEffect(() => {
        if (connected && publicKey != undefined) {
            const data = {
                price,
                publicKey,
                sendTransaction,
                signTransaction,
                provider,
                connection,
                connected,
                wallet,
                account: publicKey != undefined && publicKey.toBase58()
            };

            window.solpadContext =
                window.solpadContext != undefined
                    ? {
                        ...window.solpadContext,
                        ...data,
                    }
                    : data;
        } else {
            let interval = null;
            interval = setInterval(() => {
                setSeconds((seconds) => seconds + 1);
            }, 1000);
            const data = {
                price,
                publicKey,
                sendTransaction,
                signTransaction,
                provider,
                connection,
                connected,
                wallet,
                account: publicKey != undefined && publicKey.toBase58()
            };

            window.solpadContext =
                window.solpadContext != undefined
                    ? {
                        ...window.solpadContext,
                        ...data,
                    }
                    : data;

            return () => clearInterval(interval);
        }
    }, [seconds]);

    return (
        <>
            <Content
                WalletButton={<WalletMultiButton />}
                WalletButtonOut={<WalletDisconnectButton />}
                selected={selected}
                rpcs={rpcs}
                select={select}
                price={price}
                setPriority={setPriority}
                setCustom={setCustom}
                priceSol={{
                    solana:priceSolApi,
                    tron:priceTronApi,
                }}
            />
        </>
    );
};
export default SolConnection