import React from "react";
import { nextblock, programNetwork, quote } from "../../../../programs/utils";

const DataModal = ({
  program,
  object,
  types,
  SAFE,
  percent,
  tksforlp,
  WarningAmberOutlinedIcon,
  leftValue,
  estimateMC,
}) => {
  if (object == undefined) {
    return (
      <div class="animated-background">
        <div class="background-masker btn-divide-left"></div>
      </div>
    );
  }
  console.log(estimateMC())
  return (
    <div className="form">
      <SAFE
        what={"Tokens for " + types(object.url) + ""}
        result={
          types(object.url) == "Fair Launch"
            ? nextblock(object.presale_rate, object.tokendec) +
            " " +
            object.tokesymbol
            : object.presale_rate != undefined
              ? (
                nextblock(object.presale_rate, object.tokendec) *
                nextblock(object.hardcap, object.quoteDecimals)
              ).format() +
              " " +
              object.tokesymbol
              : "-"
        }
      />
      <hr style={{ margin: "5px 0" }} />
      <SAFE
        what={"Tokens For Liquidity"}
        result={
          object.listRate != undefined
            ? percent(tksforlp(), object.feeSaleToken, object.saleDecimals) +
            " " +
            object.tokesymbol
            : "-"
        }
      />
      <hr style={{ margin: "5px 0" }} />
      <SAFE
        what={"Liquidity Percent"}
        result={
          object.percentLiquidity != undefined
            ? object.percentLiquidity + " %"
            : "-"
        }
      />
      <hr style={{ margin: "5px 0" }} />
      <SAFE
        what={"Liquidity Listing"}
        result={
          object.listOP != undefined
            ? parseInt(object.listOP) == 1
              ? "Manual"
              : "Automatic"
            : "-"
        }
      />
      <hr style={{ margin: "5px 0" }} />
      {/*<SAFE
            what={types(object.url) + " Address"}
            result={
                <p
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        window.open("https://solscan.io/account/" + object.cts);
                    }}
                >
                    {object.cts}
                    <p />
                    <div className="warning-message">
                        <WarningAmberOutlinedIcon
                            sx={{ fill: "#babd41", width: "0.8rem", height: "0.8rem" }}
                        />
                        {"Do not send SOL or tokens to the " +
                            types(object.url) +
                            " address!"}
                    </div>
                </p>
            }
        />
        <hr style={{ margin: "5px 0" }} />*/}
      <SAFE
        what={types(object.url) + " Address"}
        result={
          <p
            style={{ cursor: "pointer" }}
            onClick={() => {

              window.open(programNetwork(object.program).explorer + program.convertAddress(object.bank));
            }}
          >
            {program.convertAddress(object.bank)}
            <p />
            <div className="warning-message">
              <WarningAmberOutlinedIcon
                sx={{ fill: "#babd41", width: "0.8rem", height: "0.8rem" }}
              />
              {"Do not send " + quote(programNetwork(object.program).name, object.utoken) + " or tokens to the " +
                types(object.url) +
                " address!"}
            </div>
          </p>
        }
      />
      <hr style={{ margin: "5px 0" }} />
      <SAFE
        what={types(object.url) + " Rate"}
        result={

          types(object.url) == "Fair Launch"
            ? "1 " + quote(programNetwork(object.program).name, object.utoken) + " = "
            +
            nextblock(object.rate, object.tokendec).format() +
            " " +
            object.tokesymbol
            : "1 " + quote(programNetwork(object.program).name, object.utoken)
            + " = " +
            nextblock(object.presale_rate, object.tokendec).format() +
            " " +
            object.tokesymbol
        }
      />
      <hr style={{ margin: "5px 0" }} />
      <SAFE
        what={"Listing Rate"}
        result={
          object.listRate != undefined
            ? "1 "+(quote(programNetwork(object.program).name, object.utoken)) + " = "+
            (types(object.url) == "Fair Launch"
              ? (
                parseFloat(1) /
                parseFloat(
                  parseFloat(leftValue()) /
                  nextblock(object.listRate, object.tokendec)
                )
              ).format()
              : nextblock(object.listRate, object.tokendec).format()
            ) +
            " " +
            object.tokesymbol
            : "-"
        }
      />
      <hr style={{ margin: "5px 0" }} />
      <SAFE what={"Sale method"} result={types(object.url) + ""} />
      <hr style={{ margin: "5px 0" }} />
      <SAFE
        what={"softcap"}
        result={
          nextblock(object.softcap, object.quoteDecimals) +
          (quote(programNetwork(object.program).name, object.utoken))
        }
      />
      {object.hardcap != undefined && (
        <>
          <hr style={{ margin: "5px 0" }} />
          <SAFE
            what={"hardcap"}
            result={
              nextblock(object.hardcap, object.quoteDecimals) +
              (quote(programNetwork(object.program).name, object.utoken))
            }
          />
        </>
      )}
      <hr style={{ margin: "5px 0" }} />
      <SAFE
        what={"Initial MarketCap (estimate)"}
        result={
          estimateMC() != undefined
          ? estimateMC()
          : "-"
        }
      />
      {types(object.url) != "Fair Launch" && (
        <>
          <hr style={{ margin: "5px 0" }} />
          <SAFE
            what={"Unsold tokens"}
            result={
              object.refundType == 0
                ? object.cts == "8wyU6VzpqCjpiN11MtUVo9F3JrfmsaK4dWo6QwsRnTeD"
                  ? "Burn"
                  : "Refund"
                : "Burn"
            }
          />
        </>
      )}
      {types(object.url) != "Fair Launch" && (
        <>
          <hr style={{ margin: "5px 0" }} />
          <SAFE
            what={"Minimum buy"}
            result={
              nextblock(object.minbuy, object.quoteDecimals) +
              (quote(programNetwork(object.program).name, object.utoken))
            }
          />
        </>
      )}
      <hr style={{ margin: "5px 0" }} />
      <SAFE
        what={"Maximum buy"}
        result={
          nextblock(object.maxbuy, object.quoteDecimals) +
          (quote(programNetwork(object.program).name, object.utoken))
        }
      />
      <hr style={{ margin: "5px 0" }} />
      <SAFE
        what={"Start time"}
        result={
          object.startTime != undefined || object.startTime != null
            ? new Date(parseInt(object.startTime * 1000)).toLocaleString()
            : "..."
        }
      />
      <hr style={{ margin: "5px 0" }} />
      <SAFE
        what={"End time"}
        result={
          object.endTime != undefined || object.endTime != null
            ? new Date(parseInt(object.endTime * 1000)).toLocaleString()
            : "..."
        }
      />
    </div>
  );
};
export default DataModal;
