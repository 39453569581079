import React, { useContext, useEffect, useState } from "react";
import InputBlock from "../../components/Launchpad/InputBlock.js";
import CheckList from "../../components/Launchpad/ListCheck.js";
import Button from "../../components/Launchpad/Button.js";
import DescBlock from "../../components/Launchpad/descBlock.js";
import Checkbox from "../../components/Launchpad/Checkbox.js";
import AppContext from "../../framework/helpers/AppContext.js";
import SwichNetwork from "../component/SwichNetwork.js";
import { toBigInt } from "ethers";
import { solana } from "@ant-design/web3-solana";
import { TronContract, tronWeb } from "../../programs/programs.js";
import { getToken, nextblock, programNetwork, returnbignumber } from "../../programs/utils.js";
import tokensTron from "./token/tron/tokens.js";
import { Emit } from "../../components/Alert/Alert.js";
import data from "../../hooks/data/Config.js";
const lpabi = [{
    "outputs": [
        {
            "name": "amountToken",
            "type": "uint256"
        },
        {
            "name": "amountETH",
            "type": "uint256"
        },
        {
            "name": "liquidity",
            "type": "uint256"
        }
    ],
    "inputs": [
        {
            "name": "token",
            "type": "address"
        },
        {
            "name": "amountTokenDesired",
            "type": "uint256"
        },
        {
            "name": "amountTokenMin",
            "type": "uint256"
        },
        {
            "name": "amountETHMin",
            "type": "uint256"
        },
        {
            "name": "to",
            "type": "address"
        },
        {
            "name": "deadline",
            "type": "uint256"
        }
    ],
    "name": "addLiquidityETH",
    "stateMutability": "Payable",
    "type": "Function"
}]
const AddLp = (props) => {
    const {
        setnet,
        netid
    } = useContext(AppContext)
    useEffect(() => {

    }, [])
    // const [contracts, setcontracts] = useState(0);
    const [step, setstep] = useState(0);
    const [ta, setta] = useState("TNUC9Qb1rRpS5CbWLmNMxXBjyFoydXjWFR");
    const [tb, settb] = useState("");
    const [aa, setaa] = useState(0);
    const [ab, setab] = useState(0);
    const [tokenA, setTokenA] = useState({
        ct: undefined,
        name: "",
        symbol: "",
        dec: 6,
        supply: "",
        allow: 10000000000000000,
        balance: 0
    });
    const [tokenB, setTokenB] = useState({
        ct: undefined,
        name: "",
        symbol: "",
        dec: 0,
        supply: "",
        allow: 0,
        balance: 0
    });
    async function create() {
        const { account, signTransaction } = window.solpadContext
        const alert = Emit("Building Token...", "", "load");
        try {

            switch (netid) {
                case "tron":
                    const lpContract = "TKzxdSv2FZKQrEqkKVgp5DcwEXBEKMg2Ax"
                    const createlp = new TronContract(
                        lpContract, lpContract, lpabi
                    )
                    const isMainQuote = (ta).toUpperCase() == ("TNUC9Qb1rRpS5CbWLmNMxXBjyFoydXjWFR").toUpperCase()
                    if (isMainQuote) { } else {
                        const _tokenA = new TronContract(tokenA.ct, tokenA.ct, data.token.abi)
                        await _tokenA.methods.approve.send(0, lpContract, '1' + '0'.repeat(50))
                    }

                    const _tokenB = new TronContract(tokenB.ct, tokenB.ct, data.token.abi)
                    await _tokenB.methods.approve.send(0, lpContract, '1' + '0'.repeat(50))

                    await createlp.methods.addLiquidityETH.send(
                        isMainQuote
                            ? returnbignumber(parseFloat(aa), 6)
                            : 0
                        , tb, returnbignumber(parseFloat(ab), tokenB.dec), 0, 0, account, parseInt(Date.now() / 1000) + 60
                    )
                    /*  await createlp.methods._share.send(
                          isMainQuote
                              ? returnbignumber(parseFloat(aa), 6)
                              : 0
                          , ta, tb, returnbignumber(parseFloat(aa), tokenA.dec), returnbignumber(parseFloat(ab), tokenB.dec)
                      )*/
                    break;

                default:
                    break;
            }

        } catch (error) {
            alert.update("Error", error, "error")
            //console.log(error)
        }
    }
    async function gettoken(ct, set) {
        const { account, signTransaction } = window.solpadContext
        try {
            if (ct?.toString().trim().length < 0) {
                throw "invalid token"
            } else {
                //   console.log(ct?.toString().trim())
                const obj = await getToken(ct?.toString().trim(), netid);
                // console.log(obj)
                obj.ct = ct?.toString().trim();
                obj.allow = 10000000n;
                obj.dec = obj.decimals;
                try {
                    obj.balance = await obj.getBalance(account)
                } catch (error) {

                }

                set(obj);
            }
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            <div className="text-title">SunPad LP provider</div>
            <div className="text-sub-title">

            </div>
            <SwichNetwork />
            <div className="group-create">
                {netid == "solana" ? <></> :
                    <div className="form">
                        <InputBlock title={"Token A *"} place={"Type Address to quote token"} variavel={ta} setvariavel={(e) => { setta(e); gettoken(e, setTokenA) }} desc={"Main token like (TRX, BUSD, USDT)"} />
                        <InputBlock type={"Number"} title={"Amount A *"} place={"0"} variavel={aa} setvariavel={setaa} desc={"Balance: " + nextblock(tokenA.balance, tokenA.dec)} />
                        <InputBlock title={"Token B *"} place={"Type Address to Base token"} variavel={tb} setvariavel={(e) => { settb(e); gettoken(e, setTokenB) }} desc={"Your token"} />
                        <InputBlock type={"Number"} title={"Amount B *"} place={"0"} variavel={ab} setvariavel={setab} desc={"Balance: " + nextblock(tokenB.balance, tokenB.dec)} />

                    </div>
                }
                <div className="group-buttons">
                    {netid == "solana"
                        ? <Button
                            func={() => {

                            }}
                            text={"Disable"}
                            next={true}
                            className="next"
                        />
                        :
                        <Button
                            func={() => {
                                create();
                            }}
                            text={"Add liquidity"}
                            next={true}
                            className="next"
                        />


                    }
                </div>
            </div>
        </>
    )
};

export default AddLp;
