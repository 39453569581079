import React, { useState, useEffect } from "react";
import facabi from "../../abis/lock/lock.json";
import { ethers } from "ethers";
import env from "../../hooks/data/Config.js";
import ProjectData from "../../config.js";
import HourglassDisabledOutlinedIcon from "@mui/icons-material/HourglassDisabledOutlined";
import LoadingComponent from "../../components/loading/loading.js";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useNavigate } from "react-router-dom";
import CardTokenLock from "./shared/card-token-lock.js";
import TokenLockCreator from "./shared/token-lock-creator.js";
import TransferTokenLock from "./shared/transfer-token-lock.js";
import UpdateTokenLock from "./shared/update-token-lock.js";
import DetailTokenLock from "./shared/detail-token-lock.js";
import api from "../../programs/api.js"
import { getProp, getToken, getTokenAddress, returnbignumber, testAccountsToken } from "../../programs/utils.js";
import CreateLock from "./create-lock.js";
import { BN } from "@coral-xyz/anchor";
import { PublicKey } from "@solana/web3.js";
import { Contract, EVMContract, TronContract } from "../../programs/programs.js";
import { useContext } from "react";
import AppContext from "../../framework/helpers/AppContext.js";
import SwichNetwork from "../component/SwichNetwork.js";
import FilterSale from "../../components/Launchpad/FilterSale.js";

const LockList = (props) => {
  const info = props.type == 0 ? "token" : "liquidity"
  const uri = props.type == 0 ? "/lock/token/list" : "/lock/liquidity/list"
  const infouri = props.type == 0 ? "/lock/token" : "/lock/liquidity"
  const [listLimit, setListLimit] = useState(5);
  const [list, setList] = useState([]);
  const [data, setdata] = useState(undefined);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(true);
  const {
    setnet,
    netid
  } = useContext(AppContext)
  const [lock, setlock] = useState(
    netid == "solana"
      ? new Contract(env.lockStorage, env.lockContract)
      : netid == "tron"
        ? new TronContract(env.tron.lock, env.tron.lock, env.tron.lockabi)
        : new EVMContract(env[getProp(netid)].lock, env[getProp(netid)].lock, env[getProp(netid)].lockabi)
  );
  const { account } =
    window.solpadContext;
  const address = account;

  const navigate = useNavigate();

  useEffect(() => {
    loadlist();
  }, []);

  const Views = (props) => {
    return <>{props.elements}</>;
  };

  async function loadlist() {
    try {
      //console.log(props.view);
      if (props.view == 2) {
        const a = await getlock(window.location.href.toString().split("?=")[1]);

        const aba = await getToken(a.spltoken, netid);

        setdata({
          ...a,
          decimals: aba.decimals,
          symbol: aba.symbol,
          address: aba.address,
          amount: a.amount,
          endtime: a.endtime,
          owner: a.owner,
        });
        setLoading(false);
      } else {
        // console.log("error");
        setList([]);
        setLoading(true);
        const { account } =
          window.solpadContext;
        const result = await api.lock[getProp(netid)][info].list.get(
          !props.my
            ? "?addr=11111111111111111111111111111111"
            :
            "?addr=" + (
              netid == "solana"
                ? account
                : account
                  ? lock.convertAddress(account)
                  : "11111111111111111111111111111111"
            )
        )

        setList(result);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        loadlist();
      }, 2000);
    }
  }
  async function getlock(pos) {
    try {
      const res = await api.lock[getProp(netid)][info].get("/" + pos)
      return {
        ...res,
        owner: lock.convertAddress(res.owner)
      }
    } catch (error) {
      return undefined;
    }

  }
  async function unlock(id) {

    try {

      switch (netid) {
        case "solana":
          const l = await getlock(id);
          const extras = await testAccountsToken(l.spltoken, [
            address,
            env.lbank,
          ]);
          await lock.methods.unLock.send([...extras], [],
            {
              toWallet: await getTokenAddress(l.spltoken, address),
              programWallet: await getTokenAddress(l.spltoken, env.lbank),
              bank: env.lbank
              //  programId: new PublicKey(lock.bank),
            },
            new BN(id)
          )
          break;
        case "tron":

          await lock.methods.unLock.send(0, id)

          break;
        default:
          await lock.methods.unLock.send(0, id)

          break;
      }

    } catch (error) {
      console.log(error);
    }
  }
  async function uplock(id, name, amount, end) {

    try {
      const l = await getlock(id);
      const aba = await getToken(l.spltoken);
      switch (netid) {
        case "solana":

          const from = await getTokenAddress(l.spltoken, address);
          const to = await getTokenAddress(l.spltoken, env.lbank);
          const extras = await testAccountsToken(l.spltoken, [
            address,
            lock.storage,
            env.lbank,
          ]);
          await lock.methods.updateLock.send([...extras], [],
            {
              fromWallet: from,
              programWallet: to,
            },
            new BN(id),
            name,
            new BN(returnbignumber(amount, aba.decimals)),
            new BN(end)
          )
          break;
        case "tron":
          const tokenContract = new TronContract(
            l.spltoken.toString(),
            l.spltoken.toString(),
            env.token.abi
          )
          await tokenContract.methods.approve.send(0, lock.contract, returnbignumber(amount, aba.decimals))
          await lock.methods.updateLock.send(0,
            id,
            name,
            returnbignumber(amount, aba.decimals),
            end
          )

          break;
        default:
          const bsctokenContract = new TronContract(
            l.spltoken.toString(),
            l.spltoken.toString(),
            env.token.abi
          )
          await bsctokenContract.methods.approve.send(0, lock.contract, returnbignumber(amount, aba.decimals))
          await lock.methods.updateLock.send(0,
            id,
            name,
            returnbignumber(amount, aba.decimals),
            end
          )

          break;
      }
    } catch (error) { }
  }
  async function transfer(nowner) {
    try {
      switch (netid) {
        case "solana":
          await lock.methods.transferLock.send([], [],
            {
              _newOwner: new PublicKey(nowner),
            },
            new BN(window.location.href.toString().split("?=")[1])
          )
        case "tron":

          await lock.methods.transferLock.send(0,
            nowner,
            window.location.href.toString().split("?=")[1]
          )

          break;
        default:
       
        await lock.methods.transferLock.send(0,
          nowner,
          window.location.href.toString().split("?=")[1]
        )
      }
    } catch (error) {
      //console.log(error);
    }
  }
  async function renounceLock() {
    try {
      switch (netid) {
        case "solana":
          await lock.methods.renounceLock.send([], [],
            {},
            new BN(window.location.href.toString().split("?=")[1])
          )
        case "tron":

          await lock.methods.renounceLock.send(0,
            window.location.href.toString().split("?=")[1]
          )

          break;
        default:
          
        await lock.methods.renounceLock.send(0,
          window.location.href.toString().split("?=")[1]
        )

          break;
      }
    } catch (error) {
      // console.log(error);
    }
  }


  return (
    <>
      {props.view != 0 && (
        <>
          <div className="btn-back" onClick={() => navigate(uri)}>
            <ArrowBackOutlinedIcon /> Back
          </div>
          <hr style={{ margin: "20px 0" }} />
        </>
      )}
      <div className="text-title">
        {props.view == 0
          ? "Lock List"
          : props.view == 2
            ? "Detail Lock"
            : props.view == 3
              ? "Update Lock"
              : "Transfer Lock"}
      </div>
      {loading && <LoadingComponent />}
      {!loading && (
        <>
          {props.view == 0 && <SwichNetwork />}
          {props.view == 0 && <FilterSale
            opts={{
              setSearchValue,
            }}
            filter={{
              title: "",
              showSearcFilter: true,
              showComboFilter: false,
            }}
          />}
          {props.view == 0 && <div className="row"> <>
            {list && list.length > 0 && (
              <>
                {list
                  .map((e) =>
                    e == null ? (
                      <></>
                    ) : (
                      (searchValue.toString() == ""
                        ? true
                        : (e.symbol.toString()).toUpperCase().includes((searchValue).toUpperCase())) &&
                      <div className="col col-2">
                        <CardTokenLock
                          type={props.type}
                          uri={infouri}
                          ct={env.lockContract}
                          account={account}
                          getToken={getToken}
                          data={e}
                        />
                      </div>
                    )
                  )
                  .reverse()}
              </>
            )}
            {list && list.length === 0 && (
              <div className="no-data">
                <HourglassDisabledOutlinedIcon />
                <p>No data found</p>
              </div>
            )}
          </></div>}
          {props.view == 2 && data != undefined && (
            <Views
              elements={
                <DetailTokenLock
                  type={props.type}
                  uri={infouri}
                  posi={window.location.href.toString().split("?=")[1]}
                  getToken={getToken}
                  getlock={getlock}
                  netid={netid}
                  unlock={unlock}
                  renounceLock={renounceLock}
                  heighti={"8vh"}
                  bgcolor={"#ffffff80"}
                  buttoncolor={"#1e1f26"}
                  ct={env.lockContract}
                  account={account}
                  data={data}
                />
              }
            />
          )}

          {props.view == 1 && (
            <Views
              elements={
                <CreateLock
                  type={props.type}
                />
              }
            />
          )}
          {props.view == 3 && (
            <Views
              elements={
                <UpdateTokenLock
                  getToken={getToken}
                  uri={uri}
                  getlock={getlock}
                  netid={netid}
                  uplock={uplock}
                  interact={{}}
                  posi={window.location.href.toString().split("?=")[1]}
                  heighti={"7vh"}
                  bgcolor={"#ffffff80"}
                  buttoncolor={"#1e1f26"}
                  ct={env.lockContract}
                  account={account}
                />
              }
            />
          )}
          {props.view == 4 && (
            <Views
              elements={
                <TransferTokenLock
                  getToken={getToken}
                  uri={uri}
                  transfer={transfer}
                  interact={{}}
                  posi={window.location.href.toString().split("?=")[1]}
                  heighti={"7vh"}
                  bgcolor={"#ffffff80"}
                  buttoncolor={"#1e1f26"}
                  ct={env.lockContract}
                  account={account}
                />
              }
            />
          )}
        </>
      )}
    </>
  );
};

export default LockList;
