import React, { useState } from "react";
import assets from "../../assets/index";
import LatestPresales from "../../components/latestPresales/latestPresales.js";
import TotalComponent from "../../components/total/total.js";
import { useNavigate } from "react-router-dom";
import LatestFairLaunchs from "../../components/latestFairLaunchs/latestFairLaunchs.js";
import LatestPrivateSales from "../../components/latestPrivateSales/latestPrivateSales.js";

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <div className="home">
      <div className="group-logo-home">
        <TotalComponent />
      </div>

      <hr style={{ margin: "30px 0" }} />

      <div className="group-links">
        <div className="btn-links" onClick={() => navigate("/launchpads")}>
          <img src={`${assets.images.launchList}`} loading="lazy" alt="" />
          <div className="label-button">Launchpad List</div>
        </div>
        <div className="btn-links" onClick={() => navigate("/create/presale")}>
          <img src={`${assets.images.create}`} loading="lazy" alt="" />
          <div className="label-button">Create Presale</div>
        </div>
        <div
          className="btn-links"
          onClick={() => navigate("/create/fairlaunch")}
        >
          <img src={`${assets.images.create}`} loading="lazy" alt="" />
          <div className="label-button">Create Fair Launch</div>
        </div>
        <div
          className="btn-links"
          onClick={() => window.open("https://tokenomics.solpad.io/", "_blank")}
        >
          <img src={`${assets.images.calculator}`} loading="lazy" alt="" />
          <div className="label-button">Tokenomics Calculator</div>
        </div>
        <div
          className="btn-links"
          onClick={() =>
            window.open("https://spadwhitepaper.solpad.io/", "_blank")
          }
        >
          <img src={`${assets.images.docs}`} loading="lazy" alt="" />
          <div className="label-button">Docs</div>
        </div>
        <div
          className="btn-links"
          onClick={() =>
            window.open(
              "https://spadwhitepaper.solpad.io/for-project-owners/solpad-badges",
              "_blank"
            )
          }
        >
          <img src={`${assets.images.badges}`} loading="lazy" alt="" />
          <div className="label-button">Badges</div>
        </div>
      </div>

      <hr style={{ margin: "30px 0" }} />

      <div className="row">
        <div className="col col-4">
          <LatestPresales />
        </div>
        <div className="col col-4">
          <LatestFairLaunchs />
        </div>
        <div className="col col-4">
          <LatestPrivateSales />
        </div>
      </div>

      <hr style={{ margin: "30px 0" }} />

      <div className="footer-home">
        Disclaimer: The content of any material provided or published by SolPad
        does not represent, in any way, financial advice and is not an
        investment recommendation. We are not responsible and do not accept
        liability for any loss of assets to any person or persons acting upon
        information present in any material associated with SolPad.
      </div>
    </div>
  );
};

export default HomePage;

/**
 
*/
