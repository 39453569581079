import React, { useState, useEffect, useContext } from "react";
import { ethers } from "ethers";
import SAFE from "../../components/Launchpad/textpar.js";
import InputBlock from "../../components/Launchpad/InputBlock.js";
import Button from "../../components/Launchpad/Button.js";
import Check from "../../components/Launchpad/Checkbox.js";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import enUS from "date-fns/locale/en-US";
import "react-datepicker/dist/react-datepicker.css";
import env from "../../hooks/data/Config.js";
import { getProp, getToken, getTokenAddress, nextblock, returnbignumber, testAccountsToken } from "../../programs/utils.js";
import { BN } from "@coral-xyz/anchor";
import { PublicKey } from "@solana/web3.js";
import { Contract, EVMContract, TronContract } from "../../programs/programs.js";
import AppContext from "../../framework/helpers/AppContext.js";
import SwichNetwork from "../component/SwichNetwork.js";

const Web3 = require("web3-eth");
registerLocale("en-US", enUS);

const CreateLock = (props) => {
  const info = props.type == 0 ? "Token" : "Liquidity Token"
  const {
    setnet,
    netid
  } = useContext(AppContext)
  const { account } =
    window.solpadContext;

  const [_poolfee, setpoofee] = useState(0);
  const [tokenB, setTokenB] = useState({
    ct: undefined,
    name: "",
    symbol: "",
    dec: 0,
    supply: "",
    allow: 0,
  });
  const [lock, setlock] = useState(
    netid == "solana"
      ? new Contract(env.lockStorage, env.lockContract)
      : netid == "tron"
        ? new TronContract(env.tron.lock, env.tron.lock, env.tron.lockabi)
        : new EVMContract(env[getProp(netid)].lock, env[getProp(netid)].lock, env[getProp(netid)].lockabi)
  );
  const [address, connector] = useState(account);
  const [tokenBCT, setTokenBCT] = useState(undefined);
  const [name, setName] = useState(undefined);
  const [amountTo, setAmountTo] = useState(0);
  const [endTime, setendTime] = useState(_toUTC(new Date()));
  const [cycleReleasePercent, setCycleReleasePercent] = useState(0);
  const [cycle, setCycle] = useState(0);
  const [tgePercent, setTgePercent] = useState(0);
  const [tgeDate, setTgeDate] = useState(_toUTC(new Date()));
  const [step, setstep] = useState(0);
  const [ovesting, setovesting] = useState(0);
  useEffect(() => {
    setendTime(_toUTC(new Date()));
  }, []);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
      //  _gettoken(tokenB.ct);
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);
  useEffect(() => {
    _gettoken(tokenBCT);
  }, [tokenBCT]);


  function nf(e) {
    return ("0" + e).slice(-2);
  }
  function toUTC(func, dateA) {
    func(dateA);
  }
  function _toUTC(dateA) {
    const time = new Date(dateA);
    const _time = new Date(
      dateA.getUTCFullYear() +
      "-" +
      nf(dateA.getUTCMonth() + 1) +
      "-" +
      nf(dateA.getUTCDate()) +
      "T" +
      nf(dateA.getUTCHours()) +
      ":" +
      nf(dateA.getUTCMinutes()) +
      ":00"
    );

    return new Date(Date.now());
  }
  function alltoBN(d) {
    let a = [];
    for (let index = 0; index < d.length; index++) {
      a.push(new BN(d[index]));
    }
    return a;
  }

  async function create() {
    const { account } = window.solpadContext;

    const address = account;
    const end = parseInt(Date.parse(endTime) / 1000);
    const _tgeDate = parseInt(Date.parse(tgeDate) / 1000);

    try {
      switch (netid) {
        case "solana":
          const from = await getTokenAddress(tokenB.ct, address);
          const to = await getTokenAddress(tokenB.ct, env.lbank);
          const extras = await testAccountsToken(tokenB.ct, [
            address,
            env.lbank,
          ]);
          await lock.methods.newLock.send([...extras], [],
            {
              fromWallet: from,
              programWallet: to,
              _locker: new PublicKey(address),
              _contract: new PublicKey(tokenB.ct),
            },
            name,
            new BN(returnbignumber(amountTo, tokenB.decimals)),
            new BN(end),
            new BN(props.type),
            new BN(ovesting),

            Array.from(
              alltoBN([_tgeDate, tgePercent, cycle, cycleReleasePercent])
            )
          )
          break;
        case "tron":
          const tokenContract = new TronContract(
            tokenB.ct,
            tokenB.ct,
            env.token.abi
          )
          await tokenContract.methods.approve.send(0, lock.contract, tokenB.supply)
          await lock.methods.newLock.send(0,
            address,
            tokenB.ct,
            name,
            returnbignumber(amountTo, tokenB.decimals),
            end,
            props.type,
            ovesting,

            [_tgeDate, tgePercent, cycle, cycleReleasePercent]
          )
          break;
        default:
          const bsctokenContract = new EVMContract(
            tokenB.ct,
            tokenB.ct,
            env.token.abi
          )
          await bsctokenContract.methods.approve.send(0, lock.contract, tokenB.supply)
          await lock.methods.newLock.send(0,
            address,
            tokenB.ct,
            name,
            returnbignumber(amountTo, tokenB.decimals),
            end,
            props.type,
            ovesting,

            [_tgeDate, tgePercent, cycle, cycleReleasePercent]
          )
          break;
      }

    } catch (error) {
      console.log(error);
    }
  }

  async function _gettoken(ct) {
    try {
      if (ct.toString().trim().length < 0) {
      } else {
        setTokenBCT(ct.toString().trim());
        const obj = await getToken(ct.toString().trim(), netid);

        obj.ct = ct.toString().trim();
        obj.allow = 10000000n;
        setTokenB(obj);
      }
    } catch (error) {
      //console.log(error)
    }
  }
  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} onClick={onClick} className="date-picker">
      <p style={{ margin: "auto" }}>
        {new Date(value).toUTCString().replace("GMT", "UTC")}
      </p>
    </button>
  ));

  function nextstep() {
    setstep(step + 1);
  }

  function stepCurrent() {
    return step;
  }

  return (
    <>

      <div className="text-title">Create {info} Lock</div>
      <SwichNetwork />
      <div className="group-create">
        <div className="time-line">
          <div className="group-step">
            <div
              className={
                stepCurrent() === 0 || stepCurrent() > 0
                  ? "step-actived"
                  : "step"
              }
            >
              <div className="step-number">1</div>
              <div className="step-title">
                Verify {info}
                <br /> <p>Enter the {info} address and verify</p>
              </div>
            </div>
            <div
              className={
                stepCurrent() === 1 || stepCurrent() > 1
                  ? "step-actived"
                  : "step"
              }
            >
              <div className="step-number">2</div>
              <div className="step-title">
                Lock info <br /> <p>Enter the locker information</p>
              </div>
            </div>
            <div
              className={
                stepCurrent() === 2 || stepCurrent() > 2
                  ? "step-actived"
                  : "step"
              }
            >
              <div className="step-number">3</div>
              <div className="step-title">
                Vesting <br /> <p>Enter the vesting owner information</p>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          {stepCurrent() === 0 && (
            /*-step-1------------------------------------------------------------------------------*/
            <>
              <div className="title-form">
                <div className="title">Verify {info} </div>
                <p>Enter the {info} address and verify</p>
              </div>
              <div className="title-step">Do you want to create Token lock</div>
              <p className="sub-title-step">
                Going through the onboarding process is not necessary
              </p>
              <hr style={{ margin: "20px 0" }} />
              <div className="form">
                <div className="row">
                  <div className="col col-12">
                    <InputBlock
                      title={info + " Address *"}
                      place={"Ex. 3ic31ZwDAvL9vg8b4er22TDLaDyLakEv107CJNTsyXhW"}
                      variavel={tokenBCT}
                      setvariavel={_gettoken}
                      desc={""}
                    />
                  </div>
                </div>
              </div>

              <div className="group-buttons">
                <Button
                  func={() => {
                    nextstep();
                  }}
                  text={"Next"}
                  next={true}
                  className="next"
                />
              </div>
            </>
          )}
          {stepCurrent() === 1 && (
            /*-step-1------------------------------------------------------------------------------*/
            <>
              <div className="title-form">
                <div className="title">DeFi Launchpad</div>
                <p>Enter the locker information</p>
              </div>
              <div className="title-step">Create Your Locks for Free</div>
              <hr style={{ margin: "20px 0" }} />
              <div className="row">
                <div className="col col-6">
                  <SAFE
                    what={"Name"}
                    result={tokenB.name ? tokenB.name : "-"}
                  />
                </div>
                <div className="col col-6">
                  <SAFE
                    what={"Symbol"}
                    result={tokenB.symbol ? tokenB.symbol : "-"}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col col-6">
                  <SAFE
                    what={"Decimals"}
                    result={tokenB.decimals ? tokenB.decimals : "-"}
                  />
                </div>
                <div className="col col-6">
                  <SAFE
                    what={"Supply"}
                    result={nextblock(tokenB.supply, tokenB.decimals)}
                  />
                </div>
              </div>

              <div className="form">
                <div className="groupCreatedDisplayFlex">
                  <div className="groupCreated">
                    <InputBlock
                      title={"Title"}
                      place={""}
                      variavel={name}
                      setvariavel={setName}
                      desc={""}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col col-6">
                    <InputBlock
                      title={"Amount"}
                      place={"0"}
                      variavel={amountTo}
                      setvariavel={setAmountTo}
                      desc={""}
                    />
                  </div>

                  <div className="col col-6">
                    <div className="title-input">End time</div>
                    <DatePicker
                      selected={endTime}
                      onChange={(date) => toUTC(setendTime, date)}
                      touchUi={true}
                      showTimeSelect
                      timeFormat="HH:mm aa"
                      timeIntervals={5}
                      timeCaption="time"
                      dateFormat="MMMM d, yyyy h:mm aa"
                      customInput={<CustomInput />}
                    />
                  </div>
                </div>
              </div>

              <div className="group-buttons">
                <Button
                  func={() => {
                    setstep(step - 1);
                  }}
                  text={"Back"}
                  next={false}
                  className="default"
                />
                <Button
                  func={() => {
                    nextstep();
                  }}
                  text={"Next"}
                  next={true}
                  className="next"
                />
              </div>
            </>
          )}
          {stepCurrent() === 2 && (
            /*-step-1------------------------------------------------------------------------------*/
            <>
              <div className="title-form">
                <div className="title">Vesting</div>
                <p>Enter the vesting owner information</p>
              </div>
              <div className="form">
                <div className="title-input" style={{ marginLeft: "10px" }}>
                  User Vesting
                </div>
                <div className="row">
                  <div className="col col-3">
                    <Check
                      title={"Disable"}
                      id={0}
                      variavel={ovesting}
                      setvariavel={setovesting}
                    />
                  </div>
                  <div className="col col-3">
                    <Check
                      title={"Enable"}
                      id={1}
                      variavel={ovesting}
                      setvariavel={setovesting}
                    />
                  </div>
                </div>
                {
                  ovesting == 1 && <>
                    <hr style={{ margin: "20px 0" }} />
                    <div className="row">
                      <div className="col col-6">
                        <div className="title-input">TGE Date (UTC Time)</div>
                        <DatePicker
                          selected={tgeDate}
                          onChange={(date) => toUTC(setTgeDate, date)}
                          touchUi={true}
                          showTimeSelect
                          timeFormat="HH:mm aa"
                          timeIntervals={5}
                          timeCaption="time"
                          dateFormat="MMMM d, yyyy h:mm aa"
                          customInput={<CustomInput time={tgeDate} />}
                        />
                      </div>
                      <div className="col col-6">
                        <InputBlock
                          title={"TGE Percent"}
                          place={"0"}
                          variavel={tgePercent}
                          setvariavel={setTgePercent}
                          desc={""}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col col-6">
                        <InputBlock
                          title={"Cycle (minutes)"}
                          place={"0"}
                          variavel={cycle}
                          setvariavel={setCycle}
                          desc={""}
                        />
                      </div>
                      <div className="col col-6">
                        <InputBlock
                          title={"Cycle Release Percent"}
                          place={"0"}
                          variavel={cycleReleasePercent}
                          setvariavel={setCycleReleasePercent}
                          desc={""}
                        />
                      </div>
                    </div>
                  </>
                }

              </div>

              <div className="group-buttons">
                <Button
                  func={() => {
                    setstep(step - 1);
                  }}
                  text={"Back"}
                  next={false}
                  className="default"
                />
                <Button
                  func={() => {
                    create();
                  }}
                  text={"Create"}
                  next={true}
                  className="next"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default CreateLock;
