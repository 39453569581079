import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { useNavigate } from "react-router-dom";
import { nextblock } from "../../../programs/utils";
const CardTokenLock = (props) => {
  const navigate = useNavigate();
  const [symbola, setsymbolA] = useState(props.data.symbol);
  const [deca, setdecA] = useState(props.data.decimals);
  const [owner, setowner] = useState(props.data.owner);
  const [time, settime] = useState(props.data.endtime);
  const [amount, setamount] = useState(props.data.amount);

  //console.log(time)
  function getTimeRemaining(endtime) {
    const total = Date.parse(endtime) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));
    if (total < 0) {
      return "EXPIRED";
    } else {
      return days + "D " + hours + "H " + minutes + "M " + seconds + "S";
    }
  }
  return (
    <div
      className="card-contract card-hover"
      onClick={() => {
        navigate(`${props.uri}/data/?=` + props.data.idx);
      }}
    >
      <div className="group-name-contract">
        <img src={props.data.icon} alt={symbola} loading="lazy" />
        <div className="contract-name">{symbola}</div>
      </div>
      <div className="group-values-locker">
        <p className="group-values-locker__title">Owner</p>
        <p className="group-values-locker__value">
          {owner.slice(0, 4) + "..." + owner.slice(-4)}
        </p>
      </div>
      <div className="group-values-locker">
        <p className="group-values-locker__title">Locked Amount</p>
        <p className="group-values-locker__value">{nextblock(amount, deca)}</p>
      </div>
      <div className="group-values-locker">
        <p className="group-values-locker__title">Finish in</p>
        <p
          className={`group-values-locker__value ${
            getTimeRemaining(new Date(parseInt(time) * 1000)) === "EXPIRED"
              ? "expired"
              : ""
          }`}
        >
          {getTimeRemaining(new Date(parseInt(time) * 1000))}
        </p>
      </div>
    </div>
  );
};
export default CardTokenLock;
