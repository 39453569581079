import React from "react";
import ProjectData from "../../config";

const { inputsColor, inputsBorders, innerBFont, outFont } = ProjectData;

function CheckList(props) {
  return (
    <div className="group-input">
      <div className="title-input">{props.title}</div>
      <select
        onChange={(e) => {
          props.setvariavel(e.target.value);
        }}
      >
        <font></font>
        {props.ops.map((e, i) => (
          <option>{e}</option>
        ))}
      </select>
    </div>
  );
}

export default CheckList;
