import React, { useState } from "react";
import IconAllSales from "../../assets/icons/AllSales.tsx";
import IconMyContributions from "../../assets/icons/MyContributions.tsx";
import IconMyCreatedPresale from "../../assets/icons/MyCreatedPresale.tsx";
import IconMyFavorites from "../../assets/icons/MyFavorites.tsx";
import IconMyAlarmes from "../../assets/icons/MyAlarms.tsx";

function FilterSales(props) {
  const [activedAllSales, setActivedAllSales] = useState(true);
  const [activedMyContributions, setActivedMyContributions] = useState(false);
  const [activedMyFavorites, setActivedMyFavorites] = useState(false);
  const [activedMyAlarms, setActivedMyAlarms] = useState(false);
  const [activedMyCreatedPresale, setActivedMyCreatedPresale] = useState(false);

  const setActivedClick = (index) => {
    switch (index) {
      case 0:
        setActivedAllSales(!activedAllSales);
        setActivedMyContributions(false);
        setActivedMyFavorites(false);
        setActivedMyAlarms(false);
        setActivedMyCreatedPresale(false);
        break;
      case 1:
        setActivedAllSales(false);
        setActivedMyContributions(!activedMyContributions);
        setActivedMyFavorites(false);
        setActivedMyAlarms(false);
        setActivedMyCreatedPresale(false);
        break;
      case 2:
        setActivedAllSales(false);
        setActivedMyContributions(false);
        setActivedMyFavorites(!activedMyFavorites);
        setActivedMyAlarms(false);
        setActivedMyCreatedPresale(false);
        break;
      case 3:
        setActivedAllSales(false);
        setActivedMyContributions(false);
        setActivedMyFavorites(false);
        setActivedMyAlarms(!activedMyAlarms);
        setActivedMyCreatedPresale(false);
        break;
      case 4:
        setActivedAllSales(false);
        setActivedMyContributions(false);
        setActivedMyFavorites(false);
        setActivedMyAlarms(false);
        setActivedMyCreatedPresale(!activedMyCreatedPresale);
        break;
      default:
        break;
    }
  };

  return (
    <div className="filter-tab">
      <div
        className="item-tab"
        onClick={() => {
          props.setlistof(0);
          setActivedClick(0);
        }}
      >
        <IconAllSales color={activedAllSales ? "#e2e2e2" : "#8d8d8d"} />
        <div className={activedAllSales ? "actived" : "name-tab"}>
          All Presales
        </div>
      </div>
      <div
        className="item-tab"
        onClick={() => {
          props.setlistof(1);
          setActivedClick(1);
        }}
      >
        <IconMyContributions
          color={activedMyContributions ? "#e2e2e2" : "#8d8d8d"}
        />
        <div className={activedMyContributions ? "actived" : "name-tab"}>
          My Contributions
        </div>
      </div>
      <div
        className="item-tab"
        onClick={() => {
          props.setlistof(2);
          setActivedClick(2);
        }}
      >
        <IconMyFavorites color={activedMyFavorites ? "#e2e2e2" : "#8d8d8d"} />
        <div className={activedMyFavorites ? "actived" : "name-tab"}>
          My Favorites
        </div>
      </div>
      <div
        className="item-tab"
        onClick={() => {
          props.setlistof(3);
          setActivedClick(3);
        }}
      >
        <IconMyAlarmes color={activedMyAlarms ? "#e2e2e2" : "#8d8d8d"} />
        <div className={activedMyAlarms ? "actived" : "name-tab"}>
          My Alarms
        </div>
      </div>
      <div
        className="item-tab"
        onClick={() => {
          props.setlistof(4);
          setActivedClick(4);
        }}
      >
        <IconMyCreatedPresale
          color={activedMyCreatedPresale ? "#e2e2e2" : "#8d8d8d"}
        />
        <div className={activedMyCreatedPresale ? "actived" : "name-tab"}>
          My Created Pools
        </div>
      </div>
    </div>
  );
}
export default FilterSales;
