import { useNavigate } from "react-router-dom";
import { nextblock, programNetwork } from "../programs/utils";
import assets from "../assets";
import Api from "../programs/api";

const LatestModal = (props) => {
    function removeSpacesAndUnderscores(str) {
        return str.replace(/[\s_]/g, "");
    }

    const token = [
        " sol", //
        " usdc", //usdc
        " usdt", //usdt
    ];
    function setLauchpad(url) {
        let st = "";
        switch (url) {
            case "/launchpads/pre_sale/":
                st = "Presale";
                break;
            case "/private_sale/":
                st = "Private Sale";
                break;
            case "/launchpads/fair_launch/":
                st = "Fair Launch";
                break;
            default:
                break;
        }
        return st;
    }

    function typeToUrl(input) {
        if (input == "Presale") { return "/presale" }
        if (input == "Private Sale") { return "/privatesale" }
        if (input == "Fair Launch") { return "/fairlaunch" }
        return "/"
    }

    const navigate = useNavigate();

    const cap = props.element.hardcap == undefined ? props.element.softcap : props.element.hardcap
    return (
        <a
            href={"/" + programNetwork(props.element.program).name + typeToUrl(setLauchpad(props.element.url)) + "/" + props.element.cts}
            style={{ cursor: "pointer" }}
            onClick={async () => {
                try {
                    await Api
                        .use("https://solpad-analytics-api.azurewebsites.net/api/")
                        .event.log.click.post({
                            cts: props.element.cts
                        })
                } catch (error) {

                }

            }}
            className="latest-pools-items"
        >
            <div className="latest-pools-items-group">
                <div className="latest-pools-items-group__image">
                    <img src={props.element.logoURL} alt="" loading="lazy" />
                </div>
                <div className="latest-pools-items-group__name">
                    {props.element.tokenname} <br />
                    <p>
                        {" "}
                        {nextblock(
                            cap,
                            props.element.quoteDecimals
                        )}{" "}
                        {programNetwork(props.element.program).symbol} of {props.element.hardcap == undefined ? "softcap" : "hardcap"}
                    </p>
                </div>
            </div>
            <div className="latest-pools-group-item__stake">
                <img src={`${programNetwork(props.element.program).icon}`} loading="lazy" alt="" />
            </div>
        </a>
    );
};
export default LatestModal