import React, { useState } from "react";
import DateInput from "../../../../../components/Timer/dateInput";
import { BN } from "@coral-xyz/anchor";
import Button from "../../../../../components/Launchpad/Button";
import { programNetwork } from "../../../../../programs/utils";

const DelayHub = ({
    object,
    nSendTX,
    load
}) => {

    const [delayStart, setDelayStart] = useState(new Date(object.startTime * 1000))
    const [delayEnd, setdelayEnd] = useState(new Date(object.endTime * 1000))
    function toTimestamp(date) {
        return parseInt(Date.parse(date) / 1000)
    }
    return (
        <>
            <div className="form">
                <div className="row">
                    <div className="col col-12">
                        <DateInput
                            title={"New start time"}
                            desc={""}
                            variavel={delayStart}
                            setvariavel={(date) => {
                                if (toTimestamp(date) > parseInt(object.startTime))
                                    setDelayStart(date)

                            }}
                        />
                    </div>
                    <div className="col col-12">
                        <Button
                            func={() => {
                                nSendTX(
                                    "extendstart",
                                    "0",
                                    0,
                                    programNetwork(object.program).name == "solana" ? new BN(toTimestamp(delayStart)) : toTimestamp(delayStart)
                                )
                                    .then((a) => {
                                        load();
                                    })
                                    .catch((e) => {
                                        //  //console.log(e);
                                    });
                            }}
                            text={"Delay start"}
                            className="simple-button"
                        />
                    </div>
                    <div className="col col-12">

                        <DateInput
                            title={"New Finish time"}
                            desc={""}
                            variavel={delayEnd}
                            setvariavel={(date) => {
                                if (toTimestamp(date) > parseInt(object.endTime))
                                    setdelayEnd(date)

                            }}
                        />
                    </div>
                    <div className="col col-12">
                        <Button
                            func={() => {
                                nSendTX(
                                    "extendend",
                                    "0",
                                    0,
                                    programNetwork(object.program).name == "solana" ? new BN(toTimestamp(delayEnd)) :toTimestamp(delayEnd) 
                                )
                                    .then((a) => {
                                        load();
                                    })
                                    .catch((e) => {
                                        //  //console.log(e);
                                    });
                            }}
                            text={"Delay Finish"}
                            className="simple-button"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
export default DelayHub