import React from "react";

const SAFE = (props) => {
  return (
    <div className="group-safe">
      <div className="title-safe">{props.what} </div>
      <div
        className={`value-safe ${props.result === "EXPIRED" ? "expired" : ""}`}
      >
        {" "}
        {props.result}{" "}
      </div>
    </div>
  );
};

export default SAFE;
