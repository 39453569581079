import React, { useState } from "react";
import { MenuItems } from "./MenuItems";
import "./Dropdown.scss";
import { Link } from "react-router-dom";

function Dropdown({ i }) {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const handleRemoveActiveClass = () => {
    const activeNavMenu = document.querySelector(".active-nav-menu");
    if (activeNavMenu) {
      activeNavMenu.classList.remove("active-nav-menu");
      setClick(false);

      handleToggleIcon();
    }
  };

  const handleToggleIcon = () => {
    const timesIcon = document.querySelector(".fas.fa-times");
    if (timesIcon) {
      timesIcon.classList.remove("fas", "fa-times");
      timesIcon.classList.add("fas", "fa-bars");
    }
  };

  return (
    <>
      <ul
        onClick={handleClick}
        className={click ? "dropdown-menu clicked" : "dropdown-menu"}
      >
        {MenuItems.filter((item) => item.index === i).map((item, index) => {
          return (<>
            {item.sub != undefined
              ? <li key={index}>
                <div className={item.cName}>
                  {item.title}
                </div>
                <div style={{ display: "grid", gridTemplateColumns:"50% 50%", gap: "3px" }}>

                  {item.sub.map((sub) =>
                    <div
                      style={{ cursor:"pointer", display: "flex", gap: "3px", color: "#49aec0",  }}
                    >
                      <Link
                        className={sub.cName}
                        to={sub.path}
                        onClick={handleRemoveActiveClass}
                      >
                        {sub.title}
                      </Link>
                      {/* <i className="fas fa-caret-right" style={{margin:"auto"}} /> */}
                    </div>

                  )}
                </div>
                {/* <Link
                      className={sub.cName}
                      to={sub.path}
                      onClick={handleRemoveActiveClass}
                    >
                
                    </Link> */}

              </li>
              :
              <li key={index}>
                <Link
                  className={item.cName}
                  to={item.path}
                  onClick={handleRemoveActiveClass}
                >
                  {item.title}
                </Link>




              </li>
            }
          </>

          );
        })}
      </ul>
    </>
  );
}

export default Dropdown;
