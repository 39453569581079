import React from "react";

function IconMyFavorites(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M15.2969 2.75C13.5223 2.75 11.9685 3.51312 11 4.80305C10.0315 3.51312 8.47773 2.75 6.70312 2.75C5.29051 2.75159 3.9362 3.31346 2.93733 4.31233C1.93846 5.3112 1.37659 6.66551 1.375 8.07812C1.375 14.0938 10.2945 18.963 10.6743 19.1641C10.7744 19.2179 10.8863 19.2461 11 19.2461C11.1137 19.2461 11.2256 19.2179 11.3257 19.1641C11.7055 18.963 20.625 14.0938 20.625 8.07812C20.6234 6.66551 20.0615 5.3112 19.0627 4.31233C18.0638 3.31346 16.7095 2.75159 15.2969 2.75ZM11 17.7719C9.43078 16.8575 2.75 12.6921 2.75 8.07812C2.75136 7.03011 3.16829 6.02541 3.90935 5.28435C4.65041 4.54329 5.65511 4.12636 6.70312 4.125C8.37461 4.125 9.77797 5.01531 10.3641 6.44531C10.4159 6.57141 10.504 6.67926 10.6172 6.75516C10.7304 6.83106 10.8637 6.87159 11 6.87159C11.1363 6.87159 11.2696 6.83106 11.3828 6.75516C11.496 6.67926 11.5841 6.57141 11.6359 6.44531C12.222 5.01273 13.6254 4.125 15.2969 4.125C16.3449 4.12636 17.3496 4.54329 18.0907 5.28435C18.8317 6.02541 19.2486 7.03011 19.25 8.07812C19.25 12.6852 12.5675 16.8566 11 17.7719Z"
        fill={props.color}
      />
    </svg>
  );
}

export default IconMyFavorites;
