import React from "react";
import CheckList from "./ListCheck";
import InputBlock from "./InputBlock";
function FilterSale(props) {
  const {
    setSearchValue,
    setstatusFilter,
    setFilter,
    setNetworkFilter,
    setPoolTypeFilter,
  } = props.opts;
  const { showSearcFilter, title, showComboFilter } = props.filter;
  return (
    <div>
      {showSearcFilter > 0 && (
        <InputBlock
          title={title}
          place={"Enter the token name or token symbol"}
          variavel={undefined}
          setvariavel={setSearchValue}
          desc={""}
        />
      )}

      {showComboFilter > 0 && (
        <div className="group-filters">
          <CheckList
            title={"Chain"}
            untitle={"All"}
            ops={[
              "All",
              "Solana",
              "Tron"
            ]}
            setvariavel={(e) => {
              setNetworkFilter(e);
            }}
          />
          <CheckList
            title={"Filter By"}
            untitle={"No filter"}
            ops={[
              "Coming Soon",
              "Live Sales",
              "Finalized",
              "Ended",
              "Canceled",
              "Claim Allowed",
            ]}
            setvariavel={(e) => {
              setstatusFilter(e);
            }}
          />
          <CheckList
            title={"Order By"}
            untitle={"No filter"}
            ops={[
              "Soft Cap",
              "Hard Cap",
              "LP percent",
              "Start Time",
              "End Time",
            ]}
            setvariavel={(e) => {
              setFilter(e);
            }}
          />
        </div>
      )}
    </div>
  );
}

export default FilterSale;
