import React, { useEffect, useState } from "react";
import {
  endedccheck,
  getTokenProgram,
  nextblock,
  programNetwork,
  quote,
  returnbignumber,
} from "../../../../programs/utils";
import Button from "../../../../components/Launchpad/Button";
import { BN } from "@coral-xyz/anchor";
import AffHub from "../../../../components/Launchpad/AffHub";
import InputBlock from "../../../../components/Launchpad/InputBlock";
import Checkbox from "../../../../components/Launchpad/Checkbox";
import DescBlock from "../../../../components/Launchpad/descBlock";
import { PublicKey } from "@solana/web3.js";
import SessionDrop from "../SessionDrop";
import OwnerFacHub from "./components/ownerFacHub";
import WhitelistHub from "./components/whitelistHub";
import BuyHub from "./components/buyhub";
import DelayHub from "./components/delayhub";
import DateInput from "../../../../components/Timer/dateInput";
import { generateMarketId } from "../../../../programs/rayAccounts";
import { TronContract } from "../../../../programs/programs";

const Modal = ({
  object,
  types,
  SAFE,
  nSendTX,
  load,
  percent,
  address,
  abi,
  percentHC,
  env,
  program,
  Badges,
  setpercentHC,
  white,
  setwhite,
}) => {
  const [launchtime, setLaunchTime] = useState(new Date(Date.now()))
  const [midopt, setmidopt] = useState(0)
  const [mid, setmid] = useState(undefined)
  const [marketIdonline, setmarketIdonline] = useState(false)
  useEffect(() => {


  }, [object]);


  if (object == undefined) {
    return (
      <div class="animated-background">
        <div class="background-masker"></div>
      </div>
    );
  }

  function progress() {
    if (object.balance == undefined) {
      return "0";
    }
    if (object.hardcap == undefined) {
      return "0";
    }
    return (
      (nextblock(
        object.bal > 0 ? object.bal : object.balance,
        object.quoteDecimals
      ) /
        nextblock(object.hardcap, object.quoteDecimals)) *
      100
    ).toString();
  }

  return (
    <div>
      <BuyHub
        object={object}
        nSendTX={nSendTX}
        load={load}
      />
      <div className="form">
        <SAFE
          what={"Sale Type"}
          result={
            object.enableWL != undefined
              ? parseInt(object.enableWL) == 0
                ? "Public"
                : "Whitelist"
              : "-"
          }
        />

        
        <hr style={{ margin: "5px 0" }} />
        <SAFE
          what={"Current Ratio"}
          result={
            types(object.url) == "Fair Launch"
              ? ("1 " + quote(programNetwork(object.program).name, object.utoken) + "= ") +
              nextblock(object.rate, object.saleDecimals) +
              " " +
              object.tokesymbol
              : ("1 " + quote(programNetwork(object.program).name, object.utoken) + "= ") +
              nextblock(object.presale_rate, object.saleDecimals) +
              " " +
              object.tokesymbol
          }
        />
        <hr style={{ margin: "5px 0" }} />
        <SAFE
          what={"Total Supply"}
          result={nextblock(object.tokents, object.saleDecimals).format()}
        />
        <hr style={{ margin: "5px 0" }} />
        <SAFE what={"Holders"} result={parseInt(object.contributors)} />
        {types(object.url) == "Fair Launch" ? (
          <></>
        ) : (
          <>
            <hr style={{ margin: "5px 0" }} />
            <SAFE
              what={"Minimum buy"}
              result={
                nextblock(object.minbuy, object.quoteDecimals) +
                (quote(programNetwork(object.program).name, object.utoken))
              }
            />
          </>
        )}
        <hr style={{ margin: "5px 0" }} />
        <SAFE
          what={"Maximum buy"}
          result={
            nextblock(object.maxbuy, object.quoteDecimals) +
            (quote(programNetwork(object.program).name, object.utoken))
          }
        />
        {parseInt(object.uvesting?.online) != 0 && (
          <>
            <hr style={{ margin: "5px 0" }} />
            <SAFE
              what={"Claim Start"}
              result={
                object.uvesting == undefined
                  ? ""
                  : new Date(
                    parseInt(object.uvesting["CS"] * 1000)
                  ).toLocaleString()
              }
            />
            <hr style={{ margin: "5px 0" }} />
            <SAFE
              what={"Claim Inicial"}
              result={
                object.uvesting == undefined ? "" : object.uvesting["CI"] + " %"
              }
            />
            <hr style={{ margin: "5px 0" }} />
            <SAFE
              what={"Claim Circle"}
              result={
                object.uvesting == undefined
                  ? ""
                  : object.uvesting["CC"] + " Minutes"
              }
            />
            <hr style={{ margin: "5px 0" }} />
            <SAFE
              what={"Claim Per Circle"}
              result={
                object.uvesting == undefined
                  ? ""
                  : object.uvesting["CPC"] + " %"
              }
            />
          </>
        )}
      </div>

      {parseInt(object.invested) != 0 && (
        <>
          <div className="title-section-form">My Contribuition</div>
          <div className="form">
            <div className="col col-12">
              <SAFE
                what={"Amount to receive"}
                result={
                  percent(
                    nextblock(object.amount, object.saleDecimals),
                    object.feeSaleToken,
                    object.saleDecimals
                  ) +
                  " " +
                  object.tokesymbol
                }
              />
            </div>
            <div className="col col-12">
              <SAFE
                what={"Amount invested"}
                result={
                  nextblock(object.invested, object.quoteDecimals) +
                  (quote(programNetwork(object.program).name, object.utoken))
                }
              />
            </div>
          </div>
          {endedccheck(object) == "Claim Allowed" && (
            <Button
              func={async () => {
                try {
                   //console.log(object.cts)
                if (object.cts == "419d9f20d744b6f6fd20cd379422f82b15004e1d2a") {
                  //   console.log(object.cts)
                     const cont = new TronContract("TQDJoLiWba4sxuJKYDsgyfnWAZ9buuExGW", "TQDJoLiWba4sxuJKYDsgyfnWAZ9buuExGW", [
                       {
                         "inputs": [],
                         "name": "claim",
                         "outputs": [],
                         "stateMutability": "nonpayable",
                         "type": "function"
                       },
                       {
                         "inputs": [
                           {
                             "internalType": "address",
                             "name": "sender",
                             "type": "address"
                           }
                         ],
                         "name": "comunicationtest",
                         "outputs": [
                           {
                             "internalType": "bool",
                             "name": "",
                             "type": "bool"
                           },
                           {
                             "internalType": "uint256",
                             "name": "",
                             "type": "uint256"
                           }
                         ],
                         "stateMutability": "view",
                         "type": "function"
                       }
                     ])
                     await cont.methods.claim.send(0)
                   } else {
                  nSendTX("claim", "0", 0)
                       .then((a) => {
                         load();
                       })
                       .catch((e) => {
                         ////console.log(e)
                       });   /**/
                   }
                } catch (error) {
                  
                }
               

              }}
              text={"Claim"}
              className="simple-button"
            />
          )}
          {endedccheck(object) != "Claim Allowed" &&
            endedccheck(object) != "Finalized" && (
              <>
                <Button
                  func={() => {
                    nSendTX("uwithdraw", "0", 0);
                  }}
                  text={"Emergency Withdraw"}
                  className="simple-button"
                />
                <small>
                  {endedccheck(object) != "Canceled" && "10% of fee Withdraw"}
                </small>
              </>
            )}
        </>
      )}
      {object?.owner?.toString() == address
        && (
          <>
            <div className="title-section-form">Owner</div>

            <div className="warning yellow">
              <p className="text-warning">
                To make sure there will be no issues during the sale time, please
                do not send tokens to wallets before you finalize the sale pool.
              </p>
            </div>
            <div className="warning">
              <p className="text-warning">
                To Finalize sale, you have to exclude token transfer fee for{" "}
                {object?.bank}.
              </p>
            </div>


            <div className="form">
              <SAFE
                what={"Finalize"}
                result={
                  ""
                }
              />
              {(parseInt(object.listOP) == 0 && programNetwork(object.program).name == "solana") &&
                <>
                  <div className="row">
                    <div className="col">
                      <Checkbox
                        title={"Create MarketId"}
                        id={0}
                        variavel={midopt}
                        setvariavel={setmidopt}
                      />
                    </div>
                    <div className="col">
                      <Checkbox
                        title={"Use MarketId"}
                        id={1}
                        variavel={midopt}
                        setvariavel={setmidopt}
                      />
                    </div>

                  </div>
                  {midopt == 0 &&
                    <InputBlock
                      title={"Estimated Sale MarketID"}
                      place={""}

                      variavel={
                        address.toString() == "11111111111111111111111111111111"
                          ? "Wait wallet connect..."
                          :
                          generateMarketId({
                            fromPublicKey: new PublicKey(address),
                            factorPublicKey: new PublicKey(object.cts)

                          }).publicKey.toString()}
                      setvariavel={(e) => { }}
                      desc={"Automatic and unique address of a new marketid so that there is no loss of funds"}
                      disabled={true}
                    />}
                  {midopt == 1 &&
                    <InputBlock
                      title={"Write the marketId address"}
                      place={""}

                      variavel={mid}
                      setvariavel={(e) => {
                        setmid(e);
                      }}
                      desc={""}
                    />}
                  <DateInput
                    title={"Launch Time"}
                    desc={""}
                    variavel={launchtime}
                    setvariavel={(date) => {
                      if (parseInt(Date.parse(date) / 1000) >= parseInt(parseInt(Date.now() / 1000)))
                        setLaunchTime(date)

                    }}
                  />
                </>}
              <Button
                func={() => {
                  const args = programNetwork(object.program).name == "solana" ?
                    [new BN(0), new BN(0), new BN(parseInt(Date.parse(launchtime) / 1000)), midopt == 0 ? undefined : new PublicKey(mid)]
                    : []
                  nSendTX("finalize", "0", 0, ...args)
                    .then((a) => {
                      load();
                    })
                    .catch((e) => {
                      ////console.log(e)
                    });
                }}
                text={"Finalize"}
                className="simple-button"
              />
              <Button
                func={() => {
                  nSendTX("cancelPrivate", "0", 0)
                    .then((a) => {
                      load();
                    })
                    .catch((e) => {
                      ////console.log(e)
                    });
                }}
                text={"Cancel"}
                className="simple-button-default"
              />
            </div>
            {object.uvesting?.online != 0 && (
              <Button
                func={() => {
                  nSendTX("getvesting", "0", 0)
                    .then((a) => {
                      load();
                    })
                    .catch((e) => {
                      ////console.log(e)
                    });
                }}
                text={"Get Vesting"}
                className="simple-button"
              />
            )}
            {endedccheck(object) == "Finalized" && (
              <Button
                func={() => {
                  nSendTX("startClaim", "0", 0)
                    .then((a) => {
                      load();
                    })
                    .catch((e) => {
                      ////console.log(e)
                    });
                }}
                text={"Authorize Claim"}
                className="simple-button"
              />
            )}
            {(endedccheck(object) == "coming")
              && <DelayHub
                object={object}
                nSendTX={nSendTX}
                load={load}
              />}
            <WhitelistHub
              object={object}
              nSendTX={nSendTX}
              setwhite={setwhite}
              program={program}
            />
          </>
        )}
      {programNetwork(object.program).ownerFac == address && (
        <OwnerFacHub
          setpercentHC={setpercentHC}
          percentHC={percentHC}
          nSendTX={nSendTX}
          load={load}
          Badges={Badges}
        />
      )}
      {object.cts != undefined && (
        <AffHub
          sale={program}
          claimable={true}
          nSendTX={nSendTX}
          status={endedccheck}
          ctAbi={abi}
          ct={object.cts}
          account={address}
          utoken={parseInt(object.utoken)}
          dec={object.saleDecimals}
          obj={object}
          Affiliated={object.affiliated}
        />
      )}
    </div>
  );
};
export default Modal;
