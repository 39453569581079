import React, { useState, useMemo } from "react";
import '@rainbow-me/rainbowkit/styles.css';
import {
    getDefaultWallets,
    RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { ethers } from 'ethers';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';

import { bsc, bscTestnet } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import {
    injectedWallet,
    rainbowWallet,
    walletConnectWallet,
    braveWallet,
    coinbaseWallet,
    metaMaskWallet,
    trustWallet
} from '@rainbow-me/rainbowkit/wallets';
import EvmConnection from './connection.js';
import { Emit } from "../../../components/Alert/Alert.js";
const EvmContent = ({ rpcs }) => {
    const [selected, setSelected] = useState(0);
    const [custom, setCustom] = useState(undefined);
    const [priority, setpriority] = useState(0);
    const { chains, publicClient } = configureChains(
        [bsc
            ,
            //  bscTestnet
        ],
        [
            publicProvider()
        ]
    );

    const connectors = connectorsForWallets([
        {
            groupName: 'Recommended',
            wallets: [
                trustWallet({
                    projectId: '3f529f8b097214c03d7190df245218c9',
                    chains
                }),
                metaMaskWallet({
                    projectId: '3f529f8b097214c03d7190df245218c9',
                    chains
                }),
                injectedWallet({ chains }),
                rainbowWallet({ projectId: '3f529f8b097214c03d7190df245218c9', chains }),
                walletConnectWallet({ projectId: '3f529f8b097214c03d7190df245218c9', chains }),
                braveWallet({
                    chains
                }),
                coinbaseWallet({
                    appName: "SUNPAD",
                    chains
                })
            ],
        },
    ]);

    const wagmiConfig = createConfig({
        autoConnect: true,
        connectors,
        publicClient
    })
    return (
        <WagmiConfig config={wagmiConfig}>
            <RainbowKitProvider chains={chains}>
                <EvmConnection
                    selected={selected}
                    rpcs={rpcs}
                    select={setSelected}
                    setPriority={setpriority}
                    setCustom={setCustom}
                    custom={custom}
                />
            </RainbowKitProvider>
        </WagmiConfig>
    );

};
export default EvmContent