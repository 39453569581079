import env from "../hooks/data/Config.js";
import {
  Keypair,
  SystemProgram,
  Transaction,
  PublicKey,
  SYSVAR_CLOCK_PUBKEY,
  ComputeBudgetProgram,
  Connection,
  clusterApiUrl,
  VersionedTransaction,
} from "@solana/web3.js";
import {
  signTransactions,
  sendSignedTransaction,
  xsendSignedTransaction,
  wsendSignedTransaction,
} from "../hooks/utils/send.tsx";
//import { walletAdapterIdentity } from "@metaplex-foundation/umi-signer-wallet-adapters";
import {
  createApproveInstruction,
  createAssociatedTokenAccountInstruction,
  getAssociatedTokenAddress,
  getAccount,
  TOKEN_PROGRAM_ID,
  MINT_SIZE,
  getMinimumBalanceForRentExemptMint,
  createInitializeMintInstruction,
  createMintToInstruction,
  TOKEN_2022_PROGRAM_ID,
  createBurnInstruction,
} from "@solana/spl-token";
import { Emit } from "../components/Alert/Alert.js";
import { BN, Program } from "@coral-xyz/anchor";
import {
  getTokenAddress,
  decodeView,
  approve,
  testAccountsToken,
  writeAccounts,
  getTokenProgram,
  balanceOf,
  nextblock,
} from "./utils.js";
import fairabi from "../abis/fairSale/fairSale.json";
import lockabi from "../abis/lock/lock.json";
import privabi from "../abis/Private/privateAbi.json";
import preabi from "../abis/PreSale/PreSale.json";
import preabiv2 from "../abis/PreSale/PreSalev2.json";
import factoryabi from "../abis/factory.json";
import { deserialize, Schema } from "borsh";
import fixedCache from "./fixedCache.json";
import { array } from "@raydium-io/raydium-sdk";
import Api from "./api.js"
import { TronWeb } from "tronweb";
import { getAccount as getAccountEther, prepareWriteContract, waitForTransaction, writeContract, getNetwork } from 'wagmi/actions'
import { useAccount } from 'wagmi';
import { BrowserProvider, JsonRpcProvider, ethers } from "ethers";
export const tronWeb = new TronWeb(
  'https://api.trongrid.io',
  'https://api.trongrid.io',
  'https://api.trongrid.io'
)
const TokenApi = Api.use("https://solpad-nodeapi.azurewebsites.net/")



Object.defineProperty(String.prototype, "capitalize", {
  value: function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false,
});
function getAccounts(_accounts, defaultAccounts, abi, func) {
  const { publicKey } = window.solpadContext
  let defaults = writeAccounts(defaultAccounts, abi, func)

  return {
    ...defaults,
    sender: publicKey,
    ..._accounts
  }
}
class WriteTx {
  constructor(transaction) {
    // Constructor
    this.tx = transaction;
  }
  async send(...signers) {
    const {

      publicKey,
      sendTransaction,
      connection,
      wallet
    } = window.solpadContext;
    const {
      context: { slot: minContextSlot },
      value: { blockhash, lastValidBlockHeight }
    } = await connection.getLatestBlockhashAndContext();
    this.tx.recentBlockhash = blockhash
    this.tx.feePayer = publicKey
    //this.tx.recentBlockhash = (await connection.getLatestBlockhash()).blockhash
    //  this.tx.feePayer = publicKey
    /*const build = {
      transaction: this.tx,
    };*/
    if (signers.length > 0) {
      // build.signers = [...signers];
      this.tx.partialSign(...signers)
    }

    /* const signedTransactions = await signTransactions({
       transactionsAndSigners: [build],
       wallet,
       connection,
     });
     for (let signedTransaction of signedTransactions) {
       // ////console.log(signedTransaction)
       await sendSignedTransaction({
         signedTransaction,
         connection,
       });
     }*/
    //console.log(sendTransaction)
    const signature = await sendTransaction(this.tx, connection,
      {

        skipPreflight: false,
        // maxRetries: 50

      });
    console.log(signature)
    await connection.confirmTransaction({ blockhash, lastValidBlockHeight, signature });
    return signature
  }
}
export class Factory {
  #acc;
  constructor(contract, storage) {
    // Constructor
    const { provider, publicKey } = window.solpadContext;
    this.contract = contract;
    this.#acc = Keypair.generate();
    this.storage = storage;
    this.abi = factoryabi;
    this.program = new Program(this.abi, new PublicKey(contract), provider);

    this.defaultAccounts = {
      dataAccount: storage,
      sender: publicKey,
      tokenProgram: TOKEN_PROGRAM_ID,
      systemProgram: SystemProgram.programId,
      clock: SYSVAR_CLOCK_PUBKEY,
      bank: env.bank,
    };
  }


  updateMetadata = async (add, data) => {
    const {
      LogoUrl,
      Website,
      Facebook,
      Twitter,
      Github,
      Telegram,
      Discord,
      Instagram,
      Reddit,
      YoutubeVideo,
      Description,
    } = data;
    return {
      auth: JSON.stringify(add.pda.secretKey),
      editpad: {
        key: add.metadatapda,
        description: Description,
        urls: {
          image: LogoUrl,
          website: Website,
          github: Github,
          facebook: Facebook,
          twitter: Twitter,
          instagram: Instagram,
          telegram: Telegram,
          discord: Discord,
          reddit: Reddit,
          banner: YoutubeVideo,
        },
      },
    };

  };

  async _updateMetadata(add, data) {
    const calls = [];

    const keys = Object.keys(data);
    for (let index = 0; index < keys.length; index++) {
      calls.push(await this.send("set" + keys[index], add, data[keys[index]]));
    }
    return calls;
  }

  async getMetadata(add, data) {
    const calls = {};

    const keys = Object.keys(data);
    for (let index = 0; index < keys.length; index++) {
      calls[keys[index].capitalize()] = await this.call(keys[index], add);
    }
    return calls;
  }

}

export class SplBuilder {
  #payer;
  #token;
  constructor() {
    // Constructor
    const { provider, publicKey } = window.solpadContext;
    this.#payer = Keypair.generate();
    this.#token = Keypair.generate();
  }

  asyncwrite = async (tokeninfo, isMutable, tokenStandart) => {
    const alert = Emit("Building SplToken...", "", "load");
    const { publicKey, wallet, sendTransaction, connection } =
      window.solpadContext;
    const mintKeypair = this.#token;
    //const toMetadata = new Blob([JSON.stringify(tokeninfo)]).size;
    try {

      const result = await TokenApi.token.spl.post({
        signedTransaction: undefined, //signedTransactions[0].serialize({ requireAllSignatures: false }),
        metadata: new Uint8Array(this.#payer.secretKey),
        owner: publicKey,
        mintKeypair: mintKeypair.publicKey,
        tokenKeypair: new Uint8Array(this.#token.secretKey),
        tokeninfo,
        isMutable,
        tokenStandart,
      })

      alert.update("Send To Blockchain", "", "load");
      await new WriteTx(
        new Transaction().add(
          Transaction.from(result.trx.data)
        )
      ).send(mintKeypair);

      alert.update("Success", "New Token Address: " + mintKeypair.publicKey.toString(), "success");
      window.open(
        "https://solscan.io/token/" + mintKeypair.publicKey.toString()
      );
      return mintKeypair.publicKey.toString();
    } catch (error) {
      //console.log(error)
      try {
        alert.update("Error on build splToken", error.message, "error");
      } catch (e) {
        alert.update("Error on build splToken", JSON.stringify(error), "error");
      }

    }
  };
  asyncedit = async (mint, tokeninfo, isMutable) => {
    const alert = Emit("Work on SplToken...", "", "load");
    const { publicKey } = window.solpadContext;

    const mintKeypair = new PublicKey(mint);
    // //console.log("size:" + new Blob([JSON.stringify(tokeninfo)]).size)
    const toMetadata = new Blob([JSON.stringify(tokeninfo)]).size;
    // //console.log("need:" + parseInt((toMetadata * 150000) + 900000))
    try {
      const result = await TokenApi.token.edit.post({
        owner: publicKey,
        mint: mintKeypair,
        tokeninfo,
        isMutable,
      })

      await new WriteTx(
        new Transaction().add(
          Transaction.from(result.trx.data)
        )
      ).send();

      alert.update("Success", "New metadata online", "success");
      window.open("https://solscan.io/token/" + mint);
      return mint;
    } catch (error) {
      try {
        alert.update("Error on edit splToken", error.message, "error");
      } catch (e) {
        alert.update("Error on edit splToken", JSON.stringify(error), "error");
      }
    }
  };
  asyncburn = async (mint, amount) => {
    const alert = Emit("Burning SplToken...", "", "load");
    const { publicKey } = window.solpadContext;

    const mintKeypair = new PublicKey(mint);

    try {

      const ATA = await getTokenAddress(mintKeypair, publicKey);
      await new WriteTx(
        new Transaction().add(
          createBurnInstruction(
            ATA,
            new PublicKey(mint),
            publicKey,
            amount,
            undefined,
            await getTokenProgram(mint)
          )
        )
      ).send();

      alert.update("Success", "", "success")
      //window.open("https://solscan.io/token/" + mint)
      return "mint";
    } catch (error) {
      try {
        alert.update("Error on burn splToken", error.message, "error");
      } catch (e) {
        alert.update("Error on burn splToken", JSON.stringify(error), "error");
      }
    }
  };
}

class v1 {
  #acc;
  constructor(contract, storage) {
    // Constructor
    const { provider, publicKey } = window.solpadContext;
    this.contract = contract;
    this.#acc = Keypair.generate();
    this.cache = {};
    this.storage = storage;
    this.abi = preabi;
    this.bank = env.pbank;
    this.program = new Program(this.abi, new PublicKey(contract), provider);
    this.defaultAccounts = {
      dataAccount: storage,
      sender: publicKey,
      bank: new PublicKey(env.pbank),
      tokenProgram: TOKEN_PROGRAM_ID,
      systemProgram: SystemProgram.programId,
      clock: SYSVAR_CLOCK_PUBKEY,
      of: new PublicKey(env.ownerFAC),
    };
  }
  newAcc() {
    const acc = this.#acc;

    this.storage = acc.publicKey.toBase58();
    this.defaultAccounts.dataAccount = acc.publicKey;

    return acc.publicKey.toBase58();
  }
  getAcc() {
    const acc = this.#acc;
    return acc.publicKey;
  }
  create = async (basics, instructions, editpad) => {
    Emit("Searching for block", "", "load");
    const acc = this.#acc;
    const { publicKey, connection, sendTransaction, wallet } =
      window.solpadContext;

    try {
      const tx1 = new Transaction();

      tx1.add(
        ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 10000 }),
        SystemProgram.createAccount({
          fromPubkey: publicKey,
          newAccountPubkey: acc.publicKey,
          lamports: 100000000,
          space: 10000,
          programId: new PublicKey(this.contract),
        }),
        ...basics,
        ...instructions
      );
      tx1.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;

      tx1.feePayer = publicKey;
      tx1.partialSign(acc);

      const tx2 = new Transaction().add(
        editpad[0],
        editpad[1],
        editpad[2],
        editpad[3],
        editpad[4],
        editpad[5]
      );
      tx1.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;

      tx1.feePayer = publicKey;
      const tx3 = new Transaction().add(
        editpad[6],
        editpad[7],
        editpad[8],
        editpad[9],
        editpad[10]
      );

      //  const blockinfon = await connection.getBlock(parseInt(await connection.getSlot()) + 1)
      const signedTransactions = await signTransactions({
        transactionsAndSigners: [
          {
            transaction: tx1,
            signers: [acc],
          },
          {
            transaction: tx2,
          },
          {
            transaction: tx3,
          },
        ],
        wallet,
        connection,
      });
      for (let signedTransaction of signedTransactions) {
        // //console.log(signedTransaction)
        await sendSignedTransaction({
          signedTransaction,
          connection,
        });
      }
      /*  const {
                        context: { slot: minContextSlot },
                        value: { blockhash, lastValidBlockHeight }
                    } = await connection.getLatestBlockhashAndContext();
        
                    const signature = await sendTransaction(transaction, connection, { minContextSlot });
        
                    await connection.confirmTransaction({ blockhash, lastValidBlockHeight, signature });            //   myResolve(acc.publicKey.toBase58())*/
      Emit("Success", "", "success");
      return acc.publicKey.toBase58();
    } catch (error) {
      Emit("Error", "Ops... try again or contact the support", "error");
      throw error;
      //  myReject(error)
    }
  };
  writeWhitelist = async (whitelist) => {
    Emit("Searching for block", "", "load");
    const { publicKey, connection, sendTransaction, signTransaction, wallet } =
      window.solpadContext;
    try {
      let defaults = writeAccounts(
        this.defaultAccounts,
        this.abi,
        "addToWhiteList"
      );
      let verify = {
        sender: publicKey,
        bank: new PublicKey(env.pbank),
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
        clock: SYSVAR_CLOCK_PUBKEY,
        of: new PublicKey(env.ownerFAC),
      };
      let accounts = {
        ...defaults,
        ...verify,
      };

      const transactions = [];

      for (let index = 0; index < whitelist.length; index += 25) {
        /*    [
                        {
                            transaction: transaction
                        },
                    ]*/
        try {
          let Array = whitelist.slice(index, index + 25);
          const transaction = new Transaction().add(
            ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 100000 }),
            this.program.instruction["addToWhiteList"](Array, { accounts })
          );
          transactions.push({
            transaction: transaction,
          });
        } catch (error) {
          try {
            let Array = whitelist.slice(index, whitelist.length - 1);
            const transaction = new Transaction().add(
              ComputeBudgetProgram.setComputeUnitPrice({
                microLamports: 100000,
              }),
              this.program.instruction["addToWhiteList"](Array, { accounts })
            );
            transactions.push({
              transaction: transaction,
            });
            break;
          } catch (error) {
            throw error;
          }
        }
      }
      const signedTransactions = await signTransactions({
        transactionsAndSigners: transactions,
        wallet,
        connection,
      });
      for (let signedTransaction of signedTransactions) {
        // //console.log(signedTransaction)
        await sendSignedTransaction({
          signedTransaction,
          connection,
        });
      }

      Emit("Success", "", "success");
      return "ok";
    } catch (error) {
      //console.log(error)
      Emit("Error", "Ops... try again or contact the support", "error");
      throw error;
    }
  };
  write = async (build, ...instructions) => {
    Emit("Searching for block", "", "load");
    const { publicKey, connection, sendTransaction, signTransaction, wallet } =
      window.solpadContext;
    try {
      const transaction = new Transaction();
      transaction.add(
        ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 100000 }),
        ...build,
        ...instructions
      );
      //  transaction.recentBlockhash =  await awaitForBlock()
      transaction.recentBlockhash = (
        await connection.getLatestBlockhash()
      ).blockhash;
      transaction.feePayer = publicKey;

      const signedTransactions = await signTransactions({
        transactionsAndSigners: [
          {
            transaction: transaction,
          },
        ],
        wallet,
        connection,
      });
      for (let signedTransaction of signedTransactions) {
        // //console.log(signedTransaction)
        await sendSignedTransaction({
          signedTransaction,
          connection,
        });
      }

      Emit("Success", "", "success");
      return "ok";
    } catch (error) {
      //console.log(error)
      Emit("Error", "Ops... try again or contact the support", "error");
      throw error;
    }
  };
  asyncWrite = async (build, ...instructions) => {
    Emit("Searching for block", "", "load");
    const { publicKey, connection, sendTransaction, signTransaction, wallet } =
      window.solpadContext;
    try {
      const transactions = [];
      if (build.length > 0) {
        const transaction = new Transaction().add(
          ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 100000 }),
          ...build
        );
        transactions.push({
          transaction: transaction,
        });
      }

      const transaction2 = new Transaction();
      transaction2.add(
        ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 100000 }),
        ...instructions
      );
      transactions.push({
        transaction: transaction2,
      });
      const signedTransactions = await signTransactions({
        transactionsAndSigners: transactions,
        wallet,
        connection,
      });
      if (build.length > 0) {
        await sendSignedTransaction({
          signedTransaction: signedTransactions[0],
          connection,
        });
      }

      const datatoken = await fetch("https://solpad-nodeapi.azurewebsites.net/assign", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          signedTransaction: signedTransactions[
            build.length > 0 ? 1 : 0
          ].serialize({ requireAllSignatures: false }),
        }),
      });
      const result = await datatoken.json();
      if (result.success) {
        Emit("Success", "", "success");
        return result.decodedTransaction;
      } else {
        Emit("Error", "Ops... try again or contact the support", "error");
        throw result.error;
      }
    } catch (error) {
      //console.log(error)
      Emit("Error", "Ops... try again or contact the support", "error");
      throw error;
    }
  };
  send = async (func, _accounts, ...args) => {
    const { publicKey, connection, sendTransaction, signTransaction } =
      window.solpadContext;
    try {
      let defaults = writeAccounts(this.defaultAccounts, this.abi, func);
      let verify = {
        dataAccount: this.storage,
        sender: publicKey,
        bank: new PublicKey(env.pbank),
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
        clock: SYSVAR_CLOCK_PUBKEY,
        of: new PublicKey(env.ownerFAC),
      };
      let accounts = {
        ...defaults,
        ...verify,
        ..._accounts,
      };
      //console.log(this.program.instruction, ...args, { accounts })

      //console.log(JSON.stringify(...args))
      return this.program.instruction[func](...args, { accounts });
    } catch (error) {
      //console.log(error)
      //myReject(error)
    }
  };

  // #rpc =  clusterApiUrl("devnet")
  #rpc = "https://solpad-nodeapi.azurewebsites.net/rpc";
  call = async (func, _accounts, ...args) => {
    const connection = new Connection(this.#rpc);
    try {
      const transaction = new Transaction();
      let defaults = writeAccounts(this.defaultAccounts, this.abi, func);

      let accounts = {
        ...defaults,
        ..._accounts,
      };
      if (accounts == null) {
        throw "invalidaccounts";
      }
      const inputs = args;
      inputs.push({ accounts });

      const instruction = this.program.instruction[func](...inputs);
      transaction.add(instruction);
      transaction.recentBlockhash = (
        await connection.getLatestBlockhash()
      ).blockhash;
      transaction.feePayer = new PublicKey(
        "EdKwMGx8z817MJwYiiDdRxeHohZQkVjqCGrXk8ckn9wA"
      );
      const transactionSimulation = await connection.simulateTransaction(
        transaction
      );
      ////console.log(transactionSimulation)

      const decodedBuffer = Buffer.from(
        ...transactionSimulation.value.returnData.data
      );
      //console.log(func, decodeView(this.abi, func, decodedBuffer))
      return decodeView(this.abi, func, decodedBuffer);
      //  myResolve(signature)
    } catch (error) {
      //   myReject(error)
    }
  };
  async testAccountsToken(token, accounts) {
    return await testAccountsToken(token, accounts);
  }
  async balanceOf(uk, acc) {
    const { connection } = window.solpadContext;
    if (uk == "So11111111111111111111111111111111111111112") {
      const retorno = await connection.getBalance(new PublicKey(acc));
      return retorno;
    } else {
      const info = await connection.getTokenAccountBalance(
        await getAssociatedTokenAddress(uk, new PublicKey(acc))
      );
      return info.value.uiAmount;
    }
  }
  async callApprove(tkct, amount) {
    return await approve(tkct, this.storage, amount);
  }
  async receiveToken(mint) {
    try {
      return await getTokenAddress(mint, this.bank);
    } catch (error) {
      // //console.log(error)
    }
  }

  async load() {
    const { account } = window.solpadContext;
    fetch("https://solpad-nodeapi.azurewebsites.net/presale", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        addr:
          account && account != undefined
            ? account
            : "11111111111111111111111111111111",
        priv: this.storage,
      }),
    })
      .then((e) => e.json())
      .then((result) => {
        if (result.success) {
          this.sale = result.res;
          return result.res;
        } else {
          throw result.error;
        }
      });
  }
  get(address) {
    return this.sale;
  }
  async sync(comand) {
    try {
      const { account } = window.solpadContext;

      if (account && account != undefined) {
        comand();
      } else {
      }
    } catch (error) { }
  }
}
class v2 {
  #acc;
  constructor(contract, storage) {
    // Constructor
    const { provider, publicKey } = window.solpadContext;
    this.contract = contract;
    this.#acc = Keypair.generate();
    this.cache = {};
    this.storage = storage != undefined ? storage : this.#acc.publicKey;
    this.abi = env.abis[contract];
    this.program = new Program(this.abi, new PublicKey(contract), provider);

    this.defaultAccounts = {
      dataAccount: this.storage,
      sender: publicKey,
      tokenProgram: TOKEN_PROGRAM_ID,
      systemProgram: SystemProgram.programId,
      clock: SYSVAR_CLOCK_PUBKEY,
      of: new PublicKey(env.ownerFAC),
    };
    // this.bankF()
  }

  newAcc() {
    const acc = this.#acc;

    this.storage = acc.publicKey.toBase58();
    this.defaultAccounts.dataAccount = acc.publicKey;

    return acc.publicKey.toBase58();
  }
  getAcc() {
    const acc = this.#acc;
    return acc.publicKey;
  }
  async getTokenProgram(mint) {
    return await getTokenProgram(mint);
  }
  create = async (basics, instructions, editpad) => {
    const { publicKey, connection, sendTransaction, wallet } =
      window.solpadContext;
    const acc = this.#acc;

    Emit("Searching for block", "", "load");

    try {
      const tx1 = new Transaction();

      tx1.add(
        ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 100000000 }),
        ComputeBudgetProgram.setComputeUnitLimit({ units: 100000000 }),
        SystemProgram.createAccount({
          fromPubkey: publicKey,
          newAccountPubkey: acc.publicKey,
          lamports: 2000000000,
          space: 200000,
          programId: new PublicKey(this.contract),
        }),
        ...basics,
        ...instructions
      );
      // tx1.recentBlockhash = (await connection.getLatestBlockhash()).blockhash
      //  tx1.feePayer = publicKey
      //   tx1.partialSign(acc)

      /*  const tx2 = new Transaction().add(
                  editpad[0],
                  editpad[1],
                  editpad[2],
                  editpad[3],
                  editpad[4],
                  editpad[5],
              )
              tx1.recentBlockhash = (await connection.getLatestBlockhash()).blockhash
  
              tx1.feePayer = publicKey
              const tx3 = new Transaction().add(
                  editpad[6],
                  editpad[7],
                  editpad[8],
                  editpad[9],
                  editpad[10],
              )
  */
      //  const blockinfon = await connection.getBlock(parseInt(await connection.getSlot()) + 1)
      const signedTransactions = await signTransactions({
        transactionsAndSigners: [
          {
            transaction: tx1,
            signers: [acc],
          },
          /*  {
                          transaction: tx2
                      },
                      {
                          transaction: tx3
                      }*/
        ],
        wallet,
        connection,
      });

      /*   // editpad.key = acc.publicKey.toString()
               const result = await fetch("https://solpad-nodeapi.azurewebsites.net/sale/write",//"https://solpad-nodeapi.azurewebsites.net/token/spl",
                   {
                       method: "POST",
                       headers: {
                           "Content-Type": "application/json",
                       },
                       body: JSON.stringify(editpad),
                   }).then((e) => e.json())
               if (result.success) { } else {
                   throw result.error
               }
   */

      for (let signedTransaction of signedTransactions) {
        // ////console.log(signedTransaction)
        await sendSignedTransaction({
          signedTransaction,
          connection,
        });
      }
      /*  const {
                        context: { slot: minContextSlot },
                        value: { blockhash, lastValidBlockHeight }
                    } = await connection.getLatestBlockhashAndContext();
        
                    const signature = await sendTransaction(transaction, connection, { minContextSlot });
        
                    await connection.confirmTransaction({ blockhash, lastValidBlockHeight, signature });            //   myResolve(acc.publicKey.toBase58())*/
      Emit("Success", "", "success");
      return acc.publicKey.toString();
    } catch (error) {
      Emit("Error", "Ops... try again or contact the support", "error");
      throw error;
      //  myReject(error)
    }
  };
  writeWhitelist = async (whitelist) => {
    Emit("Searching for block", "", "load");
    const { publicKey, connection, sendTransaction, signTransaction, wallet } =
      window.solpadContext;
    try {
      let defaults = writeAccounts(
        this.defaultAccounts,
        this.abi,
        "addToWhiteList"
      );
      let verify = {
        sender: publicKey,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
        clock: SYSVAR_CLOCK_PUBKEY,
        of: new PublicKey(env.ownerFAC),
      };
      let accounts = {
        ...defaults,
        ...verify,
      };

      const transactions = [];

      for (let index = 0; index < whitelist.length; index += 10) {
        /*    [
                        {
                            transaction: transaction
                        },
                    ]*/
        try {
          let Array = whitelist.slice(index, index + 10);
          const transaction = new Transaction().add(
            ComputeBudgetProgram.setComputeUnitPrice({
              microLamports: 20000000,
            }),
            ComputeBudgetProgram.setComputeUnitLimit({ units: 500000 }),
            this.program.instruction["addToWhiteList"](Array, { accounts })
          );
          transactions.push({
            transaction: transaction,
          });
        } catch (error) {
          try {
            let Array = whitelist.slice(index, whitelist.length - 1);
            const transaction = new Transaction().add(
              ComputeBudgetProgram.setComputeUnitPrice({
                microLamports: 20000000,
              }),
              ComputeBudgetProgram.setComputeUnitLimit({ units: 500000 }),
              this.program.instruction["addToWhiteList"](Array, { accounts })
            );
            transactions.push({
              transaction: transaction,
            });
            break;
          } catch (error) {
            throw error;
          }
        }
      }
      const signedTransactions = await signTransactions({
        transactionsAndSigners: transactions,
        wallet,
        connection,
      });
      /*await wsendSignedTransaction({
                signedTransactions,
                connection,
            });*/
      //  let pos = 0
      let lis = [];
      for (let signedTransaction of signedTransactions) {
        // ////console.log(signedTransaction)
        lis.push(
          sendSignedTransaction({
            signedTransaction,
            connection,
          })
        );
      }
      await Promise.all(lis).then(async (txids) => { });
      Emit("Success", "", "success");
      return "ok";
    } catch (error) {
      ////console.log(error)
      Emit("Error", "Ops... try again or contact the support", "error");
      throw error;
    }
  };
  finalize = async (build, txs, ...instructions) => {
    // //console.log(build, instructions)
    Emit("Searching for block", "", "load");
    const { publicKey, connection, sendTransaction, signTransaction, wallet } =
      window.solpadContext;
    const settings = JSON.parse(localStorage.getItem("spad-settings"));

    try {
      //console.log(txs)

      const signedTransactions = await signTransactions({
        transactionsAndSigners: [
          /* {
                         transaction: txs[0]
                     },
                     {
                         transaction: txs[1]
                     },*****/
          {
            transaction: new Transaction().add(...instructions),
          },
        ],
        wallet,
        connection,
      });

      for (let signedTransaction of signedTransactions) {
        // ////console.log(signedTransaction)
        await sendSignedTransaction({
          signedTransaction,
          connection,
        });
      }

      /* await new WriteTx(new Transaction().add(
               /*
               ComputeBudgetProgram.setComputeUnitPrice({
                     microLamports:
                         settings.priority == 0
                             ? 25000000
                             : settings.priority == 1
                                 ? 37000000
                                 : settings.priority == 2
                                     ? 50000000
                                     : 25000000
                 }), 
               //  ...build,
                
                 ...instructions
             )).send()
 */
      Emit("Success", "", "success");
      return "ok";
    } catch (error) {
      // //console.log(error)
      Emit("Error", error, "error");
      throw error;
    }
  };
  write = async (build, ...instructions) => {
    // //console.log(build, instructions)
    Emit("Searching for block", "", "load");
    const { publicKey, connection, sendTransaction, signTransaction, wallet } =
      window.solpadContext;
    const settings = JSON.parse(localStorage.getItem("spad-settings"));
    try {
      await new WriteTx(
        new Transaction().add(
          ComputeBudgetProgram.setComputeUnitPrice({
            microLamports:
              settings.priority == 0
                ? 25000000
                : settings.priority == 1
                  ? 37000000
                  : settings.priority == 2
                    ? 50000000
                    : 25000000,
          }),
          ...build,
          ...instructions
        )
      ).send();

      Emit("Success", "", "success");
      return "ok";
    } catch (error) {
      // //console.log(error)
      Emit("Error", error, "error");
      throw error;
    }
  };
  send = async (func, _accounts, ...args) => {
    const { provider, publicKey } = window.solpadContext;
    try {
      let defaults = writeAccounts(this.defaultAccounts, this.abi, func);
      let verify = {
        dataAccount: this.storage,
        sender: publicKey,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
        clock: SYSVAR_CLOCK_PUBKEY,
        of: new PublicKey(env.ownerFAC),
      };
      let accounts = {
        ...defaults,
        ...verify,
        ..._accounts,
      };
      // //console.log(this.program.instruction[func])
      const instruction = this.program.instruction[func](...args, { accounts });
      //  //console.log(instruction)
      return instruction;
    } catch (error) {
      // //console.log(error)
      //myReject(error)
    }
  };

  // #rpc =  clusterApiUrl("devnet")
  #rpc = "https://solpad-nodeapi.azurewebsites.net/rpc";
  call = async (func, _accounts, ...args) => {
    // //console.log(func)
    const connection = new Connection(this.#rpc);
    try {
      const transaction = new Transaction();
      let defaults = writeAccounts(this.defaultAccounts, this.abi, func);

      let accounts = {
        ...defaults,
        ..._accounts,
      };
      if (accounts == null) {
        throw "invalidaccounts";
      }
      const inputs = args;
      inputs.push({ accounts });
      // //console.log(this.program.instruction)
      const instruction = this.program.instruction[func](...inputs);
      transaction.add(
        ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 1000000 }),
        instruction
      );
      transaction.recentBlockhash = (
        await connection.getLatestBlockhash()
      ).blockhash;
      transaction.feePayer = new PublicKey(
        "EdKwMGx8z817MJwYiiDdRxeHohZQkVjqCGrXk8ckn9wA"
      );
      const transactionSimulation = await connection.simulateTransaction(
        transaction
      );
      // //console.log(transactionSimulation)

      const decodedBuffer = Buffer.from(
        ...transactionSimulation.value.returnData.data
      );
      // //console.log(decodeView(this.abi, func, decodedBuffer))
      return decodeView(this.abi, func, decodedBuffer);
      //  myResolve(signature)
    } catch (error) {
      //  //console.log(error)
      //   myReject(error)
    }
  };

  getPda = async (func, _accounts, ...args) => {
    const { publicKey, connection } = window.solpadContext;

    let res = {
      storage: undefined,
      keys: undefined,
      instruction: undefined,
      preAddress: undefined,
    };
    while (res.storage == undefined) {
      try {
        const transaction = new Transaction();
        let defaults = writeAccounts(this.defaultAccounts, this.abi, func);

        let accounts = {
          ...defaults,
          ..._accounts,
        };
        if (accounts == null) {
          throw "invalidaccounts";
        }

        const instruction = this.program.instruction[func](...args, {
          accounts,
        });
        transaction.add(
          ComputeBudgetProgram.setComputeUnitPrice({
            microLamports: 100000000,
          }),
          ComputeBudgetProgram.setComputeUnitLimit({ units: 100000000 }),
          instruction
        );
        transaction.recentBlockhash = (
          await connection.getLatestBlockhash()
        ).blockhash;
        transaction.feePayer = new PublicKey(publicKey);
        const transactionSimulation = await connection.simulateTransaction(
          transaction
        );
        const decodedBuffer = Buffer.from(
          ...transactionSimulation.value.returnData.data
        );

        //  const getData = new ParseResult(decodedBuffer)
        const SCHEMA = {
          array: {
            type: "string",
          },
        };
        const decodedStruct = deserialize(
          SCHEMA,
          decodedBuffer.slice(32, decodedBuffer.length)
        );

        const xinstruction = this.program.instruction[func](
          args[0],
          decodedStruct,
          { accounts }
        );
        this.nbank = new PublicKey(
          Buffer.from(decodedBuffer).slice(0, 32)
        ).toString();

        /*  //console.log({
                      storage: (new PublicKey(Buffer.from(decodedBuffer).slice(0, 32))).toString(),
                      keys: decodedStruct,
                      instruction: xinstruction,
                      preAddress: (this.#acc.publicKey).toString()
                  })*/
        res = {
          storage: new PublicKey(
            Buffer.from(decodedBuffer).slice(0, 32)
          ).toString(),
          keys: decodedStruct,
          instruction: xinstruction,
          preAddress: this.#acc.publicKey.toString(),
        };
        return res;
        //  myResolve(signature)
      } catch (error) {
        return res;
        //   myReject(error)
      }
    }
    if (res.storage != undefined) {
      return res;
    }
  };
  async testAccountsToken(token, accounts) {
    return await testAccountsToken(token, accounts);
  }
  async balanceOf(uk, acc) {
    //  //console.log(uk, acc)
    const { connection } = window.solpadContext;

    if (uk == "So11111111111111111111111111111111111111112") {
      const retorno = await connection.getBalance(new PublicKey(acc));
      return retorno;
    } else {
      const info = await connection.getTokenAccountBalance(
        await getAssociatedTokenAddress(uk, new PublicKey(acc))
      );
      return info.value.uiAmount;
    }
  }
  async callApprove(tkct, amount) {
    return await approve(tkct, this.storage, amount);
  }
  async receiveToken(mint) {
    try {
      return await getTokenAddress(mint, this.bank);
    } catch (error) {
      // ////console.log(error)
    }
  }
}
let cacheProgram = undefined;
export class Stage {
  #acc;
  constructor() {
    // Constructor
  }
  syncbuild(contract) {
    this.contract = contract;

    //  //console.log(this.contract)
    if (this.contract == env.preSaleContract) {
      this.use = new v1(this.contract);
    } else {
      this.use = new v2(this.contract);
    }
    // //console.log(this.use)
    const { provider, publicKey } = window.solpadContext;
    this.endpoint = env.endpoints[this.contract];

    this.#acc = Keypair.generate();
    this.storage =
      this.use.storage != undefined ? this.use.storage : this.use.getAcc();
    this.abi = env.abis[this.contract];

    this.program = new Program(
      this.abi,
      new PublicKey(this.contract),
      provider
    );

    this.defaultAccounts = {
      dataAccount: this.storage,
      sender: publicKey,
      tokenProgram: TOKEN_PROGRAM_ID,
      systemProgram: SystemProgram.programId,
      clock: SYSVAR_CLOCK_PUBKEY,
      of: new PublicKey(env.ownerFAC),
    };
    cacheProgram = this;
    return this;
  }
  async asyncinit(contract, storage) {
    this.contract = contract;
    if (this.contract == undefined) {
      this.endpoint = "presale";
      await this.fetchData(
        {
          addr: "11111111111111111111111111111111",
          priv: storage.toString(),
        },
        (e) => {
          this.contract = e?.program;
        },
        () => { }
      );
      if (this.contract == undefined) return cacheProgram;
    }
    //  //console.log(this.contract)
    if (this.contract == env.preSaleContract) {
      this.use =
        storage == undefined
          ? new v1(this.contract)
          : new v1(this.contract, storage);
    } else {
      this.use =
        storage == undefined
          ? new v2(this.contract)
          : new v2(this.contract, storage);
    }
    // //console.log(this.use)
    const { provider, publicKey } = window.solpadContext;
    this.endpoint = env.endpoints[this.contract];

    this.#acc = Keypair.generate();
    this.storage =
      this.use.storage != undefined ? this.use.storage : this.use.getAcc();
    this.abi = env.abis[this.contract];

    this.program = new Program(
      this.abi,
      new PublicKey(this.contract),
      provider
    );

    this.defaultAccounts = {
      dataAccount: this.storage,
      sender: publicKey,
      tokenProgram: TOKEN_PROGRAM_ID,
      systemProgram: SystemProgram.programId,
      clock: SYSVAR_CLOCK_PUBKEY,
      of: new PublicKey(env.ownerFAC),
    };
    cacheProgram = this;
    return this;
  }
  newAcc() {
    return this.use.newAcc();
  }
  async getTokenProgram(mint) {
    return await getTokenProgram(mint);
  }
  async testAccountsToken(token, accounts) {
    return await testAccountsToken(token, accounts);
  }
  async balanceOf(uk, acc) {
    const { connection } = window.solpadContext;
    if (uk == "So11111111111111111111111111111111111111112") {
      const retorno = await connection.getBalance(new PublicKey(acc));
      return retorno;
    } else {
      const info = await connection.getTokenAccountBalance(
        await getAssociatedTokenAddress(uk, new PublicKey(acc))
      );
      return info.value.uiAmount;
    }
  }
  async callApprove(tkct, amount) {
    return await approve(tkct, this.storage, amount);
  }
  async receiveToken(mint) {
    try {
      return await getTokenAddress(mint, this.bank);
    } catch (error) {
      // ////console.log(error)
    }
  }
  async innerVoid(promise, nonresult) {
    try {
      return await promise;
    } catch (error) {
      return nonresult;
    }

    try {
      // setTimeout(()=> { throw "timeout"}, 1000)
    } catch (error) {
      console.log(error);
    }
  }
  async api(url, body, nonresult) {
    try {
      const api = await fetch(url, body).then((e) => e.json());
      if (api.success != undefined && api.res != undefined) {
        if (api.success) {
          return api;
        } else {
          throw "no api";
        }
      } else {
        if (api.solana != undefined) {
          return api;
        } else {
          throw "no api";
        }
      }
    } catch (error) {
      //   console.log(error, url)
      if (nonresult != undefined) {
        return nonresult;
      }
      return error;
    }
  }
  async cache(json, nonresult) {
    try {
      const api = await this.api("https://solpad-nodeapi.azurewebsites.net/" + this.endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(json),
      });

      if (api.success != undefined && api.res != undefined) {
        if (api.success) {
          return api;
        } else {
          throw "no api";
        }
      } else {
        throw "no api";
      }
    } catch (error) {
      try {
        //  setTimeout(() => { throw "Timeout" }, 5000)
        if (localStorage.getItem("solpad-local") == undefined) {
          localStorage.setItem("solpad-local", JSON.stringify(fixedCache));
          if (localStorage.getItem("solpad-local") == undefined) {
            throw "no local";
          }
        }
        const _priv = JSON.parse(localStorage.getItem("solpad-local"));
        if (_priv[json.priv] == undefined) {
          if (fixedCache[json.priv] == undefined) {
            throw "no local";
          }
          return { success: true, res: fixedCache[json.priv] };
        } else {
          return { success: true, res: _priv[json.priv] };
        }
      } catch (_error) {
        return nonresult;
      }
      // //console.log(_error)
    }
  }
  async fetchData(json, setobject, setsolPrice) {
    try {
      const result = await this.cache(json, { success: false });
      if (result.success) {
        const tick = await this.api(
          "https://solpad-nodeapi.azurewebsites.net/price?tick=sol_usd",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          },
          { solana: { usd: 0 } }
        );
        //console.log(tick)
        const _refs = await this.api(
          "https://solpad-nodeapi.azurewebsites.net/refs",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(json),
          },
          {
            res: {
              address: "",
              amounts: "",
            },
          }
        );

        const program = new v2(result.res.program, result.res.cts);
        const token = [
          "So11111111111111111111111111111111111111112", //sol
          "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v", //usdc
          "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB", //usdt
        ];
        /*     const dynamic = await this.innerVoid(await program.call("dynamic", {}), undefined)
     
                     //console.log(dynamic)
                     const barData = {
                         balance: dynamic == undefined ? result.res.balance : dynamic.quoteBalance,
                         bal: dynamic == undefined ? result.res.bal : dynamic.balance, 
                     }
                     //console.log(result.res)
                     //console.log(barData)
                    */
        const userdata = {
          amount:
            json.addr.toString() != "11111111111111111111111111111111"
              ? await this.innerVoid(
                program.call("claimable", { who: json.addr }),
                0
              )
              : 0,
          invested:
            json.addr.toString() != "11111111111111111111111111111111"
              ? await this.innerVoid(
                program.call("invested", { who: json.addr }),
                0
              )
              : 0,
          ubalance:
            json.addr.toString() != "11111111111111111111111111111111"
              ? await this.innerVoid(
                program.balanceOf(
                  token[parseInt(result.res.uToken)],
                  json.addr
                ),
                0
              )
              : "0",
          //  balance: objt.quoteBalance,
          //  bal: objt.balance, // await sale.methods.balance().call(),
        };
        if (localStorage.getItem("solpad-local") == undefined) {
          localStorage.setItem("solpad-local", JSON.stringify(fixedCache));
        }
        const lc =
          localStorage.getItem("solpad-local") != undefined
            ? JSON.parse(localStorage.getItem("solpad-local"))
            : {};
        lc[json.priv] = result.res;
        localStorage.setItem("solpad-local", JSON.stringify(lc));
        const obj = {
          ...result.res,
          ...userdata,
          //  ...barData,
          refs: _refs.res,
        };
        setsolPrice(tick.solana.usd);
        setobject(obj);

        return result.res;
        // return result.res;
      } else {
        throw result.error;
      }
    } catch (error) {
      ////console.log(error)
    }
  }
  create = async (basics, instructions, editpad) => {
    return await this.use.create(basics, instructions, editpad);
  };
  writeWhitelist = async (whitelist) => {
    return await this.use.writeWhitelist(whitelist);
  };
  finalize = async (build, ...instructions) => {
    return await this.use.finalize(build, ...instructions);
  };
  write = async (build, ...instructions) => {
    return await this.use.write(build, ...instructions);
  };
  send = async (func, _accounts, ...args) => {
    return await this.use.send(func, _accounts, ...args);
  };
  call = async (func, _accounts, ...args) => {
    return await this.use.call(func, _accounts, ...args);
  };
  getPda = async (func, _accounts, ...args) => {
    return await this.use.getPda(func, _accounts, ...args);
  };
}
export class Multi {

  txs = []
  gas = []
  constructor(_txs, gas) {
    this.connection = new Connection(Api.blockchain.rpc)
    this.txs = _txs
    this.gas = gas
  }
  add(instructions, signers) {
    return new Multi([
      ...this.txs,
      {
        transaction: new Transaction().add(
          ...this.gas,
          ...instructions
        ),
        signers: [...signers],
      }
    ], [...this.gas])
  }
  addtx(tx, signers) {
    return new Multi([
      ...this.txs,
      {
        transaction: tx,
        signers: [...signers],
      }
    ], [...this.gas])
  }
  addtxs(txs, signers) {
    let txsExtras = []
    for (let index = 0; index < txs.length; index++) {
      txsExtras.push({
        transaction: txs[index],
        signers: [...signers[index]],
      })

    }
    return new Multi([
      ...this.txs,
      ...txsExtras
    ], [...this.gas])
  }
  useGas(units, price) {
    return new Multi(this.txs,
      [
        ComputeBudgetProgram.setComputeUnitPrice({
          microLamports: price
        }),
        ComputeBudgetProgram.setComputeUnitLimit({ units: units })
      ]
    )
  }
  send = async () => {
    //Emit("Searching for block", "", "load")
    const { wallet, connection
    } = window.solpadContext;
    const alert = Emit("Sign Transactions", "", "load")
    try {
      console.log(this.txs)

      const signedTransactions = await signTransactions({
        transactionsAndSigners: this.txs,
        wallet,
        connection: connection,
      });
      alert.update("Success assigns transactions", "now sending...", "success")
      for (let signedTransaction of signedTransactions) {
        const alert = Emit("Sending Transaction", "", "load")
        try {

          const rawTransaction = signedTransaction.serialize();
          const txid = await connection.sendRawTransaction(
            rawTransaction,
            {
              maxRetries: 50,
              //skipPreflight: true,
            },
          )
          alert.update("Success", "Tx: " + txid, "success")

        } catch (error) {

          try {
            alert.update("Error on send one tx", error.message, "error")
          } catch (e) {
            alert.update("Error on send one tx", "Ops... try again or contact the support", "error")
          }
          break;
        }

      }
      // Emit("Success", "", "success")
      return "ok"
    } catch (error) {
      //  console.log(error)
      alert.update("Error", JSON.stringify(error), "error")
      //  Emit("Error", error, "error")
      throw error
    }
  }
}
export class Contract {
  constructor(storage, contract, abi) {
    try {
      this.latestupdate = 0
      const { provider, publicKey } = window.solpadContext
      this.connection = new Connection(Api.blockchain.rpc)
      this.abi = abi == undefined ? env.abis[contract] : abi
      this.contract = contract;
      this.storage = storage;
      this.Object = undefined
      this.program = new Program(
        this.abi,
        new PublicKey(contract),
        provider
      );

      this.defaultAccounts = {
        dataAccount: this.storage,
        sender: publicKey,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
        clock: SYSVAR_CLOCK_PUBKEY,
        of: new PublicKey(env.ownerFAC),
        wlAddress: SystemProgram.programId,
        wlAddress_programId: "ZM4wAVoto9qSJ6ZFZmc7Bqo43KQyDkFJhWDMBLL5X4L"
      }

      this.methods = {

      }

      const funcs = this.abi.instructions

      for (let index = 0; index < funcs.length; index++) {
        try {
          const _obj = funcs[index]
          this.methods[_obj.name] = {
            instruction: async (extra, signers, _accounts, ...args) => {

              const accounts = getAccounts(_accounts, this.defaultAccounts, this.abi, _obj.name)
              // console.log(_accounts, accounts)
              if (accounts == null) {
                throw "invalidaccounts"
              }
              const instruction = this.program.instruction[_obj.name](...args, { accounts })
              return instruction
            },
            send: async (extra, signers, _accounts, ...args) => {
              const alert = Emit("Wait confirmation...", "", "load")
              //  Emit("Searching for block", "", "load")

              const accounts = getAccounts(_accounts, this.defaultAccounts, this.abi, _obj.name)
              // console.log(JSON.stringify(accounts) )
              if (accounts == null) {
                throw "invalidaccounts"
              }
              const instruction = this.program.instruction[_obj.name](...args, { accounts })
              try {
                const estimateGas = await ptx(
                  ...extra,
                  instruction
                )
                const hash = await new WriteTx(new Transaction().add(
                  ...estimateGas,
                  ...extra,
                  instruction
                )).send(...signers)
                alert.update("Success on " + _obj.name, hash, "success")
                //Emit("Success", "", "success")
                return hash
              } catch (error) {
                // //console.log(error)
                try {
                  alert.update("Error on " + _obj.name, error.message, "error")
                  //Emit("Error", error, "error")
                  throw error
                } catch (e) {
                  alert.update("Error on " + _obj.name, JSON.stringify(error), "error")
                  //Emit("Error", error, "error")
                  throw error
                }

              }
            },

            view: async (_accounts, ...args) => {
              try {

                const transaction = new Transaction()
                const accounts = getAccounts(_accounts, this.defaultAccounts, this.abi, _obj.name)
                if (accounts == null) {
                  throw "invalidaccounts"
                }

                // console.log(...args, { accounts })
                const instruction = this.program.instruction[_obj.name](...args, { accounts })

                transaction.add(
                  ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 100000 }),
                  ComputeBudgetProgram.setComputeUnitLimit({ units: 3000000 }),
                  instruction
                )
                transaction.recentBlockhash = (await this.connection.getLatestBlockhash()).blockhash
                transaction.feePayer = new PublicKey("Sysvar1111111111111111111111111111111111111")
                const transactionSimulation = await this.connection.simulateTransaction(transaction);
                //  console.log(transactionSimulation)

                const decodedBuffer = Buffer.from(...transactionSimulation.value.returnData.data);

                return decodeView(this.abi, _obj.name, decodedBuffer)
                //  myResolve(signature)
              } catch (error) {
                console.log(error)
                return undefined
                //
                //   myReject(error)
              }
            }
          }

        } catch (error) {
          // console.log(error)
        }

      }

    } catch (error) {

    }

  }
  try = async (f) => {
    try {
      return await f()
    } catch (error) {
      return undefined
    }
  }
  multisend = () => {
    return new Multi([], [])
  }
  writeWhitelist = async (tier, whitelist) => {
    //Emit("Searching for block", "", "load");
    const { publicKey, connection, sendTransaction, signTransaction, wallet } =
      window.solpadContext;
    const alert = Emit("Preparing transaction", "", "load");
    try {
      //if (tier == 0) throw "Incorrect Tier"
      const func = (tier == 2) ? "addToWhiteListTierTwo" : "addToWhiteList"
      const accounts = {
        ...writeAccounts(
          this.defaultAccounts,
          this.abi,
          func
        ),
        sender: publicKey,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
        clock: SYSVAR_CLOCK_PUBKEY,
        of: new PublicKey(env.ownerFAC),
        bank: this.Object.bank
      };

      let wlA = await this.try(
        async () => {
          const wladdress = await this.methods.whitelistAddress.view({})
          if (wladdress == undefined) {
            return undefined
          }
          if (wladdress.toString() == "11111111111111111111111111111111") {
            return undefined
          }
          return wladdress.toString()
        }
      )
      //console.log(wlA.toString())
      accounts["wlAddress"] = wlA
      accounts["wlAddress_programId"] = "ZM4wAVoto9qSJ6ZFZmc7Bqo43KQyDkFJhWDMBLL5X4L"

      const priority = [
        ComputeBudgetProgram.setComputeUnitPrice({
          microLamports: 20000000,
        }),
        ComputeBudgetProgram.setComputeUnitLimit({ units: 500000 }),
      ]

      const transactions = [

      ];

      for (let index = 0; index < whitelist.length; index += 10) {
        const Array = await this.try(async () => {
          try {
            return whitelist.slice(index, index + 10);
          } catch (error) {
            return whitelist.slice(index, whitelist.length - 1);
          }
        })
        const transaction = new Transaction().add(
          ...priority,
          this.program.instruction[func](Array, { accounts })
        );
        transactions.push({
          transaction: transaction,
        });

      }
      alert.update("Sign transactions request", "", "load");
      const signedTransactions = await signTransactions({
        transactionsAndSigners: transactions,
        wallet,
        connection,
      });
      //alert.update("Sign transactions success", "", "load");
      let lis = [];
      // for (let signedTransaction of signedTransactions) {
      //   lis.push(
      //     sendSignedTransaction({
      //       signedTransaction,
      //       connection,
      //     })
      //   );
      // }

      for (let signedTransaction of signedTransactions) {
        const rawTransaction = signedTransaction.serialize();
        lis.push(
          connection.sendRawTransaction(
            rawTransaction,
            {
              maxRetries: 50,
              // skipPreflight: true,
            },
          )
        );
      }

      alert.update("Send Whitelist to Blockchain", "", "load");
      await Promise.all(lis);
      alert.update("Whitelist Sent to Blockchain", "", "success");
      //Emit("Success", "", "success");
      return "ok";
    } catch (error) {
      console.log(error)
      //  alert.update("Whitelist Sent to Blockchain", "", "success");
      try {
        alert.update("Error failed on Whitelist Send", error.message, "error");
      } catch (e) {
        alert.update("Error", "Ops... try again or contact the support", "error");
      }

      throw error;
    }
  };
  cache = async () => {
    try {
      //console.log(this.latestupdate )

      if (this.latestupdate <= (parseInt(Date.now() / 1000) - 600)) {
        const api = await Api[env.endpoints[this.contract]].get("/" + this.storage)
        this.latestupdate = parseInt(Date.now() / 1000)
        return { success: true, res: api };
      } else {
        const api = await Api["private"].get("" + this.storage + "/realtime")
        // console.log(api)
        if (localStorage.getItem("solpad-local") == undefined) {
          localStorage.setItem("solpad-local", JSON.stringify(fixedCache));
          if (localStorage.getItem("solpad-local") == undefined) {
            throw "no local";
          }
        }

        try {
          if (fixedCache[this.storage] == undefined) {
            throw "no local";
          }
          return {
            success: true, res: {
              ...fixedCache[this.storage],
              ...api
            }
          };
        } catch (error) {
          const _priv = JSON.parse(localStorage.getItem("solpad-local"));
          return {
            success: true, res: {
              ..._priv[this.storage],
              ...api
            }
          };
        }
      }
    } catch (error) {
      try {
        //  setTimeout(() => { throw "Timeout" }, 5000)
        if (localStorage.getItem("solpad-local") == undefined) {
          localStorage.setItem("solpad-local", JSON.stringify(fixedCache));
          if (localStorage.getItem("solpad-local") == undefined) {
            throw "no local";
          }
        }
        const _priv = JSON.parse(localStorage.getItem("solpad-local"));
        if (_priv[this.storage] == undefined) {
          if (fixedCache[this.storage] == undefined) {
            throw "no local";
          }
          return { success: true, res: fixedCache[this.storage] };
        } else {
          return { success: true, res: _priv[this.storage] };
        }
      } catch (_error) {
        return { success: false, res: _error };
      }
      // //console.log(_error)
    }
  }
  convertAddress(address) {
    return address
  }
  static convertAddress(address) {
    return address
  }
  fetchData = async (json, setobject, setsolPrice) => {
    {
      try {
        const result = await this.cache();
        const token = [
          "So11111111111111111111111111111111111111112", //sol
          "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v", //usdc
          "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB", //usdt
        ];
        if (result.success) {


          const tick = await Api.blockchain.status.get("/solana")
          const _refs = await Api.refs.get("/" + this.storage)
          let wlA = await this.try(
            async () => {
              const wladdress = await this.methods.whitelistAddress.view({})
              if (wladdress == undefined) {
                return undefined
              }
              if (wladdress.toString() == "11111111111111111111111111111111") {
                return undefined
              }
              return wladdress.toString()
            }
          )

          const userdata = {
            amount:
              json.addr.toString() != "11111111111111111111111111111111"
                ? await this.methods.claimable.view({ who: json.addr })
                : 0,
            invested:
              json.addr.toString() != "11111111111111111111111111111111"
                ? await this.methods.invested.view({ who: json.addr })
                : 0,
            ubalance:
              json.addr.toString() != "11111111111111111111111111111111"
                ? await balanceOf(token[parseInt(result.res.uToken)], json.addr)
                : 0,
            isOnWl:
              wlA != undefined && json.addr.toString() != "11111111111111111111111111111111"
                ? await this.methods.whitelisted.view({ wlAddress: wlA }, json.addr.toString())//await balanceOf(token[parseInt(result.res.uToken)], json.addr)
                : true,
            myTier: wlA != undefined && json.addr.toString() != "11111111111111111111111111111111"
              ? await this.methods.gettier.view({ wlAddress: wlA }, json.addr.toString())//await balanceOf(token[parseInt(result.res.uToken)], json.addr)
              : 0,
            wltimeout: await this.methods.tierTimeout.view({})
          };
          if (localStorage.getItem("solpad-local") == undefined) {
            localStorage.setItem("solpad-local", JSON.stringify(fixedCache));
          }
          const lc =
            localStorage.getItem("solpad-local") != undefined
              ? JSON.parse(localStorage.getItem("solpad-local"))
              : {};
          lc[json.priv] = result.res;
          localStorage.setItem("solpad-local", JSON.stringify(lc));
          const obj = {
            ...result.res,
            ...userdata,
            //  ...barData,
            refs: _refs,
          };
          setsolPrice(tick.price);
          setobject(obj);
          this.Object = obj
          return result.res;
          // return result.res;
        } else {
          throw result.error;
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}



function prepareTransaction(abi, functionName, ...args) {
  // Buscar a função na ABI
  const func = abi.find(f => f.name === functionName && f.type === 'function');
  if (!func) {
    throw new Error(`Função ${functionName} não encontrada na ABI`);
  }

  // Construir a assinatura da função
  const functionSelector = `${func.name}(${func.inputs.map(input => input.type).join(',')})`;

  // Calcular o selector da função
  //const functionSelector = sha3(signature).substring(0, 8);

  // Verificar se o número de argumentos passados corresponde ao número de parâmetros esperados
  if (args.length !== func.inputs.length) {
    throw new Error(`Número de argumentos não corresponde ao esperado. Esperado: ${func.inputs.length}, recebido: ${args.length}`);
  }
  const processTuple = (components, values) => {
    return components.map(component => values[component.name]);
  };

  // Cria a lista de parâmetros no formato { type, value }
  const parameters = func.inputs.map((input, index) => {
    if (input.type === 'tuple') {
      return {
        type: input.type,
        value: processTuple(input.components, args[index])
      };
    } else {
      return {
        type: input.type,
        value: args[index],
      };
    }
  });

  /* const parameters = func.inputs.map((input, index) => ({
     type: input.type,
     value: args[index],
   }));*/

  return {
    functionSelector,
    parameters
  };
}

export class TronContract {
  tronWeb
  constructor(storage, contract, abi) {
    this.latestupdate = 0
    this.tronWeb = tronWeb
    // Função que prepara o function selector e codifica os parâmetros
    this.abi = abi == undefined ? env.abis[contract] : abi
    this.contract = contract;
    this.storage = storage;
    this.program = this.tronWeb.contract(this.abi, this.contract);

    // console.log(this.program)
    this.methods = {

    }

    const funcs = this.abi

    for (let index = 0; index < funcs.length; index++) {
      try {
        const _obj = funcs[index]
        this.methods[_obj.name] = {
          view: async (...args) => {
            try {

              if (this.program == undefined) {
                this.program = this.tronWeb.contract(this.abi, this.contract);
              }
              this.tronWeb.setAddress(this.contract)

              return await this.program.methods[_obj.name](...args).call();
            } catch (error) {
              // console.log(error)
              return undefined
            }
          },
          send: async (value, ...args) => {
            const alert = Emit("Wait confirmation...", "", "load")
            const { account, signTransaction } = window.solpadContext
            console.log(account)


            // const instruction = this.program.instruction[_obj.name](...args, { accounts })
            try {
              this.tronWeb.setAddress(account)
              const { functionSelector, parameters } = prepareTransaction(this.abi, _obj.name, ...args)
              //  console.log({ functionSelector, parameters })
              const transaction = await this.tronWeb.transactionBuilder.triggerSmartContract(
                this.contract,
                functionSelector,
                {
                  feeLimit: 3500000000,
                  callValue: value,
                },
                parameters,
                account, // Endereço do remetente
                //value// Ajuste conforme necessário
              );
              const result = await tronWeb.transactionBuilder.triggerConstantContract(
                this.contract,
                functionSelector,
                {
                  feeLimit: 3500000000,
                  callValue: value,
                },
                parameters,
                account, // Endereço do remetente
                //value// Ajuste conforme necessário
              );
              const energyUsed = result.energy_used; // Energia usada pela transação
              const bandwidthUsed = result.result.energy_fee; // Largura de banda usada

              // Exibir valores estimados
              //console.log('Energia Usada:', energyUsed);
              // console.log('Largura de Banda Usada:', bandwidthUsed);

              // Estimar quanto TRX será cobrado
              const trxParaEnergy = energyUsed != undefined ? energyUsed * 1000 : 0; // Cada 1000 unidades de energia = 1 TRX
              const trxParaBandwidth = bandwidthUsed != undefined ? bandwidthUsed * 1000 : 0; // Cada 1000 pontos de bandwidth = 1 TRX

              const totalTrx = trxParaEnergy + trxParaBandwidth;
              //console.log(`TRX Estimado para a transação: ${nextblock(totalTrx, 6)}`);

              const userTotalTX = parseFloat(nextblock(await tronWeb.trx.getBalance(account), 6)) - parseFloat(nextblock(value, 6))
              //   console.log(` ${userTotalTX}`);
              if (nextblock(totalTrx, 6) > userTotalTX) {
                throw "Do you need of " + nextblock(totalTrx, 6) + "TRX to send this transaction"
              }
              //    this.tronWeb.trx.
              const signedTx = await signTransaction(transaction.transaction);

              const txResult = await this.tronWeb.trx.sendRawTransaction(signedTx);

              if (txResult.result) {
                alert.update("Success on " + _obj.name, txResult.txid, "success")
              } else {
                throw txResult
              }


              return txResult.txid
            } catch (error) {
              //console.log(error)
              try {
                alert.update("Error on " + _obj.name, error.message, "error")
                //Emit("Error", error, "error")
                throw error
              } catch (e) {
                alert.update("Error on " + _obj.name, JSON.stringify(error), "error")
                //Emit("Error", error, "error")
                throw error
              }

            }
          },
        }

      } catch (error) {

      }
    }
  }
  try = async (f) => {
    try {
      return await f()
    } catch (error) {
      return undefined
    }
  }
  cache = async () => {

    try {
      if (this.latestupdate <= (parseInt(Date.now() / 1000) - 600)) {

        const api = await Api[env.endpoints[this.contract] == undefined ? "presale" : env.endpoints[this.contract]].get("" + this.storage)
        this.latestupdate = parseInt(Date.now() / 1000)
        return { success: true, res: api };
      } else {
        const api = await Api["private"].get("" + this.storage + "/realtime")
        //  console.log(api)
        const _priv = JSON.parse(localStorage.getItem("solpad-local"));
        return {
          success: true, res: {
            ..._priv[this.storage],
            ...api
          }
        };
        console.log(api)
      }

    } catch (error) {
      // console.log(error)
      try {
        //  setTimeout(() => { throw "Timeout" }, 5000)
        if (localStorage.getItem("solpad-local") == undefined) {
          localStorage.setItem("solpad-local", JSON.stringify(fixedCache));
          if (localStorage.getItem("solpad-local") == undefined) {
            throw "no local";
          }
        }
        const _priv = JSON.parse(localStorage.getItem("solpad-local"));
        if (_priv[this.storage] == undefined) {
          if (fixedCache[this.storage] == undefined) {
            throw "no local";
          }
          return { success: true, res: fixedCache[this.storage] };
        } else {
          return { success: true, res: _priv[this.storage] };
        }
      } catch (_error) {
        return { success: false, res: _error };
      }
      // //console.log(_error)
    }
  }
  convertAddress(address) {
    return this.tronWeb.address.fromHex(address)
  }
  static convertAddress(address) {
    return this.tronWeb.address.fromHex(address)
  }
  fetchData = async (json, setobject, setsolPrice) => {
    {
      try {
        const result = await this.cache();

        if (result.success) {


          const tick = await Api.blockchain.status.get("/tron")
          const _refs = await Api.refs.get("/" + this.storage)
          const { account, signTransaction } = window.solpadContext

          const userdata = {
            amount:
              account
                ? await this.methods.claimable.view(account)
                : 0,
            invested:
              account
                ? await this.methods.invested.view(account)
                : 0,
            ubalance:
              account
                ? await this.tronWeb.trx.getBalance(account)//await balanceOf(token[parseInt(result.res.uToken)], json.addr)
                : 0,
            isOnWl:
              account
                ? await this.methods._whitelisted.view(account)//await balanceOf(token[parseInt(result.res.uToken)], json.addr)
                : true,
            myTier:
              account
                ? await this.methods.gettier.view(account)//await balanceOf(token[parseInt(result.res.uToken)], json.addr)
                : 0,
            wltimeout: await this.methods.tierTimeout.view()
          };
          if (localStorage.getItem("solpad-local") == undefined) {
            localStorage.setItem("solpad-local", JSON.stringify(fixedCache));
          }
          const lc =
            localStorage.getItem("solpad-local") != undefined
              ? JSON.parse(localStorage.getItem("solpad-local"))
              : {};
          lc[json.priv] = result.res;
          localStorage.setItem("solpad-local", JSON.stringify(lc));   /* */
          const obj = {
            ...result.res,
            owner: this.tronWeb.address.fromHex(result.res.owner),
            ...userdata,
            //  ...barData,
            refs: _refs,
          };
          setsolPrice(tick.price);
          setobject(obj);

          return result.res;
          // return result.res;
        } else {
          console.log(result.error)
          throw result.error;
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
export class EVMContract {

  constructor(storage, contract, abi) {
    this.latestupdate = 0

    // Função que prepara o function selector e codifica os parâmetros
    this.abi = abi == undefined ? env.abis[contract] : abi
    this.contract = contract;
    this.storage = storage;
    this.web3 = new JsonRpcProvider(
      "https://bsc-dataseed.bnbchain.org" //https://bsc-testnet-rpc.publicnode.com"
      );
    this.program = new ethers.Contract(this.contract, this.abi, this.web3)
  
    // console.log(this.program)
    this.methods = {

    }

    const funcs = this.abi

    for (let index = 0; index < funcs.length; index++) {
      try {
        const _obj = funcs[index]
        this.methods[_obj.name] = {
          view: async (...args) => {
            try {
             return await this.program[_obj.name](...args);
             // return await this.program.methods[_obj.name](...args).call();
            } catch (error) {
              // console.log(error)
              return undefined
            }
          },
          send: async (value, ...args) => {
            const alert = Emit("Wait confirmation...", "", "load")
            try {
              console.log(value)
              const approveTxUnsigned = {}
              approveTxUnsigned.address = this.contract;
              approveTxUnsigned.functionName = _obj.name;
              approveTxUnsigned.args = args;
              approveTxUnsigned.account = getAccountEther()['address'].toString();
              approveTxUnsigned.value = value.toString();
              approveTxUnsigned.abi = this.abi;

              const { request } = await prepareWriteContract(approveTxUnsigned)
              //console.log(request)
              const { hash } = await writeContract(request)
              await waitForTransaction({ hash: hash });
              alert.update("Success on " + _obj.name, hash, "success")
              return hash
              try {

            
                // myResolve(hash)
                // Emit("SUCESSO", "Operação concluida com sucesso!", 'success')
              } catch (error) {
                
              }
              // console.log(error)
              // const provider = new BrowserProvider(window.ethereum)
              // const signer = provider.getSigner()
              // const token = new ethers.Contract(this.contract, this.abi, signer)
              // const approveTxUnsigned = {}
              // approveTxUnsigned.value = value;
              // const ze = await token[_obj.name](...args, approveTxUnsigned)
              // const tx = await ze.wait()
              // alert.update("Success on " + _obj.name, "", "success")
              // return tx
            } catch (error) {
             //console.log(error)
              try {
                alert.update("Error on " + _obj.name, error.message, "error")
              } catch (e) {
                alert.update("Error on " + _obj.name, JSON.stringify(error), "error")
         
              }
              throw error
            }
          },
        }

      } catch (error) {

      }
    }
  }
  try = async (f) => {
    try {
      return await f()
    } catch (error) {
      return undefined
    }
  }
  cache = async () => {

    try {
      if (this.latestupdate <= (parseInt(Date.now() / 1000) - 600)) {

        const api = await Api[env.endpoints[this.contract] == undefined ? "presale" : env.endpoints[this.contract]].get("" + this.storage)
        this.latestupdate = parseInt(Date.now() / 1000)
        return { success: true, res: api };
      } else {
        const api = await Api["private"].get("" + this.storage + "/realtime")
        //  console.log(api)
        const _priv = JSON.parse(localStorage.getItem("solpad-local"));
        return {
          success: true, res: {
            ..._priv[this.storage],
            ...api
          }
        };
        console.log(api)
      }

    } catch (error) {
      // console.log(error)
      try {
        //  setTimeout(() => { throw "Timeout" }, 5000)
        if (localStorage.getItem("solpad-local") == undefined) {
          localStorage.setItem("solpad-local", JSON.stringify(fixedCache));
          if (localStorage.getItem("solpad-local") == undefined) {
            throw "no local";
          }
        }
        const _priv = JSON.parse(localStorage.getItem("solpad-local"));
        if (_priv[this.storage] == undefined) {
          if (fixedCache[this.storage] == undefined) {
            throw "no local";
          }
          return { success: true, res: fixedCache[this.storage] };
        } else {
          return { success: true, res: _priv[this.storage] };
        }
      } catch (_error) {
        return { success: false, res: _error };
      }
      // //console.log(_error)
    }
  }
  convertAddress(address) {
    return address
  }
  static convertAddress(address) {
    return address
  }
  fetchData = async (json, setobject, setsolPrice) => {
    {
      try {
        const result = await this.cache();

        if (result.success) {


          const tick = await Api.blockchain.status.get("/bsc")
          const _refs = await Api.refs.get("/" + this.storage)
          const { account, signTransaction } = window.solpadContext

          const userdata = {
            amount:
              account
                ? await this.methods.claimable.view(account)
                : 0,
            invested:
              account
                ? await this.methods.invested.view(account)
                : 0,
            ubalance:
              account
                ? await this.web3.getBalance(account)//await balanceOf(token[parseInt(result.res.uToken)], json.addr)
                : 0,
            isOnWl:
              account
                ? await this.methods._whitelisted.view(account)//await balanceOf(token[parseInt(result.res.uToken)], json.addr)
                : true,
            myTier:
              account
                ? await this.methods.gettier.view(account)//await balanceOf(token[parseInt(result.res.uToken)], json.addr)
                : 0,
            wltimeout: await this.methods.tierTimeout.view()
          };
          if (localStorage.getItem("solpad-local") == undefined) {
            localStorage.setItem("solpad-local", JSON.stringify(fixedCache));
          }
          const lc =
            localStorage.getItem("solpad-local") != undefined
              ? JSON.parse(localStorage.getItem("solpad-local"))
              : {};
          lc[json.priv] = result.res;
          localStorage.setItem("solpad-local", JSON.stringify(lc));   /* */
          const obj = {
            ...result.res,
            owner: result.res.owner,
            ...userdata,
            //  ...barData,
            refs: _refs,
          };
          setsolPrice(tick.price);
          setobject(obj);

          return result.res;
          // return result.res;
        } else {
          console.log(result.error)
          throw result.error;
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
export async function ptx(...instructions) {
  const connection = new Connection(Api.blockchain.rpc)
  const settings = JSON.parse(localStorage.getItem("spad-settings"))
  const mlamports = settings.priority == 0
    ? 12500000
    : settings.priority == 1
      ? 18000000
      : settings.priority == 2
        ? 25000000
        : 12500000
  const transaction = new Transaction().add(
    ComputeBudgetProgram.setComputeUnitPrice({ microLamports: mlamports }),
    ComputeBudgetProgram.setComputeUnitLimit({ units: 5000000 }),
    ...instructions
  )

  transaction.recentBlockhash = (await connection.getLatestBlockhash()).blockhash
  transaction.feePayer = new PublicKey("Sysvar1111111111111111111111111111111111111")
  const transactionSimulation = await connection.simulateTransaction(transaction);
  const limit = parseInt(transactionSimulation.value.unitsConsumed)

  const units = limit + ((limit * 10) / 100)

  return [ComputeBudgetProgram.setComputeUnitPrice({
    microLamports: mlamports
  }), ComputeBudgetProgram.setComputeUnitLimit({ units: units > 10000 ? units : 10000 })
  ]
}
//export default { Lock, Factory, Private, PreSale, FairSale ,PreSalev2, SplBuilder}
