import React from "react";

export default ({ pos, selected, setSelected, title, content }) => {
    return (
        <div onClick={() => { setSelected(pos) }} style={{ height: "min-content", background: "transparent", border: "1px solid #000", width: "100%", display: "grid", gap: "5px", fontSize: "10px", }}>
            <div style={{ height: "59px", background: "transparent", width: "100%", display: "flex", gap: "5px", fontSize: "10px", justifyContent: "space-between", padding: "0px 10px" }}>
                <div style={{ display: "flex", alignItems: "center", gap: "5px", }}>

                    <p style={{ fontSize: "15px", color: "#fff", textAlign: "left" }}>{title}</p>
                </div>
                <svg style={{ fontSize: "24px", color: "#fff", margin: "auto", marginRight: "0px", transform: `rotate(${selected == pos ? 180 : 0}deg)` }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                </svg>
            </div>
            {selected == pos && content}
        </div>
    )
}