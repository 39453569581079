import "./style.scss";
import React, { useState } from "react";
import Unstake from "./shared/unstake.js";
import Rewards from "./shared/rewards.js";
import Stake from "./shared/stake.js";

const StakeEarn = ({
  lock,
  setlock,
  apr,
  price,
  stake,
  priceSOL,
  priceSOLUSD,
  amounts,
  rewards,
  balance,
  unstake,
  harvest,
  TOKENA,
  TOKENB,
}) => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    <>
      {/*<div className="group-tabs">
        <div className="tabs">
          <div
            className={`tab tab-1 ${activeTab === 1 ? "active" : ""}`}
            onClick={() => handleTabClick(1)}
          >
            Stake
          </div>
          <div
            className={`tab tab-2 ${activeTab === 2 ? "active" : ""}`}
            onClick={() => handleTabClick(2)}
          >
            Unstake
          </div>
          <div
            className={`tab tab-3 ${activeTab === 3 ? "active" : ""}`}
            onClick={() => handleTabClick(3)}
          >
            Rewards
          </div>
        </div>

        <div className="form tab-container">
          {activeTab === 1 && <Stake
            lock={lock}
            setlock={setlock}
            apr={apr}
            price={price}
            stake={stake}
            balance={balance}
            priceSOL={priceSOL}
            priceSOLUSD={priceSOLUSD}
            TOKENA={TOKENA} TOKENB={TOKENB}
          />}
          {activeTab === 2 && <Unstake
            lock={lock}
            setlock={setlock}
            apr={apr}
            price={price}
            stake={stake}
            priceSOL={priceSOL}
            priceSOLUSD={priceSOLUSD}
            amounts={amounts}
            unstake={unstake}
            TOKENA={TOKENA} TOKENB={TOKENB}
          />}
          {activeTab === 3 && <Rewards
            lock={lock}
            setlock={setlock}
            apr={apr}
            price={price}
            stake={stake}
            priceSOL={priceSOL}
            priceSOLUSD={priceSOLUSD}
            reward={rewards}
            harvest={harvest}
            TOKENA={TOKENA} TOKENB={TOKENB}
          />}
        </div>
      </div>*/}
      <div className="row">
        <div className="col col-7">
          <div className="group-wallet__title-card">Stake</div>
          <div className="form">
            <Stake
              lock={lock}
              setlock={setlock}
              apr={apr}
              price={price}
              stake={stake}
              balance={balance}
              priceSOL={priceSOL}
              priceSOLUSD={priceSOLUSD}
              TOKENA={TOKENA}
              TOKENB={TOKENB}
            />
          </div>
        </div>
        <div className="col col-5 p-0">
          <div className="row">
            <div className="col col-12">
              <div className="group-wallet__title-card">Unstake</div>
              <div className="form">
                <Unstake
                  lock={lock}
                  setlock={setlock}
                  apr={apr}
                  price={price}
                  stake={stake}
                  priceSOL={priceSOL}
                  priceSOLUSD={priceSOLUSD}
                  amounts={amounts}
                  unstake={unstake}
                  TOKENA={TOKENA}
                  TOKENB={TOKENB}
                />
              </div>
            </div>
            <div className="col col-12">
              <div className="group-wallet__title-card">Rewards</div>
              <div className="form">
                <Rewards
                  lock={lock}
                  setlock={setlock}
                  apr={apr}
                  price={price}
                  stake={stake}
                  priceSOL={priceSOL}
                  priceSOLUSD={priceSOLUSD}
                  reward={rewards}
                  harvest={harvest}
                  TOKENA={TOKENA}
                  TOKENB={TOKENB}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StakeEarn;
