import React, { useContext, useEffect, useState } from "react";
import InputBlock from "../../components/Launchpad/InputBlock.js";
import Button from "../../components/Launchpad/Button.js";
import { BN } from "@coral-xyz/anchor";
import { balanceOf, getToken, nextblock, returnbignumber } from "../../programs/utils.js";
import AppContext from "../../framework/helpers/AppContext.js";

const BurnTokenComponent = () => {
  const {
    setnet,
    netid
  } = useContext(AppContext)
  useEffect(() => {
    if (netid == "tron") {
      setnet("solana")
      window.location.reload()
    }
  }, [])
  const [step, setstep] = useState(0);
  const [mint, setMint] = useState(undefined);
  const [burnAmount, setBurnAmount] = useState(0);
  const [balance, setBalance] = useState(0);
  const [decimals, setDecimals] = useState(6);

  function stepCurrent() {
    return step;
  }

  async function burnSpl() {
    const { SplBuilder } = require("../../programs/programs.js");
    const builder = new SplBuilder();

    builder.asyncburn(mint, new BN(returnbignumber(burnAmount, decimals)));
  }
  return (
    <>
      <div className="text-title">Solpad Metadata Tool</div>
      <div className="text-sub-title">
        Edit your token Metadata on the Solana chain
      </div>
      {stepCurrent() === 0 && (
        /*-step-1------------------------------------------------------------------------------*/
        <>
          <div className="form">
            <div className="row">
              <div className="col col-7">
                <InputBlock
                  title={"Token Address"}
                  place={"Type the token address"}
                  variavel={mint}
                  setvariavel={async (ct) => {
                    try {
                      if (ct?.toString().trim().length < 0) {
                      } else {
                        setMint(ct?.toString().trim());
                        const tokenData = await getToken(ct?.toString().trim());
                        setDecimals(tokenData.decimals);

                        if (window.solpadContext.account != undefined) {
                          if (window.solpadContext.account) {
                            const bal = await balanceOf(ct?.toString().trim(), window.solpadContext.account)
                            setBalance(bal)
                          }
                        }
                      }
                    } catch (error) {
                      //console.log(error)
                    }
                  }}
                  desc={""}
                />
              </div>
              <div className="col" style={{ display: "grid", gridTemplateColumns: "auto 50px", width: "100%" }}>
                <InputBlock
                  title={"Burn Amount"}
                  place={"Burn Amount"}
                  variavel={burnAmount}
                  setvariavel={setBurnAmount}
                  desc={""}
                />
                <div
                  style={{ height: "100%", display: "flex", justifyContent: "center", cursor: "pointer", marginTop: "30px", cursor: "pointer" }}
                  onClick={() => {
                    setBurnAmount(nextblock(balance, decimals))
                  }}
                >
                  MAX
                </div>
              </div>
            </div>
          </div>
          <div className="group-buttons">
            <Button
              func={() => {
                burnSpl();
              }}
              text={"Burn"}
              next={true}
              className="next"
            />
          </div>
        </>
      )}
    </>
  );
};

export default BurnTokenComponent;
