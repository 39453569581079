import "../style.scss";
import assets from "../../../../../assets/index";
import Button from "../../../../../components/Launchpad/Button.js";
import React, { useState } from "react";

const Unstake = ({
  lock,
  setlock,
  apr,
  price,
  stake,
  priceSOL,
  unstake,
  priceSOLUSD,
  amounts,
  reward,
  TOKENA,
  TOKENB,
}) => {
  const [image, setImage] = useState("sol");
  const theme = () => {
    let img = localStorage.getItem("theme");

    if (img !== image) {
      setImage(img);
    }
  };
  theme();

  return (
    <>
      <div className="row">
        <div className="col col-4">
          <div className="tab-title">10% </div>
          <div className="tab-subtitle">Withdraw Fee Until</div>
        </div>
        <div className="col col-8">
          <div className="tab-content__item">
            {image === "sol" && (
              <img src={`${assets.images.logoSmall}`} alt="" />
            )}
            {image === "trx" && (
              <img
                src={`${assets.images.logoSmallTrx}`}
                loading="lazy"
                alt=""
              />
            )}
            {image === "bnb" && (
              <img
                src={`${assets.images.logoSmallBnb}`}
                loading="lazy"
                alt=""
              />
            )}
            <div className="tab-content__item--chain">
              <div className="tab-content__item--chain__title">${TOKENA}</div>
              <div className="tab-content__item--chain__value">
                {amounts[1]}
              </div>
            </div>
          </div>
        </div>
        <div className="col col-12">
          <Button
            func={async () => {
              await unstake(1);
            }}
            text={"Unstake"}
            className="unstake"
          />
        </div>
      </div>

      {/*<div className="row align-items-center">
        <div className="col col-4">
          <div className="tab-title">10% </div>
          <div className="tab-subtitle">Withdraw Fee Until</div>
        </div>
        <div className="col col-8 tab-content">
          <div className="tab-content__item">
            <img src={`${assets.images.logoSmall}`} alt="" />
            <div className="tab-content__item--chain">
              <div className="tab-content__item--chain__title">${TOKENA}</div>
              <div className="tab-content__item--chain__value">{amounts[1]}</div>
            </div>
            <Button  func={async ()=>{await unstake(1)}} text={"Unstake"} className="unstake" />
          </div>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col col-4">
          <div className="tab-title">1 Month | Paper hand</div>
          <div className="tab-subtitle">10% Withdraw Fee Until</div>
        </div>
        <div className="col col-8 tab-content">
          <div className="tab-content__item">
            <img src={`${assets.images.logoSmall}`} alt="" />
            <div className="tab-content__item--chain">
              <div className="tab-content__item--chain__title">${TOKENA}</div>
              <div className="tab-content__item--chain__value">{amounts[1]}</div>
            </div>
            <Button  func={async ()=>{await unstake(1)}} text={"Unstake"} className="unstake" />
          </div>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col col-4">
          <div className="tab-title">3 Month | Paper hand</div>
          <div className="tab-subtitle">15% Withdraw Fee Until</div>
        </div>
        <div className="col col-8 tab-content">
          <div className="tab-content__item">
            <img src={`${assets.images.logoSmall}`} alt="" />
            <div className="tab-content__item--chain">
              <div className="tab-content__item--chain__title">${TOKENA}</div>
              <div className="tab-content__item--chain__value">{amounts[3]}</div>
            </div>
            <Button  func={async ()=>{await unstake(3)}}  text={"Unstake"} className="unstake" />
          </div>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col col-4">
          <div className="tab-title">6 Month | Paper hand</div>
          <div className="tab-subtitle">20% Withdraw Fee Until</div>
        </div>
        <div className="col col-8 tab-content">
          <div className="tab-content__item">
            <img src={`${assets.images.logoSmall}`} alt="" />
            <div className="tab-content__item--chain">
              <div className="tab-content__item--chain__title">${TOKENA}</div>
              <div className="tab-content__item--chain__value">{amounts[6]}</div>
            </div>
            <Button  func={async ()=>{await unstake(6)}}  text={"Unstake"} className="unstake" />
          </div>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col col-4">
          <div className="tab-title">12 Month | Paper hand</div>
          <div className="tab-subtitle">25% Withdraw Fee Until</div>
        </div>
        <div className="col col-8 tab-content">
          <div className="tab-content__item">
            <img src={`${assets.images.logoSmall}`} alt="" />
            <div className="tab-content__item--chain">
              <div className="tab-content__item--chain__title">${TOKENA}</div>
              <div className="tab-content__item--chain__value">{amounts[12]}</div>
            </div>
            <Button  func={async ()=>{await unstake(12)}}  text={"Unstake"} className="unstake" />
          </div>
        </div>
      </div>*/}
    </>
  );
};

export default Unstake;
