import React from "react";
import ProjectData from "../../config";

const {
  Name,
  Logo,
  backgroundColor,
  buttonsColor,
  buttonsBorders,
  inputsColor,
  inputsBorders,
  outbuttonsColor,
  Borders,
  outFont,
  innerFont,
  Routers,
} = ProjectData;

function DescBlock(props) {
  return (
    <div className="group-input">
      <div className="title-input">{props.title}</div>
      <textarea
        rows="5"
        id={props.id != undefined ? props.id : ""}
        maxlength={props.max == undefined ? 512 : props.max}
        onChange={(e) => {
          props.setvariavel(e.target.value);
        }}
        placeholder={props.place}
        value={props.variavel}
      ></textarea>
      <p>{props.desc}</p>
    </div>
  );
}

export default DescBlock;
