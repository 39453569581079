import React, { useEffect } from "react";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { formatNumber, nextblock, programNetwork, quote, returnbignumber } from "../../programs/utils";

export const ProgressBar = ({ object, typeSALE, price }) => {
 // console.log(price)
  useEffect(() => { }, [object]);


  function rightValue() {
    if (typeSALE == "Fair Launch") {
      return nextblock(object.softcap, object.quoteDecimals);
    }
    if (object.hardcap == undefined) {
      return "0";
    }
    return nextblock(object.hardcap, object.quoteDecimals);
  }
  function leftValue() {

    // console.log(object.bal, getFinish(object.bal), object.balance)
    return nextblock(
      object.balance > 0 ? getFinish(object.balance) :  object.quoteBalance,
      object.quoteDecimals
    );
  }
  function getFinish(_bal) {
    if (typeSALE == "Fair Launch") {
      return _bal;
    }
    const tokensbuy =
      parseFloat(nextblock(object.avaliable, object.saleDecimals)) * 1;
    const prerate =
      parseFloat(nextblock(object.presale_rate, object.saleDecimals)) * 1;

    return returnbignumber(
      parseFloat((tokensbuy / prerate).toFixed(object.quoteDecimals)),
      object.quoteDecimals
    );
  }

  function progress() {


    if (typeSALE == "Fair Launch") {
      if (object.softcap == undefined) {
        return "0";
      }
    } else {
      if (object.hardcap == undefined) {
        return "0";
      }
    }

    return (
      (nextblock(
        object.balance > 0 ? getFinish(object.balance) : object.quoteBalance,
        object.quoteDecimals
      ) /
        nextblock(typeSALE == "Fair Launch" ? object.softcap : object.hardcap, object.quoteDecimals)) *
      100
    ).toString();
  }

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    },
  }));


//console.log(  rightValue()  * parseFloat(price))
  return (
    <>
      <div className="group-progress">
        <div className="group-progress__progress">
          <div className="label">Progress</div>
          <div className="percentage">
            {parseFloat(progress()) > 100
              ? typeSALE == "Fair Launch" ? parseFloat(progress()) + "%" : "100%"
              : parseFloat(progress()).toFixed(0) + "%"}
          </div>
        </div>
        <BorderLinearProgress variant="determinate" value={parseFloat(progress()) > 100 ? 100 : parseFloat(progress())} />
        <div className="group-progress__values">
          <div className="min">
            {leftValue() +
              (quote(programNetwork(object.program).name, object.utoken))}
            <br />
            <p>{"$" + formatNumber(leftValue() * price)}</p>
          </div>
          <div className="max">
            {" "}
            {typeSALE != "Fair Launch" && (
              <>
                {rightValue() +
                  (quote(programNetwork(object.program).name, object.utoken))}
                <br />
                <p>{"$" + formatNumber(rightValue() * parseFloat(price))}</p>
              </>
            )}
            {typeSALE == "Fair Launch" && (
              <>
                {rightValue() +
                  (quote(programNetwork(object.program).name, object.utoken))}

                <br />
                <p>
                  {"$" +
                    formatNumber(
                      rightValue()  * parseFloat(price)
                    )}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
