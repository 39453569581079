import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import SAFE from "../../../components/Launchpad/textpar.js";
import InputBlock from "../../../components/Launchpad/InputBlock.js";
import Button from "../../../components/Launchpad/Button.js";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import enUS from "date-fns/locale/en-US";
import "react-datepicker/dist/react-datepicker.css";
import { nextblock } from "../../../programs/utils.js";

const Web3 = require("web3-eth");
registerLocale("en-US", enUS);
const UpdateTokenLock = (props) => {
  const [_ct, setCt] = useState("");
  const [owner, setowner] = useState("");
  const [time, settime] = useState("");
  const [name, setName] = useState("");
  const [amount, setamount] = useState(0);
  const [_data, setdata] = useState({});
  const [tokenBname, setTokenBname] = useState("");
  const [tokenBsymbol, setTokenBsymbol] = useState("");
  const [tokenBdec, setTokenBdec] = useState(0);
  const [tokenBtotal, setTokenBtotalSupply] = useState(0);
  const [tokenBAllow, setTokenBAllow] = useState(0);

  const [endTime, setendTime] = useState(_toUTC(new Date()));

  useEffect(() => {
    const aa = async () => {
      const a = await props.getlock(props.posi);
      const aba = await props.getToken(a.spltoken, props.netid);
      setTokenBdec(aba.decimals);
      setTokenBsymbol(aba.symbol);
      setCt(aba.address);
      setamount(a.amount);
      settime(a.endtime);
      setowner(a.owner);
      setdata(a);
      setamount(a.amount);
      settime(a.endTime);
      setowner(a.owner);
    };
    setTimeout(() => {
      aa();
    }, 10000);
  }, []);

  function nf(e) {
    return ("0" + e).slice(-2);
  }
  function toUTC(func, dateA) {
    func(dateA);
  }
  function _toUTC(dateA) {
    const time = new Date(dateA);
    const _time = new Date(
      dateA.getUTCFullYear() +
        "-" +
        nf(dateA.getUTCMonth() + 1) +
        "-" +
        nf(dateA.getUTCDate()) +
        "T" +
        nf(dateA.getUTCHours()) +
        ":" +
        nf(dateA.getUTCMinutes()) +
        ":00"
    );

    return new Date(Date.now());
  }
  
  
  async function _update() {
    const end = parseInt(Date.parse(endTime) / 1000);
    props.uplock(props.posi, name, amount, end);
  }

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} onClick={onClick} className="date-picker">
      <p style={{ margin: "auto" }}>
        {new Date(value).toUTCString().replace("GMT", "UTC")}
      </p>
    </button>
  ));

  return (
    <>
      <div className="form">
        <div className="row">
          <div className="col col-12">
            <InputBlock
              title={"New Lock Name"}
              place={""}
              variavel={name}
              setvariavel={setName}
              desc={""}
            />
          </div>
        </div>

        <div className="row">
          <div className="col col-6">
            <InputBlock
              title={"New amount in lock *"}
              place={"0"}
              variavel={amount}
              setvariavel={setamount}
              desc={""}
            />
          </div>

          <div className="col col-6">
            <div className="title-input">End time</div>
            <DatePicker
              selected={endTime}
              onChange={(date) => toUTC(setendTime, date)}
              touchUi={true}
              showTimeSelect
              timeFormat="HH:mm aa"
              timeIntervals={5}
              timeCaption="time"
              dateFormat="MMMM d, yyyy h:mm aa"
              customInput={<CustomInput />}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col col-6">
          <SAFE what={"Old data"} result={"-"} />
        </div>
        <div className="col col-6">
          <SAFE what={"lock Name:"} result={_data.name ? _data.name : "-"} />
        </div>
      </div>
      <div className="row">
        <div className="col col-6">
          <SAFE
            what={"Locked Amount"}
            result={_data.amount ? nextblock(_data.amount, tokenBdec) : "-"}
          />
        </div>
        <div className="col col-6">
          <SAFE
            what={"Finish in"}
            result={
              _data.endTime
                ? new Date(parseInt(_data.endTime) * 1000).toString()
                : "-"
            }
          />
        </div>
      </div>

      <div className="group-buttons">
        <Button
          func={() => {
            _update();
          }}
          text={"Update"}
          next={true}
          className={"next"}
        />
      </div>
    </>
  );
};
export default UpdateTokenLock;
