import React, { useState } from "react";
import "../../style.scss";

const HistoryWrapper = ({TOKENA, TOKENB, historic}) => {
  /*const historic = [
    {
      type: "Stake",
      Lock: 1,
      Date: parseInt(Date.now() / 1000),
      Asset: "A",
      Amount: 100
    },
    {
      type: "Remove",
      Lock: 3,
      Date: parseInt(Date.now() / 1000),
      Asset: "A",
      Amount: 100
    },
    {
      type: "Claim",
      Lock: 12,
      Date: parseInt(Date.now() / 1000),
      Asset: "B",
      Amount: 100
    }
  ]*/
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  function convertTimestamp(timestamp) {
    // Create a Date object from the timestamp
    const date = new Date(timestamp * 1000);
    
    // Get month, date, hours, minutes, and seconds
    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    
    // Format the date as "mes DD HH:MM:SS"
    const formattedDate = `${month} ${day} ${hours}:${minutes}:${seconds}`;
    
    return formattedDate;
}
  const HistoryModal = ({ type, Lock, Date, Amount }) => {
    const color = type == "Stake" ? "#9b9b9b" : type == "Remove" ? "#d72a28" : "#44afc3"
    const token = type == "Claim" ? TOKENB : TOKENA
   
    return (
      <tr className="tabs">
        <th className="tab" style={{ background: color, color:"#fff", borderRadius:"8px", fontSize:"11px" }}>{type}</th>
      { /* <th className="tab"style={{ color, fontSize:"11px", }}>{Lock}</th>*/}
        <th className="tab" style={{ color, fontSize:"11px", }}>{convertTimestamp(Date)}</th>
        <th className="tab"style={{ color, fontSize:"11px", }}>{token}</th>
        <th className="tab"style={{ color, fontSize:"11px", }}>{Amount}</th>
      </tr>)
  }
  return (
    <>
      <div className="group-wallet__title-card">Stake History</div>
      <div className="form group-tabs">
        <div className="tabs">
          <div
            className={`tab ${activeTab === 1 ? "active" : ""}`}
            onClick={() => handleTabClick(1)}
          >
            Today
          </div>
          <div
            className={`tab ${activeTab === 2 ? "active" : ""}`}
            onClick={() => handleTabClick(2)}
          >
            7 days
          </div>
          <div
            className={`tab ${activeTab === 3 ? "active" : ""}`}
            onClick={() => handleTabClick(3)}
          >
            30 days
          </div>
          <div
            className={`tab ${activeTab === 4 ? "active" : ""}`}
            onClick={() => handleTabClick(4)}
          >
            30 days
          </div>
        </div>

        <div className="table-history">
          <table>
            <thead className="group-his" >
              <tr className="tabs" style={{border:"none"}}>
                <th className="tab">Type</th>
           { /*    <th className="tab">Lock</th>*/}
                <th className="tab">Date</th>
                <th className="tab">Asset</th>
                <th className="tab">Amount</th>
              </tr>
            </thead>
            <tbody className="group-his">{/* Add table rows here */}
              {historic.map((e, i) => <HistoryModal
                type={e.types == 0 ? "Stake"  : e.types == 1 ? "Remove": "Claim"}
                Lock={e.lock}
                Date={e.since}
                Amount={e.Amount}
              />)}
              
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default HistoryWrapper;
