import React from "react";

function IconTelegram(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
    >
      <path
        d="M19.5645 4.06335C20.1108 4.06335 20.5229 4.54252 20.3025 5.43377L17.6287 18.0359C17.4418 18.9319 16.9004 19.1475 16.1529 18.7306L9.77517 14.0204C9.75044 14.0027 9.73029 13.9793 9.71639 13.9522C9.70249 13.9251 9.69524 13.8951 9.69524 13.8647C9.69524 13.8343 9.70249 13.8043 9.71639 13.7772C9.73029 13.7501 9.75044 13.7267 9.77517 13.709L17.14 7.05815C17.4754 6.76106 17.0681 6.61731 16.6273 6.88565L7.38413 12.7171C7.3561 12.7354 7.32427 12.747 7.29106 12.7512C7.25786 12.7553 7.22414 12.7519 7.19247 12.7411L3.26809 11.5C2.39601 11.2461 2.39601 10.6471 3.46455 10.2206L19.1668 4.16398C19.2914 4.10424 19.4266 4.07002 19.5645 4.06335Z"
        stroke="#ececec"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default IconTelegram;
