import React from "react";

function IconWebsite(props) {
  return (
    <svg
      stroke="#ececec"
      fill="none"
      viewBox="0 0 24 24"
      height="1em"
      style={{
        fill: "#ffffff00",
        height: "23px",
        width: "23px",
      }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={12} cy={12} r={10} />
      <line x1={2} y1={12} x2={22} y2={12} />
      <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z" />
    </svg>
  );
}

export default IconWebsite;
