import React, { useState, useEffect, useContext } from "react";
import { ethers } from "ethers";
import SAFE from "../../../components/Launchpad/textpar.js";
import InputBlock from "../../../components/Launchpad/InputBlock.js";
import Button from "../../../components/Launchpad/Button.js";
import Check from "../../../components/Launchpad/Checkbox.js";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import enUS from "date-fns/locale/en-US";
import "react-datepicker/dist/react-datepicker.css";
import env from "../../../hooks/data/Config.js";
import { nextblock, returnbignumber } from "../../../programs/utils.js";

const Web3 = require("web3-eth");
registerLocale("en-US", enUS);
const TokenLockCreator = (props) => {
  const groupCreated = {
    background: "#0c091c",
    padding: "10px 20px",
    margin: "10px 0",
    width: "100%",
  };

  const simpleLabel = {
    fontSize: "1rem",
    marginBottom: "5px",
    color: "#d69799",
  };
  const groupDateCreated = {
    height: "min-content",
    width: "100%",
    display: "grid",
    gridTemplateColumns: "auto",
    gap: "5px",
    margin: "10px 0",
  };
  const groupCreatedDisplayFlex = {
    display: "flex",
    gap: "20px",
  };

  const groupButtonDisplayFlex = {
    display: "flex",
    gap: "30px",
    justifyContent: "flex-end",
    marginTop: "30px",
  };

  const { Token, Lock, getTokenAddress, getToken, account, PublicKey, BN } =
    window.solpadContext;

  const [_poolfee, setpoofee] = useState(0);
  const [tokenB, setTokenB] = useState({
    ct: undefined,
    name: "",
    symbol: "",
    dec: 0,
    supply: "",
    allow: 0,
  });
  const [address, connector] = useState(account);
  const [tokenBCT, setTokenBCT] = useState(undefined);
  const [name, setName] = useState(undefined);
  const [amountTo, setAmountTo] = useState(0);
  const [endTime, setendTime] = useState(_toUTC(new Date()));
  const [cycleReleasePercent, setCycleReleasePercent] = useState(0);
  const [cycle, setCycle] = useState(0);
  const [tgePercent, setTgePercent] = useState(0);
  const [tgeDate, setTgeDate] = useState(_toUTC(new Date()));
  const [step, setstep] = useState(0);
  const [ovesting, setovesting] = useState(0);
  const typename = props.type == 0 ? "Token " : "Liquidity";
  useEffect(() => {
    setendTime(_toUTC(new Date()));
  }, []);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
      // _gettoken(tokenB.ct);
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);

  useEffect(() => {
    _gettoken(tokenBCT);
  }, [tokenBCT]);
  const lock = new Lock(env.lockContract, env.lockStorage);
  if (Lock == undefined) {
    return <></>;
  }

  function nf(e) {
    return ("0" + e).slice(-2);
  }
  function toUTC(func, dateA) {
    func(dateA);
  }
  function _toUTC(dateA) {
    const time = new Date(dateA);
    const _time = new Date(
      dateA.getUTCFullYear() +
        "-" +
        nf(dateA.getUTCMonth() + 1) +
        "-" +
        nf(dateA.getUTCDate()) +
        "T" +
        nf(dateA.getUTCHours()) +
        ":" +
        nf(dateA.getUTCMinutes()) +
        ":00"
    );

    return new Date(Date.now());
  }
  function alltoBN(d) {
    let a = [];
    for (let index = 0; index < d.length; index++) {
      a.push(new BN(d[index]));
    }
    return a;
  }

  async function create() {
    const { getTokenAddress, account, PublicKey, BN } = window.solpadContext;

    const address = account;
    const end = parseInt(Date.parse(endTime) / 1000);
    const _tgeDate = parseInt(Date.parse(tgeDate) / 1000);
    const from = await getTokenAddress(tokenB.ct, address);
    const to = await lock.receiveToken(tokenB.ct);
    const extras = await lock.testAccountsToken(tokenB.ct, [
      address,
      lock.bank,
    ]);
    try {
      await lock.write(
        ...extras,
        /*  await lock.callApprove(
          tokenB.ct,
          new BN(returnbignumber(amountTo, tokenB.decimals))
        ),*/
        await lock.send(
          "newLock",
          {
            fromWallet: from,
            programWallet: to,
            _locker: new PublicKey(address),
            _contract: new PublicKey(tokenB.ct),
          },
          name,
          new BN(returnbignumber(amountTo, tokenB.decimals)),
          new BN(end),
          new BN(props.type),
          new BN(ovesting),

          Array.from(
            alltoBN([_tgeDate, tgePercent, cycle, cycleReleasePercent])
          )
        )
      );
    } catch (error) {
      console.log(error);
    }
  }
  async function approve() {
    await lock.callApprove(
      tokenB.ct,
      new BN(returnbignumber(amountTo, tokenB.decimals))
    );
    /*nSendTX(tokenB.ct, bnbabi, getID(), 'approve', '0', 0, contract, '1' + '0'.repeat(50)).then(a => {
            gettoken(tokenBCT)
        }).catch(e => {

        })*/
  }

  async function _gettoken(ct) {
    try {
      if (ct.toString().trim().length < 0) {
      } else {
        setTokenBCT(ct.toString().trim());
        const obj = await getToken(ct.toString().trim());

        obj.ct = ct.toString().trim();
        obj.allow = 10000000n;
        setTokenB(obj);
      }
    } catch (error) {
      //console.log(error)
    }
  }

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <button
      ref={ref}
      onClick={onClick}
      style={{
        cursor: "pointer",
        fontSize: "15px",
        outline: "none",
        color: "#fff",
        textAlign: "left",
        height: "35px",
        width: "100%",
        display: "grid",
        gridTemplateColumns: "auto",
        border: "1px solid #ffffff20",
        background: "#ffffff00",
        borderRadius: "2px",
        paddingLeft: "10px",
        paddingTop: "10px",
      }}
    >
      <p style={{ margin: "auto" }}>
        {new Date(value).toUTCString().replace("GMT", "UTC")}
      </p>
    </button>
  ));

  function nextstep() {
    setstep(step + 1);
  }

  function stepCurrent() {
    return step;
  }

  return (
    <>
      <div className="text-title">Create {typename} Lock</div>

      <div className="group-create">
        <div className="time-line">
          <div className="group-step">
            <div className="line custom_lock"></div>
            <div
              className={
                stepCurrent() === 0 || stepCurrent() > 0
                  ? "step-actived"
                  : "step"
              }
            >
              <div className="step-number">1</div>
              <div className="step-title">
                Verify {typename}
                <br /> <p>Enter the {typename} address and verify</p>
              </div>
            </div>
            <div
              className={
                stepCurrent() === 1 || stepCurrent() > 1
                  ? "step-actived"
                  : "step"
              }
            >
              <div className="step-number">2</div>
              <div className="step-title">
                DeFi Launchpad <br /> <p>Enter the locker information</p>
              </div>
            </div>
            <div
              className={
                stepCurrent() === 2 || stepCurrent() > 2
                  ? "step-actived"
                  : "step"
              }
            >
              <div className="step-number">3</div>
              <div className="step-title">
                Vesting <br /> <p>Enter the vesting owner information</p>
              </div>
            </div>
          </div>
        </div>
        <div className="form">
          {stepCurrent() === 0 && (
            /*-step-1------------------------------------------------------------------------------*/
            <>
              <div className="title-form">
                <div className="title">Verify {typename}</div>
                <p>Enter the {typename} address and verify</p>
              </div>
              <div className="title-step">
                Do you want to create {typename} lock
              </div>
              <p className="sub-title-step">
                Going through the onboarding process is not necessary
              </p>
              <div className="groupCreated">
                <InputBlock
                  title={typename + " Address *"}
                  place={"123..."}
                  variavel={tokenBCT}
                  setvariavel={_gettoken}
                  desc={""}
                />
              </div>

              <div className="groupButtonDisplayFlex">
                <Button
                  func={() => {
                    nextstep();
                  }}
                  text={"Next"}
                  next={true}
                />
              </div>
            </>
          )}
          {stepCurrent() === 1 && (
            /*-step-1------------------------------------------------------------------------------*/
            <>
              <div className="title-form">
                <div className="title">DeFi Launchpad</div>
                <p>Enter the locker information</p>
              </div>
              <div className="title-step">Create Your Locks for Free</div>
              <div className="groupCreatedDisplayFlex">
                <div className="groupCreated">
                  <SAFE
                    what={"Name"}
                    result={tokenB.name ? tokenB.name : "-"}
                  />
                  <SAFE
                    what={"Symbol"}
                    result={tokenB.symbol ? tokenB.symbol : "-"}
                  />
                </div>
                <div className="groupCreated">
                  <SAFE
                    what={"Decimals"}
                    result={tokenB.decimals ? tokenB.decimals : "-"}
                  />
                  <SAFE
                    what={"Supply"}
                    result={nextblock(tokenB.supply, tokenB.decimals)}
                  />
                </div>
              </div>

              <div className="groupCreatedDisplayFlex">
                <div className="groupCreated">
                  <InputBlock
                    title={"Title"}
                    place={""}
                    variavel={name}
                    setvariavel={setName}
                    desc={""}
                  />
                </div>
              </div>

              <div className="groupCreatedDisplayFlex">
                <div className="groupCreated">
                  <InputBlock
                    title={"Amount"}
                    place={"0"}
                    variavel={amountTo}
                    setvariavel={setAmountTo}
                    desc={""}
                  />
                </div>
                <div className="groupCreated">
                  <div style={groupDateCreated}>
                    <div style={simpleLabel}>End time</div>
                    <DatePicker
                      selected={endTime}
                      onChange={(date) => toUTC(setendTime, date)}
                      touchUi={true}
                      showTimeSelect
                      timeFormat="HH:mm aa"
                      timeIntervals={5}
                      timeCaption="time"
                      dateFormat="MMMM d, yyyy h:mm aa"
                      customInput={<CustomInput />}
                    />
                  </div>
                </div>
              </div>

              <div className="groupButtonDisplayFlex">
                <Button
                  func={() => {
                    setstep(step - 1);
                  }}
                  text={"Back"}
                  next={false}
                  className="buttonBack"
                />
                <Button
                  func={() => {
                    nextstep();
                  }}
                  text={"Next"}
                  next={true}
                />
              </div>
            </>
          )}
          {stepCurrent() === 2 && (
            /*-step-1------------------------------------------------------------------------------*/
            <>
              <div className="title-form">
                <div className="title">Vesting</div>
                <p>Enter the vesting owner information</p>
              </div>
              <div className="title-step">User Vesting?</div>
              <div style={groupCreated}>
                <div style={simpleLabel}>Enable?</div>
                <div style={groupCreatedDisplayFlex}>
                  <Check
                    title={"Disable"}
                    id={0}
                    variavel={ovesting}
                    setvariavel={setovesting}
                  />
                  <Check
                    title={"Enable"}
                    id={1}
                    variavel={ovesting}
                    setvariavel={setovesting}
                  />
                </div>
              </div>
              <div className="groupCreatedDisplayFlex">
                <div className="groupCreated">
                  <div style={simpleLabel}>TGE Date (UTC Time)</div>
                  <DatePicker
                    selected={tgeDate}
                    onChange={(date) => toUTC(setTgeDate, date)}
                    touchUi={true}
                    showTimeSelect
                    timeFormat="HH:mm aa"
                    timeIntervals={5}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    customInput={<CustomInput time={tgeDate} />}
                  />
                </div>

                <div className="groupCreated">
                  <InputBlock
                    title={"TGE Percent"}
                    place={"0"}
                    variavel={tgePercent}
                    setvariavel={setTgePercent}
                    desc={""}
                  />
                </div>
              </div>

              <div className="groupCreatedDisplayFlex">
                <div className="groupCreated">
                  <InputBlock
                    title={"Cycle (minutes)"}
                    place={"0"}
                    variavel={cycle}
                    setvariavel={setCycle}
                    desc={""}
                  />
                </div>
                <div className="groupCreated">
                  <InputBlock
                    title={"Cycle Release Percent"}
                    place={"0"}
                    variavel={cycleReleasePercent}
                    setvariavel={setCycleReleasePercent}
                    desc={""}
                  />
                </div>
              </div>

              <div className="groupButtonDisplayFlex">
                <Button
                  func={() => {
                    setstep(step - 1);
                  }}
                  text={"Back"}
                  next={false}
                  className="buttonBack"
                />
                <Button
                  func={() => {
                    create();
                  }}
                  text={"Create"}
                  next={true}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default TokenLockCreator;
