import React from "react";
import "./styles.scss";
import CircularProgress from "@mui/material/CircularProgress";

const LoadingComponent = (props) => {
  return (
    <div className="loading-icon">
      <CircularProgress color="success" sx={{ fontSize: "1rem" }} />
    </div>
  );
};

export default LoadingComponent;
