import React, { useState, useMemo } from "react";
import { WalletDisconnectedError, WalletNotFoundError } from '@tronweb3/tronwallet-abstract-adapter';
import { useWallet, WalletProvider } from '@tronweb3/tronwallet-adapter-react-hooks';
import {
    WalletActionButton,
    WalletConnectButton,
    WalletDisconnectButton,
    WalletModalProvider,
    WalletSelectButton,
} from '@tronweb3/tronwallet-adapter-react-ui';
//import toast from 'react-hot-toast';
import { BitKeepAdapter, OkxWalletAdapter, TokenPocketAdapter, TronLinkAdapter } from '@tronweb3/tronwallet-adapters';
import { WalletConnectAdapter } from '@tronweb3/tronwallet-adapter-walletconnect';

import { LedgerAdapter } from '@tronweb3/tronwallet-adapter-ledger';
import { Button } from '@tronweb3/tronwallet-adapter-react-ui';
import TronConnection from './connection.js';
/*import TronWeb from 'tronweb';
const tronWeb = new TronWeb({
    fullHost: 'https://api.nileex.io',
});
window["tronWeb1"] = tronWeb;*/
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Alert } from '@mui/material';
import { Emit } from "../../../components/Alert/Alert.js";
const TronContent = ({ rpcs }) => {
    const [selected, setSelected] = useState(0);
    const [custom, setCustom] = useState(undefined);
    const [priority, setpriority] = useState(0);
    // const network = WalletAdapterNetwork.Mainnet

    const adapters = useMemo(function () {
        const tronLinkAdapter = new TronLinkAdapter();
        const walletConnectAdapter = new WalletConnectAdapter({
            network: 'Nile',
            options: {
                relayUrl: 'wss://relay.walletconnect.com',
                // example WC app project ID
                projectId: '5fc507d8fc7ae913fff0b8071c7df231',
                metadata: {
                    name: 'Test DApp',
                    description: 'JustLend WalletConnect',
                    url: 'https://your-dapp-url.org/',
                    icons: ['https://your-dapp-url.org/mainLogo.svg'],
                },
            },
            web3ModalConfig: {
                themeMode: 'dark',
                themeVariables: {
                    '--w3m-z-index': '1000'
                },
            }
        });
        const ledger = new LedgerAdapter({
            accountNumber: 2,
        });
        const bitKeepAdapter = new BitKeepAdapter();
        const tokenPocketAdapter = new TokenPocketAdapter();
        const okxwalletAdapter = new OkxWalletAdapter();
        return [tronLinkAdapter, bitKeepAdapter, tokenPocketAdapter, okxwalletAdapter, walletConnectAdapter, ledger];
    }, []);

   
    return (
        <WalletProvider
            onError={(e) => { Emit("Error", e, "error") }}
            autoConnect={true}
            disableAutoConnectOnLoad={true}
            adapters={adapters}
        >
            <WalletModalProvider>
              
                <TronConnection
                    selected={selected}
                    rpcs={rpcs}
                    select={setSelected}
                    setPriority={setpriority}
                    setCustom={setCustom}
                    custom={custom}
                />
            </WalletModalProvider>

        </WalletProvider>
    );
};
export default TronContent