import React, { useState } from "react";
import InputBlock from "../../../components/Launchpad/InputBlock.js";
import Button from "../../../components/Launchpad/Button.js";
import { registerLocale } from "react-datepicker";
import enUS from "date-fns/locale/en-US";
import "react-datepicker/dist/react-datepicker.css";

registerLocale("en-US", enUS);
const TransferTokenLock = (props) => {
  const [nOwner, setNowner] = useState("");

  async function _transfer() {
    props.transfer(nOwner);
  }

  return (
    <>
      <div className="form">
        <div className="row">
          <div className="col col-12">
            <InputBlock
              title={"New Owner *"}
              place={""}
              variavel={nOwner}
              setvariavel={setNowner}
              desc={""}
            />
          </div>
        </div>
      </div>

      <div className="group-buttons">
        <Button
          func={() => {
            _transfer();
          }}
          text={"Transfer"}
          next={true}
          className="next"
        />
      </div>
    </>
  );
};
export default TransferTokenLock;
