import React, { useEffect, useState } from "react";

import LoadingComponent from "../loading/loading.js";
import api from "../../programs/api.js";
import LatestModal from "../latestModal.js";

const LatestPresales = (props) => {
  const [loading, setLoading] = useState(true);

  const [List, setList] = useState([]);
  useEffect(() => {
    api.presale.list.get("?PageSize=3").then((result) => {
      setList(result);
      console.log(result);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <div className="latest-pools-container">
        <div className="content-latest">
          <div className="latest-pools-title">
            Latest <a href="/launchpads">Presales</a>
          </div>

          <div className="latest-pools-group-items">
            {loading && <LoadingComponent />}
            {!loading &&
              List.length > 0 &&
              List.sort((a, b) =>
                parseInt(b.createAt) > parseInt(a.createAt) ? 1 : -1
              ).map((e, i) => i < 3 && <LatestModal element={e} />)}
            {!loading && List.length == 0 && (
              <div className="no-data-card">No data found</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LatestPresales;
