import React, { useState, useMemo } from "react";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import {
  WalletAdapterNetwork
} from "@solana/wallet-adapter-base";
import {
  SolflareWalletAdapter,
  PhantomWalletAdapter,
  WalletConnectWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import {
  WalletModalProvider,
} from "@solana/wallet-adapter-react-ui";
import SolConnection from "./connection.js";


const SolanaContent = ({ rpcs }) => {
  //console.log("sdadsd")
    const [selected, setSelected] = useState(0);
    const [custom, setCustom] = useState(undefined);
    const [priority, setpriority] = useState(0);
    const network = WalletAdapterNetwork.Mainnet
    // WalletAdapterNetwork.Devnet;
    const endpoint = useMemo(
      () => (custom != undefined ? custom : rpcs[selected].url),
      [network]
    );
  //console.log(endpoint)
    const wallets = useMemo(
      () => [
        new SolflareWalletAdapter(),
        new PhantomWalletAdapter(),
        new WalletConnectWalletAdapter(),
      ],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [network]
    );
    //  console.log(wallets)
    return (
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletModalProvider>
            <SolConnection
              selected={selected}
              rpcs={rpcs}
              select={setSelected}
              setPriority={setpriority}
              setCustom={setCustom}
              custom={custom}
            />
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    );
  };
  export default SolanaContent