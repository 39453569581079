import React, { useState } from "react";
import "./styles.scss";
import { Dropdown } from "flowbite-react";
import ContrastIcon from "@mui/icons-material/Contrast";
import assets from "../../assets";

const ThemeComponent = () => {
  const [themeCurrent, setThemeCurrent] = useState("sol");

  function setTheme(theme) {
    const $root = document.getElementById("root");
    $root.classList.remove(themeCurrent);
    setThemeCurrent(theme);
    $root.classList.add(theme);
    localStorage.setItem("theme", theme);
  }

  const changeTheme = () => {
    let t = localStorage.getItem("theme");

    if (!t) {
      setTheme("sol");
    }
  };
  changeTheme();

  return (
    <Dropdown
      className="dropdown"
      label=""
      dismissOnClick={false}
      renderTrigger={() => <ContrastIcon style={{ cursor: "pointer" }} />}
    >
      <Dropdown.Item className="dropdown-item" onClick={() => setTheme("sol")}>
        <img
          src={`${assets.images.logoSmallSolana}`}
          loading="lazy"
          width={20}
          alt=""
        />{" "}
        SOL
      </Dropdown.Item>
      <Dropdown.Item className="dropdown-item" onClick={() => setTheme("trx")}>
        <img
          src={`${assets.images.logoSmallTron}`}
          loading="lazy"
          width={20}
          alt=""
        />{" "}
        TRX
      </Dropdown.Item>
      <Dropdown.Item className="dropdown-item" onClick={() => setTheme("bnb")}>
        <img
          src={`${assets.images.logoSmallEVM}`}
          loading="lazy"
          width={20}
          alt=""
        />{" "}
        BNB
      </Dropdown.Item>
    </Dropdown>
  );
};

export default ThemeComponent;
