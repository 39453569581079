import React from "react";

function IconMyContributions(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
    >
      <path
        d="M4.87678 12.522C5.28296 11.1995 6.04526 10.0144 7.08017 9.09633C6.51709 8.64623 7.08017 5.84833 7.08017 5.84833C7.08017 5.84833 9.3313 6.84521 9.73367 7.31162C9.73367 7.31162 11.47 5.2049 16.504 5.2049C21.5379 5.2049 26.2812 9.09694 26.2812 14.291C26.2812 19.485 22.5184 21.1416 22.5184 21.1416C22.3222 22.0581 22.0199 22.9486 21.6176 23.7951H19.6233C19.3051 23.3056 19.0205 22.7951 18.7714 22.2672C18.7714 22.2672 17.1794 22.4605 16.0375 22.4605C15.2329 22.4441 14.4312 22.358 13.6414 22.2031C13.5733 22.7688 13.381 23.3124 13.0783 23.7951H10.9076C10.2321 23.2163 9.89438 21.399 9.89438 21.399C9.89438 21.399 4.42667 18.9225 3.26849 17.4423C2.64076 16.2684 2.72111 13.6632 2.72111 13.6632C3.3291 13.1033 4.07144 12.71 4.87617 12.5214"
        stroke={props.color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.8079 8.32481C13.0963 7.39846 14.662 6.93899 16.2467 7.02223C17.9781 6.99374 19.6716 7.53012 21.0709 8.55017"
        stroke={props.color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.01562 13.3128C7.26588 13.3128 7.46875 13.1099 7.46875 12.8597C7.46875 12.6094 7.26588 12.4066 7.01562 12.4066C6.76537 12.4066 6.5625 12.6094 6.5625 12.8597C6.5625 13.1099 6.76537 13.3128 7.01562 13.3128Z"
        fill={props.color}
      />
    </svg>
  );
}

export default IconMyContributions;
