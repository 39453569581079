import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import StakeEarn from "./shared/stake-earn/stake-earn";
import MyWallet from "./shared/wallet/wallet";
import SpadPrice from "./shared/spad-price/spad-price";
import HistoryWrapper from "./shared/history-wrapper/history-wrapper";
import {
  Connection,
  Keypair,
  PublicKey,
  SYSVAR_CLOCK_PUBKEY,
  SystemProgram,
  clusterApiUrl,
} from "@solana/web3.js";
import { Liquidity } from "@raydium-io/raydium-sdk";
import {
  api,
  getToken,
  getTokenAddress,
  nextblock,
  returnbignumber,
  testAccountsToken,
} from "../../programs/utils";
import { Contract } from "../../programs/programs";
import saAbi from "../../abis/stake/stakeAddress.json";
import spAbi from "../../abis/stake/stakePool.json";
import { BN } from "@coral-xyz/anchor";
import { getAccount } from "@solana/spl-token";
import { ethers } from "ethers";
import Api from "../../programs/api";
import AppContext from "../../framework/helpers/AppContext";
import { BigNumber } from "../../hooks/classes/BigNumber";
const Staking = (props) => {

  const {
    setnet,
    netid
  } = useContext(AppContext)
  useEffect(() => {
    if (netid == "tron") {
      setnet("solana")
      window.location.reload()
    }
  }, [])
  const baseMintInfo = {
    decimals: 9,
    address: new PublicKey("F42ZqgYHRJ7XgQL3kj61FNgUbSWFYVqRMGTHhXqnuzmX"),
    symbol: "SPAD",
  };
  const quoteMintInfo = {
    decimals: 9,
    address: new PublicKey("So11111111111111111111111111111111111111112"),
    symbol: "SOL",
  };
  const lpvaults = {
    base: new PublicKey("5shqQQeG1Y4mujvhXMrgjYZaLaFnFLLtfpFrCESEzrr6"),
    quote: new PublicKey("7XKmdTcALL4KVNM7jKpMZ8JkYr5RRdtk9RSv9cSSmrHm"),
  };
  const bank = new PublicKey("69tomstf2sA4DqQuiwEDPgfGrv5gUGMbAopB6K2Am57o");
  const [contract, setcontract] = useState(
    new Contract(
      "G4NiBLaivFGZBhp9HFfhePT7bXc2rbc11a3E2bA36tqz", //"H2BhXK4z7pTiaJMcjsixARtnuAkT7jQNLP63fn8YqR9J",
      "5VQgyd2LTtvxTZ138zkCZXJcGeYivuF59KcU1y7qDgxY",
      spAbi
    )
  );
  const [stakeAddress, setstakeAddress] = useState(
    new Contract(
      undefined,
      "39eGcnGZnkVyofZSpVZB3YNXL9VXW8M8BT9iqxRy7rZX",
      saAbi
    )
  );
  const [price, setprice] = useState(0);
  const [priceSOL, setpriceSOL] = useState(0);
  const [priceSOLUSD, setpriceSOLUSD] = useState(0);
  const [supply, setsupply] = useState(0);

  const [balance, setBalance] = useState(0);

  const [myStakedAmount, setMyStakedAmount] = useState(0);
  const [am1, setam1] = useState(0);
  const [am3, setam3] = useState(0);
  const [am6, setam6] = useState(0);
  const [am12, setam12] = useState(0);

  const [myStakedRewards, setMyStakedRewards] = useState(0);
  const [r1, setr1] = useState(0);
  const [r3, setr3] = useState(0);
  const [r6, setr6] = useState(0);
  const [r12, setr12] = useState(0);

  const [lock, setlock] = useState(1);
  const [tsd, settsd] = useState(0);
  const [apr, setapr] = useState(0);

  const [seconds, setSeconds] = useState(0);

  const [historic, setHistoric] = useState([]);
  useEffect(() => {
    load();
    loadprice();
  }, []);

  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
      load();
      loadprice();
    }, 5000);

    return () => clearInterval(interval);
  }, [seconds]);

  /*useEffect(() => {
     load()
     loadprice()
   }, [lock])*/
  async function exec(func, ...inputs) {
    try {
      await func(...inputs);
    } catch (error) {
      //   console.log(error)
    }
  }
  function parseHistoric(h) {
    let newH = [];
    h.forEach((element) => {
      newH.push({
        Amount: nextblock(
          element.amount,
          parseInt(element.types) == 2
            ? quoteMintInfo.decimals
            : baseMintInfo.decimals
        ),
        lock: parseInt(element.lock),
        since: parseInt(element.since),
        types: parseInt(element.types),
      });
    });
    return newH;
  }
  async function load() {
    const { publicKey, connection } = window.solpadContext;

    Promise.all([
      await exec(async (...e) => {
        const token = await getToken(baseMintInfo.address);
        if (token.supply != undefined)
          setsupply(token.supply);
      }),

      await exec(async (...e) => {
        settsd(await contract.methods.totalonpool.view({}));
        const totals = BigNumber.from(
          await contract.methods.totalonpoolir.view({})
        );
        //  console.log(await contract.methods.totalonpoolir.view({}))
        const totalr = BigNumber.from(
          await contract.methods.totalinreward.view({})
        );
        //console.log(totalr, (totalr * 100) / totals)
        const discount = BigNumber.from(
          await contract.methods.discount.view({}, new BN(lock))
        );
        const _apr = totalr
          .mul(BigNumber.from(100))
          .div(totals)
          .mul(discount)
          .div(BigNumber.from(100)); //  (((totalr * 100) / totals) * discount) / 100
        // console.log(_apr.toBigInt())
        setapr(parseInt(_apr.toBigInt()));
      }),
      await exec(async (...e) => {
        const myTokenData = await getAccount(
          connection,
          await getTokenAddress(new PublicKey(baseMintInfo.address), publicKey)
        );
        setBalance(nextblock(myTokenData?.amount, baseMintInfo.decimals));
      }),
      await exec(async (...e) => {
        const stakeAcc = await getStakeAddress(undefined);

        const accounts = {
          bank: contract.storage,
          stakeAddress: stakeAcc?.storage,
          stakeAddress_programId: stakeAcc?.contract,
        };
        setam1(
          stakeAcc == undefined
            ? 0
            : nextblock(
              await contract.methods.invested.view(accounts, new BN(1)),
              baseMintInfo.decimals
            )
        );
        /* setam3(stakeAcc == undefined ? 0 : nextblock(await contract.methods.invested.view(accounts, new BN(3)), baseMintInfo.decimals))
         setam6(stakeAcc == undefined ? 0 : nextblock(await contract.methods.invested.view(accounts, new BN(6)), baseMintInfo.decimals))
         setam12(stakeAcc == undefined ? 0 : nextblock(await contract.methods.invested.view(accounts, new BN(12)), baseMintInfo.decimals))
        */
        // console.log(am1, am3, am6, am12)
        setMyStakedAmount(am1 + am3 + am6 + am12);

        setr1(
          stakeAcc == undefined
            ? 0
            : nextblock(
              await contract.methods.rewardinstake.view(accounts, new BN(1)),
              quoteMintInfo.decimals
            )
        );
        /* setr3(stakeAcc == undefined ? 0 : nextblock(await contract.methods.rewardinstake.view(accounts, new BN(3)), quoteMintInfo.decimals))
         setr6(stakeAcc == undefined ? 0 : nextblock(await contract.methods.rewardinstake.view(accounts, new BN(6)), quoteMintInfo.decimals))
         setr12(stakeAcc == undefined ? 0 : nextblock(await contract.methods.rewardinstake.view(accounts, new BN(12)), quoteMintInfo.decimals))
        */
        //console.log(r1, r3, r6, r12)
        setMyStakedRewards(r1 + r3 + r6 + r12);
      }),
      await exec(async (...e) => {
        const stakeAcc = await getStakeAddress(undefined);
        const accounts = {};
        const h =
          stakeAcc == undefined
            ? []
            : parseHistoric(await stakeAcc.methods.moves.view(accounts));
        //console.log(h)
        setHistoric(h);
      }),
    ]).then((values) => { });
  }

  const loadprice = async () => {
    try {
      const connection = new Connection(Api.blockchain.rpc);
      // const { connection } = window.solpadContext

      const balanceA = await connection.getTokenAccountBalance(lpvaults.base);
      const balanceB = await connection.getTokenAccountBalance(lpvaults.quote);
      const priceSol =
        parseFloat(balanceB.value.uiAmount) /
        parseFloat(balanceA.value.uiAmount);

      const tick = await Api.blockchain.status.get("/solana")

      const price = parseFloat(tick.price) * parseFloat(priceSol);

      setpriceSOL(parseFloat(priceSol));
      setpriceSOLUSD(parseFloat(tick.price));
      setprice(price.toFixed(3));
    } catch (error) {
      // console.log(error)
    }
  };
  async function getStakeAddress(seed) {
    const { publicKey, connection } = window.solpadContext;
    //console.log(new PublicKey(publicKey).toBytes())
    const programAccounts = await connection.getProgramAccounts(
      new PublicKey(stakeAddress.contract),
      {
        filters: [
          {
            memcmp: {
              offset: 16, // Offset onde o campo "owner" começa na conta de dados
              bytes: publicKey.toBase58(), // Chave pública do "owner" para filtrar
            },
          },
          {
            memcmp: {
              offset: 48, // Offset onde o campo "owner" começa na conta de dados
              bytes: new PublicKey(contract.storage).toBase58(), // Chave pública do "owner" para filtrar
            },
          },
        ],
      }
    );
    //console.log(programAccounts)
    if (programAccounts.length <= 0 && seed == undefined) {
      return undefined;
    }
    const acc =
      programAccounts.length > 0
        ? programAccounts[programAccounts.length - 1].pubkey.toString()
        : seed.publicKey.toString();

    const sa = new Contract(acc, stakeAddress.contract, saAbi);

    return sa;
  }
  async function stake(stakeamount) {
    const { publicKey } = window.solpadContext;
    await exec(async (...e) => {
      /*const seedArray = generateRandomBytes(32);
      const seed = Array.from(seedArray).map(byte => byte.toString(16).padStart(2, '0')).join('');
      console.log("Random Seed:", seed);*/
      const fromSeed = Keypair.generate();
      const sa = await getStakeAddress(fromSeed);
      //  console.log(sa.storage)
      const owner = await sa.methods.owner.view({});
      const accountNonExist = owner == undefined;
      const space = 10240;

      const createSA = accountNonExist
        ? [
          SystemProgram.createAccount({
            fromPubkey: publicKey,
            newAccountPubkey: fromSeed.publicKey,
            lamports: 7050 * space,
            space: space, // Defina o espaço necessário para a conta
            programId: new PublicKey(sa.contract),
          }),

          await sa.methods.new.instruction([], [], {
            owner: publicKey,
            pool: contract.storage,
          }),
        ]
        : [];
      //console.log(createSA)
      const createATA = await testAccountsToken(
        new PublicKey(baseMintInfo.address),
        [publicKey, contract.storage]
      );
      await contract.methods.deposit.send(
        [...createATA, ...createSA],
        accountNonExist ? [fromSeed] : [],
        {
          bank: contract.storage,
          stakeAddress: sa.storage,
          from: await getTokenAddress(
            new PublicKey(baseMintInfo.address),
            publicKey
          ),
          to: await getTokenAddress(
            new PublicKey(baseMintInfo.address),
            contract.storage
          ),
          lpbase: lpvaults.base,
          lpquote: lpvaults.quote,
          stakeAddress_programId: sa.contract,
          // stakeAddressProgram:sa.contract,
          clocks: SYSVAR_CLOCK_PUBKEY,
        },
        new BN(returnbignumber(stakeamount, baseMintInfo.decimals)),

        new BN(1)
      );
      //new BN(lock))
    });
  }

  async function unStake(_lock) {
    const { publicKey } = window.solpadContext;
    await exec(async (...e) => {
      // const fromSeed = Keypair.generate();
      const sa = await getStakeAddress(undefined);
      const owner = await sa.methods.owner.view({});
      const ownerfac = await contract.methods.ownerfac.view({});
      const createATA = await testAccountsToken(
        new PublicKey(baseMintInfo.address),
        [ownerfac]
      );
      await contract.methods.unStake.send(
        [...createATA],
        [],
        {
          bank: contract.storage,
          stakeAddress: sa.storage,
          to: await getTokenAddress(
            new PublicKey(baseMintInfo.address),
            publicKey
          ),
          from: await getTokenAddress(
            new PublicKey(baseMintInfo.address),
            contract.storage
          ),
          owner: await getTokenAddress(
            new PublicKey(baseMintInfo.address),
            ownerfac
          ),
          stakeAddress_programId: sa.contract,
          clocks: SYSVAR_CLOCK_PUBKEY,
        },
        new BN(1)
      );
      //new BN(_lock))
    });
  }
  async function harvest(_lock) {
    //console.log(_lock)
    const { publicKey } = window.solpadContext;
    await exec(async (...e) => {
      // const fromSeed = Keypair.generate();
      const sa = await getStakeAddress(undefined);
      //const owner = await sa.methods.ownerfac.view({})

      await contract.methods.harvest.send(
        [],
        [],
        {
          bank: contract.storage,
          stakeAddress: sa.storage,

          from: bank,
          stakeAddress_programId: sa.contract,
          clocks: SYSVAR_CLOCK_PUBKEY,
        },
        new BN(1)
      );
      //new BN(_lock))
    });
  }

  return (
    <>
      <div className="text-title">Staking</div>
      <div className="row">
        <SpadPrice
          price={price}
          supply={nextblock(supply, baseMintInfo.decimals)}
          totalStaked={nextblock(tsd, baseMintInfo.decimals)}
          apr={apr}
          TOKENA={baseMintInfo.symbol}
          TOKENB={quoteMintInfo.symbol}
        />
      </div>
      <div className="row">
        <div className="col col-12">
          <StakeEarn
            lock={lock}
            setlock={setlock}
            apr={apr}
            price={price}
            stake={stake}
            priceSOL={priceSOL}
            priceSOLUSD={priceSOLUSD}
            unstake={unStake}
            harvest={harvest}
            balance={balance}
            amounts={{
              1: am1,
              3: am3,
              6: am6,
              12: am12,
            }}
            rewards={{
              1: r1,
              3: r3,
              6: r6,
              12: r12,
            }}
            TOKENA={baseMintInfo.symbol}
            TOKENB={quoteMintInfo.symbol}
          />
        </div>
      </div>
      <div className="row">
        <div className="col col-12 p-t-0 p-x-5">
          <MyWallet
            TOKENA={baseMintInfo.symbol}
            TOKENB={quoteMintInfo.symbol}
            balance={balance}
            priceSOLUSD={priceSOLUSD}
            stkA={myStakedAmount}
            stkR={myStakedRewards}
            price={price}
          />
        </div>
      </div>
      <div className="row">
        <div className="col col-5 p-x-5">
          {
            <div
              id="dexscreener-embed"
              style={{
                height: "100%",
                minHeight: "360px",
                borderRadius: "10px",
                width: "100%",
              }}
            >
              <iframe
                style={{
                  height: "100%",
                  width: "100%",
                  border: "1px solid #00000000",
                  borderRadius: "10px",
                }}
                src="https://dexscreener.com/solana/FWznLu333fEdgqtmEprw6hrxG1EnXpZmi1Asnxw3mZVZ?embed=1&theme=dark&trades=0&info=0"
              ></iframe>
            </div>
          }
        </div>
        <div className="col col-7">
          <HistoryWrapper
            historic={historic}
            TOKENA={baseMintInfo.symbol}
            TOKENB={quoteMintInfo.symbol}
          />
        </div>
      </div>
    </>
  );
};
export default Staking;
